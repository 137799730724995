import {EventEmitter, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {protectedResources} from "../auth-config";

export type BackendAvatar = {
  base64EncodedImage: string
}
@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  apiEndpoint: string = protectedResources.apiTodoList.endpoint;

  private limit: number = 202175;
  constructor(private http: HttpClient) {}

  getCompanyAvatar(companyId: string) {
    return this.http.get<BackendAvatar>(`${this.apiEndpoint}/companies/${companyId}/avatar`)
  }
  updateCompanyAvatar(companyId: string, avatar: string) {
    return this.http.put<BackendAvatar>(`${this.apiEndpoint}/companies/${companyId}/avatar`, {
      Base64EncodedImage: avatar
    })
  }
  selectFile(event: any, imagebase64: EventEmitter<string>, currentAvatar: string) {
    let preview = '';
    let selectedFiles = event.target.files;
    let currentFile = undefined;
    let tooBig: boolean = false;

    if (selectedFiles) {
      const file: File | null = selectedFiles.item(0);
      const { size }: any = file;

      if (file) {
        preview = '';
        currentFile = file;

        const reader = new FileReader();
        tooBig = this.isAvatarTooBig(size)
        reader.onload = (e: any) => {
          imagebase64.emit(
            !tooBig
              ? e.target.result
              : currentAvatar!.length > 0
                ? currentAvatar
                : ''
          );
          preview = e.target.result;
        };

        reader.readAsDataURL(currentFile);
      }
    }
    return {
      preview: preview,
      file: currentFile,
      tooBig: tooBig
    };
  }
  isAvatarTooBig(size: number) {
    if (size > this.limit) {
      return true;
    } else {
      return false;
    }
  }

  fileToDataURL(file: File, onFileLoaded: (url: string) => void) {
    let reader = new FileReader();
    reader.onloadend = function() {
      if(typeof reader.result === 'string') {
        onFileLoaded(reader.result);
      } else {
        onFileLoaded('');
      }
    };
    reader.readAsDataURL(file);
  }
}
