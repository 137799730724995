import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators, ControlContainer, FormGroup} from "@angular/forms";

type EmailInputFormControl = FormControl<string>;

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['../inputs.scss', './email-input.component.scss']
})
export class EmailInputComponent implements OnInit {
  @Input() controllerName: string = "";
  @Input() initValue: string = "";
  @Input() set value(val: string) {
    if(this.emailControl) this.emailControl.setValue(val);
  }
  @Input() label: string = 'E-mail';
  @Input() placeholder: string = 'Email';
  @Input() readonly: boolean = false;
  @Input() ignoreValidation: boolean = false;
  @Input() disabled: boolean = false;

  emailControl!: EmailInputFormControl;

  public static readonly emailControl: () => EmailInputFormControl = () => new FormControl();

  constructor(private controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.emailControl = (this.controlContainer.control as FormGroup).get(this.controllerName) as EmailInputFormControl;
    this.emailControl.setValue(this.initValue);
    if (!this.ignoreValidation) {
      this.emailControl.setValidators([Validators.required, Validators.maxLength(100), Validators.email]);
    }
    if (this.disabled) {
      this.emailControl.disable();
    }
    this.emailControl.updateValueAndValidity();
  }
}
