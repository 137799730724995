import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  agricultureOptionsBase,
  constructionAndInfrastructureOptionsBase, environmentalMonitoringOptionsBase, forestryOptionsBase,
  miningAndAggregatesOptionsBase,
  surveyingOptionsBase
} from "../../../../utils/data";

type IndustryOptionsInputFormGroup = FormGroup<{industry: FormControl<string>}>
@Component({
  selector: 'app-industry-options-input',
  templateUrl: './industry-options-input.component.html',
  styleUrls: ['./industry-options-input.component.scss', '../inputs.scss']
})
export class IndustryOptionsInputComponent implements OnInit {
  showIndustry: boolean = false;
  private _fieldsApplicationOptions: string[] = [];
  @Input()
  set fieldsApplicationOptions(value: string[]) {
    this._fieldsApplicationOptions = value.sort();
  }
  get fieldsApplicationOptions(): string[] {
    return this._fieldsApplicationOptions;
  }
  @Input() initValue: string = "";
  @Output() applicationsForIndustry: EventEmitter<string[]>
  surveyingOptions = surveyingOptionsBase;
  miningAndAggregatesOptions = miningAndAggregatesOptionsBase;
  constructionAndInfrastructureOptions = constructionAndInfrastructureOptionsBase;
  agricultureOptions = agricultureOptionsBase;
  environmentalMonitoringOptions = environmentalMonitoringOptionsBase;
  forestryOptions = forestryOptionsBase;
  industryOptionsGroup!: IndustryOptionsInputFormGroup;

  public static readonly industryOptionsGroup: () => IndustryOptionsInputFormGroup = () => new FormGroup(
    {
      industry:
        new FormControl()
    }
  )
  constructor(private controlContainer: ControlContainer) {
    this.applicationsForIndustry = new EventEmitter<string[]>();
  }

  ngOnInit(): void {
    this.industryOptionsGroup = this.controlContainer.control as IndustryOptionsInputFormGroup;
    this.industryOptionsGroup.controls.industry.setValue(this.initValue);
    this.industryOptionsGroup.controls.industry.setValidators([Validators.required])
    this.industryOptionsGroup.controls.industry.valueChanges.subscribe(next => {
      this.onChangingIndustry(next || '');
      return next;
    })
    this.industryOptionsGroup.updateValueAndValidity();
    this.onChangingIndustry(this.initValue);
  }
  toggleShowingIndustry() : void {
    this.showIndustry = !this.showIndustry;
  }

  close() {
    this.showIndustry = false;
  }

  onChangingIndustry(industry: string) {
    if (industry === 'Surveying') {
      this.applicationsForIndustry?.emit(this.surveyingOptions);
    } else if (industry === 'Mining and Aggregates') {
      this.applicationsForIndustry?.emit(this.miningAndAggregatesOptions);
    } else if (industry === 'Construction and Infrastructure') {
      this.applicationsForIndustry?.emit(this.constructionAndInfrastructureOptions);
    } else if (industry === 'Agriculture') {
      this.applicationsForIndustry?.emit(this.agricultureOptions);
    } else if (industry === 'Environmental Monitoring') {
      this.applicationsForIndustry?.emit(this.environmentalMonitoringOptions);
    } else if (industry === 'Forestry') {
      this.applicationsForIndustry?.emit(this.forestryOptions);
    } else if (
      industry === 'Research and Education'
      || industry === 'Humanitarian Disaster Management'
      || industry === 'RescueServices'
    ) {
      this.applicationsForIndustry?.emit([]);
    }
  }
}
