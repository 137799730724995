import { MemberType } from '../models/company.model';
import { EntityState } from '@ngrx/entity';
import { Aircrafts, Software } from '../models/equipment.model';
import { UserRole } from '../models/user.model';
import { Mission } from '../models/mission.model';

export enum LoadingState {
  FUTURE = 'FUTURE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
export interface ProfileState {
  id: string;
  companyId: string;
  given_name: string;
  family_name: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  avatar: string;
  loadingState: LoadingState;
  quantumProfileLoadingState: LoadingState;
  updatedState: LoadingState;
  roleLoadingState: LoadingState;
  activeProfileError?: any;
  quantumProfileError?: any;
  completed: boolean;
  role: UserRole;
}

export interface CompanyState {
  id?: string;
  name: string;
  country: string;
  stateOrProvince: string;
  postalCode: string;
  industry: string;
  fieldsOfApplication: string[];
  avatar: string;
  members: EntityState<MemberType>;
  loadingState: LoadingState;
  loadingAvatarState: LoadingState;
  updateAvatarState: LoadingState;
  updateState: LoadingState;
  creatingState: LoadingState;
  completed: boolean;
  error: any;
}

export interface EquipmentState {
  aircrafts: EntityState<Aircrafts>;
  software: EntityState<Software>;
  loadingState: LoadingState;
  updateState: LoadingState;
  completed: boolean;
  error: any;
}

export interface MissionControlState {
  missions: EntityState<Mission>;
  loadingState: LoadingState;
  updateState: LoadingState;
  completed: boolean;
  error: any;
}
