<div class="group">
  <label>{{label}}</label>
  <input
    [formControl]="emailControl"
    type="text"
    [readOnly]="readonly"
    [placeholder]="placeholder + (!ignoreValidation?'*':'')"
    class="quantum-input"
    [ngClass]="!ignoreValidation?(emailControl.invalid && emailControl.dirty? 'invalid' : ''): ''"
  />
  <div *ngIf="!ignoreValidation && emailControl.invalid && emailControl.errors" class="error-message" [class.dirty]="emailControl.dirty">
    <div *ngIf="emailControl.errors['required']">*required</div>
  </div>
</div>
