<div class="modal-container">
  <div class="modal-header">
    <span>You going to join a new group</span>
  </div>
  <div class="seperator">
    <hr />
  </div>
  <div class="modal-body">
    <span *ngIf="!errorMessage">
      You have received an invitation to join the
      <span class="company-name">{{ companyName }}</span> group
      as a pilot. Accept the invite to join.
    </span>
    <span class="error-message" *ngIf="errorMessage">
      {{ errorMessage }}
    </span>
    <ng-container *ngIf="company$ | async; let company">
      <div class="modal-content">
        <div class="message" *ngIf="!!company.id">
          <img class="icon-upload" src="../assets/svg/icon-warning.svg" alt="warning symbol"/>
          <span>
              If you already belong to another group, you need to leave that group before you join the new group
          </span>
        </div>
        <div class="buttons">
          <app-button [title]="'CANCEL'" (click)="abortModal()"></app-button>
          <app-button [disabled]="!!company.id" [title]="'ACCEPT'" (click)="finishModal()"></app-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
