import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

type MissionNameInputFormControl = FormControl<string>;

@Component({
  selector: 'app-mission-name-input',
  templateUrl: './mission-name-input.component.html',
  styleUrls: ['./mission-name-input.component.scss', '../inputs.scss'],
})
export class MissionNameInputComponent {
  @Input() missionName = '';

  @Input() controllerName: string = '';
  missionNameControl!: MissionNameInputFormControl;
  public static readonly missionNameControl: () => MissionNameInputFormControl =
    () => new FormControl();

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.missionNameControl = (this.controlContainer.control as FormGroup).get(
      this.controllerName
    ) as MissionNameInputFormControl;
    this.missionNameControl.setValidators([
      Validators.required,
      Validators.maxLength(100),
    ]);
    this.missionNameControl.updateValueAndValidity();
  }
}
