import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appTypeAheadFocus]'
})
export class TypeAheadFocusDirective {
  private inputString: string = '';
  private timeout: any;
  private inputElements;

  constructor(private elementRef: ElementRef) {
    this.inputElements = this.elementRef.nativeElement.querySelectorAll('input');
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (!(/^[a-zA-Z]$/.test(event.key))) return;
    clearTimeout(this.timeout);
    this.inputString += event.key.toUpperCase();
    this.timeout = setTimeout(() => {
      this.inputString = '';
    }, 500);

    this.inputElements = this.elementRef.nativeElement.querySelectorAll('input');

    for (const inputElement of this.inputElements) {
      if (
        inputElement.parentElement?.innerText?.toUpperCase().startsWith(this.inputString)
        ||
        inputElement.innerText?.toUpperCase().startsWith(this.inputString)
        ||
        inputElement.nextSibling?.innerText?.toUpperCase().startsWith(this.inputString)
        ||
        inputElement.previousSibling?.innerText?.toUpperCase().startsWith(this.inputString)
      ) {
        inputElement.focus();
        break;
      }
    }
  }

}
