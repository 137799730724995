<div class="group">
  <label>Job Title</label>
  <input
    [formControl]="jobtitleControl"
    type="text"
    placeholder="Job title*"
    class="quantum-input"
    [ngClass]="jobtitleControl.invalid ? 'invalid' : ''"
  />
  <div
    *ngIf="jobtitleControl.invalid && jobtitleControl.errors"
    [class.dirty]="jobtitleControl.dirty"
    class="error-message"
  >
    <div *ngIf="jobtitleControl.errors['required']">*required</div>
  </div>
</div>
