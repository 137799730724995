import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ProfileState} from "../store.states";

export const selectProfileState = createFeatureSelector<ProfileState>('profile');

export const selectProfileData = createSelector(
  selectProfileState,
  (state: ProfileState) => ({
    ...state
  })
);

export const selectProfileRole = createSelector(
  selectProfileData,
  (profile) => profile.role
)

export const selectProfileCompleted = createSelector(
  selectProfileData,
  (profile) => profile.completed
)

export const selectProfileCompanyId = createSelector(
  selectProfileData,
  (profile) => profile.companyId
)
