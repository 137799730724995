import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";

type CheckboxInputFormControl = FormControl;
@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['../inputs.scss', './checkbox-input.component.scss']
})
export class CheckboxInputComponent {
  @Input() label: string = "";
  @Input() validators: ValidatorFn[] = [];
  @Input() controllerName: string = "";
  checkboxControl!: CheckboxInputFormControl;

  public static readonly checkboxInputFormControl: () => CheckboxInputFormControl = () => new FormControl<boolean>(false)

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    console.dir(this.controlContainer)
    this.checkboxControl = (this.controlContainer.control as FormGroup).get(this.controllerName) as CheckboxInputFormControl;
    this.checkboxControl.setValidators(this.validators);
    this.checkboxControl.updateValueAndValidity();
  }
}
