import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  JoinGroupModalComponent,
  JoinGroupModalType
} from "../../components/modals/join-group-modal/join-group-modal.component";
import {Dialog} from "@angular/cdk/dialog";
import {LocalStorageService} from "../../services/local-storage.service";
import {JoinCompanyService} from "../../services/join-company.service";
import {InformationModalComponent} from "../../components/modals/information-modal/information-modal.component";
import {take} from "rxjs";
import {MsalService} from "@azure/msal-angular";
import {Store} from "@ngrx/store";
import {selectProfileData} from "../../stores/profile/profile.selector";
import {filter} from "rxjs/operators";
import {LoadingState} from "../../stores/store.states";
import {loadingProfile} from "../../stores/profile/profile.actions";

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent {
    fail: boolean = false;
  constructor(
    private dialog: Dialog,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private joinCompanyService: JoinCompanyService,
    private azure: MsalService,
    private store: Store
  ) {
    this.route.params.pipe(take(1)).subscribe(params => {
            this.saveInviteLinkToLocalStorage(atob(
             this.joinCompanyService.reversURLFriendlyBase64Text(params['link']))
            );
            if(this.azure.instance.getActiveAccount()) {
              this.store.dispatch(loadingProfile());
              this.store.select(selectProfileData).pipe(
                filter(profile => profile.quantumProfileLoadingState === LoadingState.SUCCESS),
                take(1)
              ).subscribe(profile => {
                if(profile.completed) {
                  this.openInviteDialog();
                } else {
                  this.router.navigate(["/progress"])
                }
              })
            } else {
              this.openInformationDialogToLoginOrRegister();
            }
        }
    )
  }
  openInformationDialogToLoginOrRegister() {
    const dialogRef = this.dialog.open(InformationModalComponent, {
      data: {
        headerText: "Getting Started: Login or Create an Account",
        bodyText: "It seems like you're not logged in or don't have an account yet. Press 'OK' to log in or register, and then you can proceed with the invitation."
      }
    });
    dialogRef.closed.subscribe(_ => {
      this.router.navigate(['/'])
    })
  }
  openInviteDialog() {
    const dialogRef = this.dialog.open<JoinGroupModalType>(JoinGroupModalComponent);
    dialogRef.closed.subscribe((result) => {
      if(!!result && result.isValid) {
          this.joinCompanyService.joinCompany(result.joinLink).subscribe( {
                  next: (joinResult) => {
                      this.localStorageService.removeInviteLinkFromLocalStorage();
                      this.router.navigate(['/dashboard/company'])
                  },
                  error: (error) => {
                      this.fail = true;
                  }
              }
          );
      } else if (!!result && !result?.isValid){
         this.fail = true;
      } else {
          this.localStorageService.removeInviteLinkFromLocalStorage();
          this.router.navigate(['/dashboard/company'])
      }
    });
  }

  private saveInviteLinkToLocalStorage(link: string) {
      this.localStorageService.saveInviteLinkToLocalStorage(link);
  }


}
