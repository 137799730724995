import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  url: string = '';
  clickOn: boolean = false;
  @Output() logoutEvent = new EventEmitter();

  constructor(private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.url = val.url;
      }
    });
  }

  childMethod() {
    this.logoutEvent.emit();
  }

  opening() {
    this.clickOn = !this.clickOn;
  }
}
