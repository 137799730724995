import {Component, Inject, Input} from '@angular/core';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LicensekeyInputComponent} from "../../inputs/licensekey-input/licensekey-input.component";
import {Store} from "@ngrx/store";
import * as EquipmentAction from "../../../stores/equipment/equipment.actions";
import {selectEquipment} from "../../../stores/equipment/equipment.selector";
import {LoadingState} from "../../../stores/store.states";
import {selectCompanyData} from "../../../stores/company/company.selector";
import {take} from "rxjs";

@Component({
  selector: 'app-add-drone-modal',
  templateUrl: './add-drone-modal.component.html',
  styleUrls: ['./add-drone-modal.component.scss']
})
export class AddDroneModalComponent {
  @Input() open: boolean = false;
  errorMessage: string = '';
  form: FormGroup = this.formBuilder.nonNullable.group({
    licenseKey: LicensekeyInputComponent.licenseKeyControl()
  });

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private store: Store
  ) {
  }

  abortModal() {
    this.dialogRef.close();
  }

  finishModal(data: string): void {
    this.dialogRef.close(data);
  }

  addDrone() {
    let licenseKey = this.form.value.licenseKey
    if(licenseKey) {
      this.store.select(selectCompanyData).pipe(take(1)).subscribe(company => {
        if(!company.id) return;
        this.store.dispatch(EquipmentAction.activatingAircraft({serial: licenseKey, companyId: company.id}))
        this.store.select(selectEquipment).subscribe(equipment => {
          if(equipment.updateState === LoadingState.ERROR) {
            if (equipment.error.status === 404) {
              this.errorMessage = 'Drone not found';
            } else if (equipment.error.status === 409) {
              this.errorMessage = equipment.error.error;
            } else {
              this.errorMessage = 'Something went wrong!';
            }
          }
          if(equipment.updateState === LoadingState.SUCCESS) {
            this.finishModal("Success")
          }
        })
      })
    }
  }
}
