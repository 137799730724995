import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormControl, FormControlName, FormGroup, NgControl, Validators} from "@angular/forms";

type CompanyInputFormControl = FormControl<string>;
@Component({
  selector: 'app-company-input',
  templateUrl: './company-input.component.html',
  styleUrls: ['./company-input.component.scss', '../inputs.scss']
})
export class CompanyInputComponent implements OnInit {
  @Input() initValue: string = "";
  @Input() controllerName: string = "";
  companyControl!: CompanyInputFormControl;
  public static readonly companyInputFormControl: () => CompanyInputFormControl = () => new FormControl<string>("", {nonNullable: true});
  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.companyControl = (this.controlContainer.control as FormGroup).get(this.controllerName) as CompanyInputFormControl;
    this.companyControl.setValue(this.initValue);
    this.companyControl.setValidators([Validators.required, Validators.maxLength(100)]);
    this.companyControl.updateValueAndValidity();
  }
}
