import { isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

async function prepareApp() {
  // if (isDevMode()) {
  //   const { worker } = await import('./mocks/browser.msw');
  //   return worker.start();
  // }

  return Promise.resolve();
}

prepareApp().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
