<div class="group">
  <label>Mission Name</label>
  <input
    [formControl]="missionNameControl"
    type="text"
    placeholder="Enter mission name"
    class="quantum-input"
    [value]="missionName"
  />
  <div
    *ngIf="missionNameControl.invalid && missionNameControl.errors"
    class="error-message"
    [class.dirty]="missionNameControl.dirty"
  >
    <div *ngIf="missionNameControl.errors['required']">*required</div>
  </div>
</div>
