<ng-container>
  <div class="container">
    <app-sidenav (logoutEvent)="logout()"></app-sidenav>
    <div class="page-content">
      <app-topnav (logoutEvent)="logout()"></app-topnav>
      <!--
      <app-progress *ngIf="urlComponent === '/'"></app-progress>
      <app-profile *ngIf="urlComponent === '/profile'"></app-profile>
      <app-equipment *ngIf="urlComponent === '/equipment'"></app-equipment>
      <app-progress *ngIf="urlComponent === '/progress'"></app-progress>
      <app-academy *ngIf="urlComponent === '/academy'"></app-academy>
      <app-help *ngIf="urlComponent === '/help'"></app-help>
      -->
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-fallback [show]="!isLoggedIn && !isJoiningRoute"></app-fallback>
  <app-fullscreen-loading-spinner></app-fullscreen-loading-spinner>
</ng-container>
