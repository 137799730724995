import { createEntityAdapter } from '@ngrx/entity';
import { MemberType } from '../../models/company.model';
import { CompanyState, LoadingState } from '../store.states';
import { createReducer, on } from '@ngrx/store';
import * as CompanyAction from './company.actions';
import { CompanyService } from '../../services/company.service';

export const memberAdapter = createEntityAdapter<MemberType>({
  selectId: (member: MemberType) => member.userId,
  sortComparer: false,
});

export const initialState: CompanyState = {
  country: '',
  error: null,
  fieldsOfApplication: [],
  id: '',
  industry: '',
  loadingState: LoadingState.FUTURE,
  loadingAvatarState: LoadingState.FUTURE,
  members: memberAdapter.getInitialState(),
  name: '',
  postalCode: '',
  stateOrProvince: '',
  updateState: LoadingState.FUTURE,
  updateAvatarState: LoadingState.FUTURE,
  creatingState: LoadingState.FUTURE,
  completed: false,
  avatar: '',
};

export const companyReducer = createReducer(
  initialState,
  on(CompanyAction.createCompany, (state, { company }) => ({
    ...state,
    country: company.country,
    fieldsOfApplication: company.fieldsOfApplication,
    industry: company.industry,
    name: company.name,
    postalCode: company.postalCode,
    stateOrProvince: company.stateOrProvince,
    creatingState: LoadingState.PENDING,
  })),
  on(CompanyAction.creatingCompanySuccess, (state, { company }) => ({
    ...state,
    country: company.country,
    fieldsOfApplication: company.fieldsOfApplication,
    industry: company.industry,
    name: company.name,
    postalCode: company.postalCode,
    stateOrProvince: company.stateOrProvince,
    creatingState: LoadingState.SUCCESS,
    completed: CompanyService.isCompanyComplete(company),
  })),
  on(CompanyAction.creatingCompanyError, (state, { error }) => ({
    ...state,
    creatingState: LoadingState.ERROR,
    error: error,
  })),
  on(CompanyAction.loadingCompany, (state) => ({
    ...state,
    loadingState: LoadingState.PENDING,
  })),
  on(CompanyAction.loadingCompanySuccess, (state, { company }) => ({
    ...state,
    id: company.id,
    country: company.country,
    fieldsOfApplication: company.fieldsOfApplication,
    industry: company.industry,
    name: company.name,
    postalCode: company.postalCode,
    stateOrProvince: company.stateOrProvince,
    loadingState: LoadingState.SUCCESS,
    completed: CompanyService.isCompanyComplete(company),
  })),
  on(CompanyAction.loadingCompanyError, (state, { error }) => ({
    ...state,
    loadingState: LoadingState.ERROR,
    error: error,
  })),
  on(CompanyAction.loadingCompanyMembers, (state) => ({
    ...state,
    loadingState: LoadingState.PENDING,
  })),
  on(CompanyAction.loadingCompanyMembersSuccess, (state, { members }) => ({
    ...state,
    loadingState: LoadingState.SUCCESS,
    members: memberAdapter.setAll(members, state.members),
  })),
  on(CompanyAction.loadingCompanyMembersError, (state, { error }) => ({
    ...state,
    loadingState: LoadingState.ERROR,
    error: error,
  })),
  on(CompanyAction.editCompany, (state) => ({
    ...state,
    updateState: LoadingState.PENDING,
  })),
  on(CompanyAction.editingCompanySuccess, (state, { company }) => ({
    ...state,
    ...company,
    updateState: LoadingState.SUCCESS,
  })),
  on(CompanyAction.editingCompanyError, (state, { error }) => ({
    ...state,
    updateState: LoadingState.ERROR,
    error: error,
  })),
  on(CompanyAction.loadingCompanyAvatar, (state) => ({
    ...state,
    loadingAvatarState: LoadingState.PENDING,
  })),
  on(CompanyAction.loadingCompanyAvatarSuccess, (state, { avatar }) => ({
    ...state,
    avatar: avatar,
    loadingAvatarState: LoadingState.SUCCESS,
  })),
  on(CompanyAction.loadingCompanyAvatarError, (state, { error }) => ({
    ...state,
    loadingAvatarState: LoadingState.ERROR,
    error: error,
  })),
  on(CompanyAction.updateCompanyAvatar, (state) => ({
    ...state,
    updateAvatarState: LoadingState.PENDING,
  })),
  on(CompanyAction.updateCompanyAvatarSuccess, (state, { avatar }) => ({
    ...state,
    avatar: avatar,
    updateAvatarState: LoadingState.SUCCESS,
  })),
  on(CompanyAction.updateCompanyAvatarError, (state, { error }) => ({
    ...state,
    updateAvatarState: LoadingState.ERROR,
    error: error,
  })),
  on(CompanyAction.deletingCompany, (state) => ({
    ...state,
    updateState: LoadingState.PENDING,
  })),
  on(CompanyAction.deletingCompanySuccess, (state) => ({
    ...state,
    updateState: LoadingState.SUCCESS,
    country: '',
    fieldsOfApplication: [],
    industry: '',
    name: '',
    postalCode: '',
    stateOrProvince: '',
    avatar: '',
    members: memberAdapter.setAll([], state.members),
    completed: false,
    id: '',
  })),
  on(CompanyAction.deletingCompanyError, (state, { error }) => ({
    ...state,
    updateState: LoadingState.ERROR,
    error: error,
  })),
  on(CompanyAction.deleteMember, (state) => ({
    ...state,
    updateState: LoadingState.PENDING,
  })),
  on(CompanyAction.deleteMemberSuccess, (state, { memberId }) => ({
    ...state,
    updateState: LoadingState.SUCCESS,
    members: memberAdapter.removeOne(memberId, state.members),
  })),
  on(CompanyAction.deleteMemberError, (state, { error }) => ({
    ...state,
    updateState: LoadingState.ERROR,
    error: error,
  })),
  on(CompanyAction.leaveCompany, (state) => ({
    ...state,
    updateState: LoadingState.PENDING,
  })),
  on(CompanyAction.leaveCompanySuccess, (state) => ({
    ...state,
    updateState: LoadingState.SUCCESS,
  })),
  on(CompanyAction.leaveCompanyError, (state, { error }) => ({
    ...state,
    updateState: LoadingState.ERROR,
    error: error,
  }))
);
