<ng-container *ngIf="role$ | async; let userRole">
  <ng-container *ngIf="company$ | async; let company">
    <div class="header-nav" [class.pilot]="userRole === UserRole.PILOT">
      <div class="avatar">
        <app-avatar-company
          [name]="company.name"
          [area]="company.industry"
          [subarea]="company.fieldsOfApplication.at(0) ?? ''"
          [address]="company.postalCode"
          [avatar]="company.avatar || 'assets/imgs/placeholder-avatar.png'"
        ></app-avatar-company>
      </div>
      <div *ngIf="userRole !== UserRole.PILOT">
        <span (click)="editCompany()" class="edit"
          ><span class="edit-icon"></span>edit details</span
        >
      </div>
      <div *ngIf="userRole !== UserRole.PILOT" class="new-member-invitation">
        <span class="invite" (click)="openInviteDialog()"
          ><span class="plus-icon"></span> invite new members</span
        >
      </div>
      <div
        *ngIf="userRole === UserRole.PILOT && (profile$ | async); let profile"
        class="new-member-invitation"
        (click)="openConfirmLeave(profile.id)"
      >
        <span class="leave"><span class="leave-icon"></span> Leave company group</span>
      </div>
    </div>
    <div class="content-table">
      <div class="titles-row">
        <div>Employee</div>
        <div>Role</div>
        <div>Email</div>
        <div *ngIf="userRole !== UserRole.PILOT"></div>
      </div>
      <div class="break-line"></div>
      <div class="content" cdkMenuBar
           *ngIf="members$ | async; let members"
      >
        <div
          class="content-row"
          *ngFor="let member of members; index as i"
        >
          <div>{{ member.firstName }} {{ member.lastName }}</div>
          <div>{{ member.role === UserRole.ADMIN ? "Admin" : "Pilot" }}</div>
          <div>{{ member.email }}</div>
          <div class="three-dots" *ngIf="userRole === UserRole.ADMIN">
            <span
                    *ngIf="members.length>1 && !(i<1&&!moreThanOneAdmin(members))"
              class="icon-dots"
              cdkMenuItem
              [cdkMenuTriggerFor]="memberMenu"
            ></span>
          </div>
          <ng-template #memberMenu>
            <div class="member-menu" cdkMenu>
              <ul>
                <li cdkMenuItem *ngIf="i>0 || moreThanOneAdmin(members)">
                  <p (click)="changeRole(member, company, i<1)">
                    set
                    {{ member.role === UserRole.PILOT ? "admin" : "pilot" }}
                    role
                  </p>
                </li>
                <li cdkMenuItem>
                  <p (click)="deleteMember(member, company, i<1)">
                    {{i>0?"remove from group":"leave group"}}
                  </p>
                </li>
              </ul>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
