<div class="group">
  <label>Result URL</label>
  <input
    [formControl]="resultUrlControl"
    type="text"
    placeholder="Enter result URL"
    class="quantum-input"
    [value]="resultUrl"
  />
  <div
    *ngIf="resultUrlControl.invalid && resultUrlControl.errors"
    class="error-message"
    [class.dirty]="resultUrlControl.dirty"
  >
    <div *ngIf="resultUrlControl.errors['required']">*required</div>
  </div>
</div>
