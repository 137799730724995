<form
  (submit)="onSubmit()"
  [class.dirty-and-invalid-or-invalid-submitted]="
    (form.controls['readInformationCheckbox'].dirty &&
      form.controls['readInformationCheckbox'].invalid) ||
    (form.invalid && submitted)
  "
  [formGroup]="form"
>
  <app-card
    title="Your Company"
    subtitle="Group for working together"
    imagename="placeholder-avatar.png"
  ></app-card>
  <app-infobox>
    <p style="line-height: normal">
      Before creating a new group, please check with your colleagues to confirm
      if your company already has an existing group. This is important for
      avoiding redundancies and ensuring you can access the right information.
      If your <strong> company already has a group</strong>, the administrator
      of this group can invite you via a link. Then you don’t have to do
      anything else. After joining your company group, you get full access to
      all features of the portal.
    </p>
  </app-infobox>
  <app-checkbox-input
    [label]="
      'I have read the information above and confirm that no group from my company exists yet.'
    "
    [controllerName]="'readInformationCheckbox'"
    [validators]="[Validators.requiredTrue]"
  ></app-checkbox-input>
  <app-response-banner
    [success]="false"
    [invisible]="!submitted"
    [errorMessage]="'Confirm the conditions'"
  ></app-response-banner>
  <app-button title="CREATE NEW GROUP"></app-button>
</form>
