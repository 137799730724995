import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {dispatchLoadingProfileWithCompanyAndEquipment} from "../../stores/store.utils";

@Component({
  selector: 'app-profile',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(private store: Store) {
  }
  ngOnInit() {
    dispatchLoadingProfileWithCompanyAndEquipment(this.store);
  }
}
