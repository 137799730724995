import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CompanyState } from '../../../stores/store.states';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCompanyData } from '../../../stores/company/company.selector';
import {LocalStorageService} from "../../../services/local-storage.service";
import {MsalService} from "@azure/msal-angular";
import {ActivatedRoute, Router} from "@angular/router";
import {protectedResources} from "../../../auth-config";
import {CompanyType} from "../../../models/company.model";
import {dispatchLoadingProfileWithCompanyAndEquipment} from "../../../stores/store.utils";

export type JoinGroupModalType = {
  joinLink: string,
  isValid: boolean
}
@Component({
  selector: 'app-join-group-modal',
  templateUrl: './join-group-modal.component.html',
  styleUrls: ['./join-group-modal.component.scss'],
})
export class JoinGroupModalComponent {
  errorMessage: string = '';
  modalResponse: JoinGroupModalType = {
    joinLink: '',
    isValid: false
  }
  companyName: string = "";
  company$: Observable<CompanyState>
  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA) public data: any,
    private store: Store,
    private localStorageService: LocalStorageService,
    private activeRoute: ActivatedRoute,

  ) {
    this.company$ = this.store.select(selectCompanyData);
    dispatchLoadingProfileWithCompanyAndEquipment(this.store);
    this.processInviteLink();
    this.activeRoute.queryParamMap.subscribe(queryParams => {
      this.companyName = queryParams.get('name') ?? "";
    })
  }


  processInviteLink() {
    const invite = this.localStorageService.getInviteLinkFromLocalStorage();
    if(!invite) return;
    this.modalResponse.joinLink = invite.joinLink;
    if(this.modalResponse.joinLink) this.validateInviteLink(this.modalResponse.joinLink);
  }

  abortModal(): void {
    this.dialogRef.close();
  }

  finishModal(): void {
    this.dialogRef.close(this.modalResponse);
  }

  private validateInviteLink(link: string) {
    return (this.modalResponse.isValid = (new URL(link).hostname) === (new URL(protectedResources.apiTodoList.endpoint).hostname));
  }

}
