import { CompanyState, ProfileState } from '../store.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { memberAdapter } from './company.reducer';
import { MemberType } from '../../models/company.model';
import { selectProfileData } from '../profile/profile.selector';
import { UserRole } from 'src/app/models/user.model';

export const selectCompanyState =
  createFeatureSelector<CompanyState>('company');

export const selectCompanyData = createSelector(
  selectCompanyState,
  (state: CompanyState) => ({
    ...state,
  })
);
export const selectCreateCompany = createSelector(
  selectCompanyState,
  (state: CompanyState) => ({
    ...state,
  })
);
export const selectCompanyMembers = createSelector(
  selectCompanyData,
  (state: CompanyState) => state.members
);

export const {
  selectAll: selectAllMembers,
  selectEntities: selectMemberEntities,
  selectIds: selectMemberIds,
  selectTotal: selectTotalMembers,
} = memberAdapter.getSelectors(selectCompanyMembers);

export const selectAllCompanyMembersWithSelfFirst = createSelector(
  selectAllMembers,
  selectProfileData,
  (members: MemberType[], profile: ProfileState) =>
    members.slice().sort((a, b) => {
      if (profile.id === a.userId) {
        return -1;
      } else if (profile.id === b.userId) {
        return 1;
      }
      return 0;
    })
);

export const selectAllPilots = createSelector(
  selectAllMembers,
  (members: MemberType[]) =>
    members.filter((member) => member.role === UserRole.PILOT)
);

export const selectCompanyMemberByOperatorId = (operatorId: string) =>
  createSelector(selectAllMembers, (members: MemberType[]) => {
    const found = members.find((member) => member.userId === operatorId);
    if (!found) return operatorId;
    return found.email;
  });
