import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import { Observable } from 'rxjs';
import {AvatarService} from "../../services/avatar.service";
import {ProfileState} from "../../stores/store.states";
import {Store} from "@ngrx/store";
import {selectProfileData} from "../../stores/profile/profile.selector";

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  constructor(
    private avatarService: AvatarService,
    private store: Store
  ) {}
  @Input() avatarMenu: boolean = false;
  @Input() avatarTop: boolean = false;
  @Input() avatarInput: boolean = false;
  @Output() imagebase64 = new EventEmitter<string>();
  user$: Observable<ProfileState> = this.store.select(selectProfileData);
  preview = '';
  previewURL?: any;
  limit: number = 202175;
  isAvatarTooBig: boolean = false;
  currentAvatar?: string = '';

  getInitalsFromName(name?: string) {
    if (name) {
      const initial = name.substring(0, 1);
      return initial.toUpperCase();
    }
    return;
  }

  ngOnInit(): void {
    this.user$.subscribe(profile => {
      this.currentAvatar = profile.avatar;
      this.imagebase64.emit(profile.avatar);
    })
  }

  selectFile(event: any): void {
    let result = this.avatarService.selectFile(event, this.imagebase64, this.currentAvatar!);
    this.isAvatarTooBig = result.tooBig;
    if (!this.isAvatarTooBig) {
      this.preview = result.preview;
      this.avatarService.fileToDataURL(result.file!, (url) => {
        this.previewURL = url;
      })
    }
  }
}
