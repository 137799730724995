import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResources } from '../auth-config';
import { Equipment, DroneActivation } from '../models/equipment.model';
import {BehaviorSubject, Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  $step: BehaviorSubject<number>;
  constructor(private http: HttpClient) {
    this.$step = new BehaviorSubject<number>(1);
  }
  apiEndpoint: string = protectedResources.apiTodoList.endpoint;

  getEquipments(companyId: string) {
    return this.http.get<Equipment>(`${this.apiEndpoint}/companies/${companyId}/equipment`);
  }

  activateEquipment(serialNummer: string, companyId: string): Observable<any> {
    return this.http.post<DroneActivation>(
      `${this.apiEndpoint}/companies/${companyId}/activated_aircrafts`,
      {
        serialNumber: serialNummer,
      }
    );
  }

  getStepAsObservable() {
    return this.$step.asObservable();
  }

  setStep(step: number) {
    if(step>this.$step.value) {
      this.$step.next(step);
    }
  }
}
