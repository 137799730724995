<div class="group">
  <label>Last name</label>
  <input
    type="text"
    placeholder="Last name"
    class="quantum-input"
    [attr.disabled]="true"
    [value]="lastName"
  />
</div>
