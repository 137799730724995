<div class="group" appActionOnOutsideClick (clickOutside)="close()">
  <label>Drone</label>
  <div class="selected-item" [ngClass]="showDrone ? 'arrow-down' : ''">
    <div
      class="option"
      [ngClass]="droneOptionsGroup.invalid ? 'invalid' : 'valid'"
      [class.dirty]="droneOptionsGroup.dirty"
    >
      <input type="button" name="group" (click)="toggleShowingDrone()" />
      <p *ngIf="droneOptionsGroup.value?.drone">
        {{ droneOptionsGroup.value.drone }}
      </p>
      <p class="placeholder" *ngIf="!droneOptionsGroup.value.drone">
        Choose...
      </p>
    </div>
  </div>
  <div
    class="option-wrap"
    [ngClass]="showDrone ? 'active' : ''"
    appTypeAheadFocus
  >
    <div
      class="option children"
      *ngFor="let option of droneOptions"
      [formGroup]="droneOptionsGroup"
    >
      <input
        type="radio"
        name="drone"
        [formControlName]="'drone'"
        (click)="toggleShowingDrone()"
        [value]="option.name"
      />
      <p>{{ option.name + " (" + option.serialNumber + ")" }}</p>
    </div>
  </div>
  <div
    *ngIf="droneOptionsGroup.invalid && !showDrone"
    [class.dirty]="droneOptionsGroup.dirty"
    class="error-message"
  >
    <div>*required</div>
  </div>
</div>
