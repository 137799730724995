import { Component, Inject, Input } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectAllCompanyMembersWithSelfFirst } from '../../../stores/company/company.selector';
import { Observable } from 'rxjs';
import { OperatorOptionsInputComponent } from '../../inputs/operator-options-input/operator-options-input.component';
import { MemberType } from 'src/app/models/company.model';
import { DroneOptionsInputComponent } from '../../inputs/drone-options-input/drone-options-input.component';
import { selectAllAircrafts } from 'src/app/stores/equipment/equipment.selector';
import { Aircrafts } from 'src/app/models/equipment.model';
import { MissionNameInputComponent } from '../../inputs/mission-name-input/mission-name-input.component';
import { Router } from '@angular/router';
import { TimestampInputComponent } from '../../inputs/timestamp-input/timestamp-input.component';
import { MissionControlService } from 'src/app/services/mission-control.service';

@Component({
  selector: 'app-new-mission-modal',
  templateUrl: './new-mission-modal.component.html',
  styleUrls: ['./new-mission-modal.component.scss'],
})
export class NewMissionModalComponent {
  droneOptions: Aircrafts[] = [];
  operatorOptions: MemberType[] = [];
  @Input() open: boolean = false;
  errorMessage: string = '';
  isCopied: boolean = false;
  link: string = window.location.host + '/join/';
  form: FormGroup = this.formBuilder.nonNullable.group({
    name: MissionNameInputComponent.missionNameControl(),
    drone: DroneOptionsInputComponent.droneOptionsGroup(),
    operator: OperatorOptionsInputComponent.operatorOptionsGroup(),
    timestamp: TimestampInputComponent.timestampInputFormControl(),
  });

  members$: Observable<MemberType[]>;
  aircrafts$: Observable<Aircrafts[]>;

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA) public data: any,
    private clipboard: Clipboard,
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    private missionControlService: MissionControlService
  ) {
    this.aircrafts$ = this.store.select(selectAllAircrafts);
    this.aircrafts$.subscribe((aircrafts) => {
      this.droneOptions = aircrafts;
    });

    // this.members$ = this.store.select(selectAllPilots); // NOTE: remove for fast PoC
    this.members$ = this.store.select(selectAllCompanyMembersWithSelfFirst);
    this.members$.subscribe((members) => {
      this.operatorOptions = members;
    });
  }

  abortModal(): void {
    this.dialogRef.close();
  }

  finishModal(): void {
    this.dialogRef.close({});
  }

  planMission() {
    this.router.navigate(['/missions/planner']);
    this.dialogRef.close();
  }

  copyLink() {
    this.clipboard.copy(this.link);
    this.showIsCopiedBanner(7500);
  }

  showIsCopiedBanner(time: number) {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, time);
  }

  ngOnDestroy(): void {
    const operatorId = this.operatorOptions.find(
      (operator) => operator.email === this.form.value.operator.operator
    )?.userId;

    const aircraftId = this.droneOptions.find(
      (operator) => operator.name === this.form.value.drone.drone
    )?.serialNumber;

    this.missionControlService.mission = {
      name: this.form.value.name,
      aircraftId,
      operatorId,
      plannedAt: this.form.value.timestamp,
    };

    if (!this.missionControlService.mission.plannedAt) {
      // NOTE: BE can't handle undefined or empty string
      this.missionControlService.mission.plannedAt = new Date().toISOString();
    }
  }
}
