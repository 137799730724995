<script src="state-or-province-input.component.ts"></script>
<div class="group">
  <label>State</label>
  <input
    [formControl]="stateOrProvinceControl"
    type="text"
    placeholder=""
    class="quantum-input"
    [ngClass]="stateOrProvinceControl.invalid ? 'invalid' : ''"
  />
  <div
    *ngIf="
      stateOrProvinceControl.invalid &&
      stateOrProvinceControl.errors &&
      stateOrProvinceControl.dirty
    "
    [class.dirty]="stateOrProvinceControl.dirty"
    class="error-message"
  >
    <div *ngIf="stateOrProvinceControl.errors['required']">*required</div>
  </div>
</div>
