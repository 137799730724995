import { createAction, props } from '@ngrx/store';
import { ProfileType, UserRole } from '../../models/user.model';
import { CompanyType } from '../../models/company.model';
import { Role } from '../../services/company.service';

export interface MsalActiveProfile {
  given_name: string;
  family_name: string;
  email: string;
}
export interface QuantumProfile extends ProfileType {}

export const loginSuccess = createAction('[Azure MSAL] Login Success');

export const logout = createAction('[Azure MSAL] Logout');
export const loadingMsalActiveProfile = createAction(
  '[Profile] MSALProfile pending'
);

export const loadingProfile = createAction('[Profile] loading profile');

export const loadingMsalActiveProfileSuccess = createAction(
  '[Profile] MSALProfile loaded',
  props<{ msalProfile: MsalActiveProfile }>()
);

export const loadingMsalActiveProfileError = createAction(
  '[Profile] MSALProfile error',
  props<{ error: any }>()
);

export const loadingQuantumProfile = createAction(
  '[Profile] QuantumProfile pending'
);

export const loadingQuantumProfileSuccess = createAction(
  '[Profile] QuantumProfile loaded',
  props<{ quantumProfile: QuantumProfile }>()
);

export const loadingQuantumProfileError = createAction(
  '[Profile] QuantumProfile error',
  props<{ error: any }>()
);

export const editingQuantumProfile = createAction(
  '[Profile Form] Edit QuantumProfile',
  props<{ quantumProfile: QuantumProfile }>()
);

export const editingQuantumProfileSuccess = createAction(
  '[Profile Form] Edit QuantumProfile Success',
  props<{ updatedQuantumProfile: QuantumProfile }>()
);

export const editingQuantumProfileError = createAction(
  '[Profile Form] Edit QuantumProfile Error',
  props<{ error: any }>()
);

export const loadingProfileRole = createAction(
  '[Profile] loading profile role',
  props<{ companyId: string; userId: string }>()
);

export const loadingProfileRoleSuccess = createAction(
  '[Profile] loading profile role success',
  props<{ role: UserRole }>()
);

export const loadingProfileRoleError = createAction(
  '[Profile] loading profile role error',
  props<{ error: any }>()
);

export const updatingProfileRole = createAction(
  '[Profile] update profile role',
  props<{ memberId: string; companyId: string; role: UserRole }>()
);

export const updatingProfileRoleSuccess = createAction(
  '[Profile] update profile role success',
  props<{ role: UserRole }>()
);

export const updatingProfileRoleError = createAction(
  '[Profile] update profile role error',
  props<{ error: any }>()
);
