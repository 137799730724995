import {Component, Input, OnDestroy} from '@angular/core';
import {LoadingService} from "../../services/loading.service";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-fullscreen-loading-spinner',
  templateUrl: './fullscreen-loading-spinner.component.html',
  styleUrls: ['./fullscreen-loading-spinner.component.scss']
})
export class FullscreenLoadingSpinnerComponent {
  loading$: Observable<Boolean>

  constructor(private loadingService : LoadingService) {
    this.loading$ =
      this.loadingService.getObservable();
  }
}
