import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {
  AbstractControl, ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import {Store} from "@ngrx/store";

type ApplicationsOptionsInputFormGroup = FormGroup<{options: FormArray<FormControl>}>
@Component({
  selector: 'app-applications-options-input',
  templateUrl: './applications-options-input.component.html',
  styleUrls: ['../inputs.scss', './applications-options-input.component.scss']
})
export class ApplicationsOptionsInputComponent implements OnInit {

  placeholder: string = 'Choose...';
  showApplication: boolean = false;
  isDisabled: boolean = true;

  optionsBasedOnIndustry: string[] = [];
  @Input() selectedOptions: string[] = [];
  @HostBinding('style.min-width')
  get minWidth(): string {
    if(this.getValues().at(0)) {
      return 'max-content';
    }
    return '';
  }

  public static readonly applicationOptionsGroup: () => ApplicationsOptionsInputFormGroup = () => new FormGroup({
    options: new FormArray<FormControl>([])
  })
  applicationOptionsGroup!: ApplicationsOptionsInputFormGroup
  checkBoxes: ({control: FormControl, value: string})[] = []
  checkboxes = this.sortedCheckboxes();

  constructor(private controlContainer: ControlContainer, private store: Store) {
  }

  ngOnInit() {
    this.applicationOptionsGroup = this.controlContainer.control as ApplicationsOptionsInputFormGroup;
    this.initForm();
  }

  initForm() {
    if(this.applicationOptionsGroup?.controls.options) {
      this.checkBoxes = this.optionsBasedOnIndustry.map(
        opt => ({
          control: new FormControl(this.selectedOptions.includes(opt)),
          value: opt
        })
      )
      this.checkBoxes.map(cb => {
        this.applicationOptionsGroup!.controls.options.push(cb.control);
      })
      this.applicationOptionsGroup.setValidators([this.validation()]);
      this.applicationOptionsGroup.updateValueAndValidity();
      this.applicationOptionsGroup.markAsDirty();
    }
  }

  validation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isDisabled) {
        return null;
      }
      const result: ValidationErrors = { minOneSelected: "at least one application required" };
      const formArray = control.get('options') as FormArray;
      const selectedOptions = formArray.controls.some((cntrl) => cntrl.value);
      return selectedOptions ? null : result;
    };
  }

  toggleShowingApplication() {
    this.checkboxes = this.sortedCheckboxes();
    this.showApplication = !this.showApplication;
  }

  close() {
    this.showApplication = false;
  }

  @Input()
  set changeOptions(options: string[] | null) {
    if(options?.length) {
      if(!options.some(opt => this.checkBoxes.map(cb => cb.value).includes(opt))) {
        this.checkBoxes.forEach(cb => {
          cb.control.setValue(false);
        })
      }
      this.optionsBasedOnIndustry = options;
      this.isDisabled = false;
    } else {
      this.optionsBasedOnIndustry = [];
      this.checkBoxes.forEach(cb => {
        cb.control.setValue(false);
      })
      this.isDisabled = true;
    }
    this.initForm();
  }

  getValues() {
    return this.checkBoxes.filter(cb => cb.control.value).map(
      cb => cb.value
    )
  }

  sortedCheckboxes() {
    return this.checkBoxes.sort((a, b) => {
      if (a.control.value === true && b.control.value !== true) {
        return -1;
      } else if (a.control.value !== true && b.control.value === true) {
        return 1;
      } else {
        return 0;
      }
    })
  }

  selectedCheckboxes() {
    return this.checkBoxes.filter(cb => cb.control.value)
  }
}
