import { LoadingState, ProfileState } from './store.states';
import * as CompanyActions from './company/company.actions';
import * as ProfileActions from './profile/profile.actions';
import * as EquipmentActions from './equipment/equipment.actions';
import { Store } from '@ngrx/store';
import { selectProfileState } from './profile/profile.selector';
import { take } from 'rxjs';
import { selectCompanyState } from './company/company.selector';
import { filter } from 'rxjs/operators';
import { loadingProfile } from './profile/profile.actions';

let lastLoad = {
  profile: new Date(),
  company: new Date(),
  members: new Date(),
  equipment: new Date(),
};

const LOADING_LOCK_TIME_IN_MS = 30000;

export const dispatchLoadingProfileWithCompanyAndEquipment = (store: Store) => {
  lastLoad.profile = new Date();
  store.dispatch(ProfileActions.loadingProfile());
  dispatchLoadingCompany(store);
  dispatchLoadingMembers(store);
  dispatchLoadingEquipment(store);
};

export const dispatchLoadingProfileTimeLocked = (store: Store) => {
  let currentTime = new Date();
  if (
    currentTime.getTime() - lastLoad.profile.getTime() >
    LOADING_LOCK_TIME_IN_MS
  ) {
    store.dispatch(loadingProfile());
    lastLoad.profile = currentTime;
  }
};

export const dispatchLoadingCompanyTimeLocked = (store: Store) => {
  let currentTime = new Date();
  if (
    currentTime.getTime() - lastLoad.company.getTime() >
    LOADING_LOCK_TIME_IN_MS
  ) {
    dispatchLoadingCompany(store);
    lastLoad.company = currentTime;
  }
};

export const dispatchLoadingMembersTimeLocked = (store: Store) => {
  let currentTime = new Date();
  if (
    currentTime.getTime() - lastLoad.members.getTime() >
    LOADING_LOCK_TIME_IN_MS
  ) {
    dispatchLoadingMembers(store);
    lastLoad.members = currentTime;
  }
};

export const dispatchLoadingCompany = (store: Store) => {
  store
    .select(selectProfileState)
    .pipe(
      filter(
        (profile) =>
          profile.loadingState === LoadingState.SUCCESS && !!profile.companyId
      ),
      take(1)
    )
    .subscribe((profile: ProfileState) => {
      store.dispatch(
        CompanyActions.loadingCompany({ companyId: profile.companyId })
      );
      store.dispatch(
        CompanyActions.loadingCompanyAvatar({ companyId: profile.companyId })
      );
      lastLoad.company = new Date();
    });
};

export const dispatchLoadingMembers = (store: Store) => {
  store
    .select(selectCompanyState)
    .pipe(
      filter(
        (company) =>
          company.loadingState === LoadingState.SUCCESS && !!company.id
      ),
      take(1)
    )
    .subscribe((company) => {
      store.dispatch(
        CompanyActions.loadingCompanyMembers({
          companyId: company.id!,
        })
      );
      lastLoad.members = new Date();
    });
};

export const dispatchLoadingEquipment = (store: Store) => {
  store
    .select(selectCompanyState)
    .pipe(
      filter(
        (company) =>
          company.loadingState === LoadingState.SUCCESS && !!company.id
      ),
      take(1)
    )
    .subscribe((company) => {
      store.dispatch(EquipmentActions.loadingEquipment(
        {
          companyId: company.id!
        }
      ));
      lastLoad.equipment = new Date();
    });
};
