import { Component, Inject, Input } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MissionControlService } from 'src/app/services/mission-control.service';
import { ResultUrlInputComponent } from '../../inputs/result-url-input/result-url-input.component';

@Component({
  selector: 'app-complete-mission-modal',
  templateUrl: './complete-mission-modal.component.html',
  styleUrls: ['./complete-mission-modal.component.scss'],
})
export class CompleteMissionModalComponent {
  @Input() open: boolean = false;
  errorMessage: string = '';
  form: FormGroup = this.formBuilder.nonNullable.group({
    url: ResultUrlInputComponent.resultUrlControl(),
  });

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA) public data: { missionId: string },
    private formBuilder: FormBuilder,
    private missionControlService: MissionControlService
  ) {}

  abortModal(): void {
    this.dialogRef.close();
  }

  finishModal(): void {
    this.dialogRef.close({});
  }

  completeMission() {
    this.missionControlService
      .postMissionResult({
        missionId: this.data.missionId,
        resultUrl: this.form.value.url,
      })
      .subscribe((mission) => {
        this.dialogRef.close(mission);
      });
  }
}
