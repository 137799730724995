<div class="group">
  <label>First name</label>
  <input
    type="text"
    placeholder="First name"
    class="quantum-input"
    [attr.disabled]="true"
    [value]="firstName"
  />
</div>
