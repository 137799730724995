import { createAction, props } from '@ngrx/store';
import { CompanyType, MemberType } from '../../models/company.model';

export const createCompany = createAction(
  '[Company] creating company data',
  props<{ company: CompanyType, avatar: string }>()
);

export const creatingCompanySuccess = createAction(
  '[Company] creating company success',
  props<{ company: CompanyType, avatar: string}>()
);

export const creatingCompanyError = createAction(
  '[Company] creating company error',
  props<{ error: any }>()
);

export const loadingCompany = createAction(
  '[Company] loading company data',
  props<{ companyId: string }>()
);

export const deletingCompany = createAction(
  '[Company] deleting company',
  props<{ companyId: string }>()
);
export const deletingCompanySuccess = createAction(
  '[Company] delete company success'
);
export const deletingCompanyError = createAction(
  '[Company] delete company error',
  props<{ error: any }>()
);

export const loadingCompanySuccess = createAction(
  '[Company] loading company data success',
  props<{ company: CompanyType }>()
);

export const loadingCompanyError = createAction(
  '[Company] loading company data error',
  props<{ error: any }>()
);

export const loadingCompanyMembers = createAction(
  '[Member] loading company members',
  props<{ companyId: string }>()
);

export const loadingCompanyMembersSuccess = createAction(
  '[Member] loading company members success',
  props<{ members: MemberType[] }>()
);
export const loadingCompanyMembersError = createAction(
  '[Member] loading company members error',
  props<{ error: any }>()
);

export const editCompany = createAction(
  '[Company] editing company data',
  props<{ company: CompanyType }>()
);

export const editingCompanySuccess = createAction(
  '[Company] editing company success',
  props<{ company: CompanyType }>()
);

export const editingCompanyError = createAction(
  '[Company] editing company error',
  props<{ error: any }>()
);

export const loadingCompanyAvatar = createAction(
  '[Company] getting company avatar',
  props<{ companyId: string }>()
);

export const loadingCompanyAvatarSuccess = createAction(
  '[Company] getting company avatar success',
  props<{ avatar: string }>()
);

export const loadingCompanyAvatarError = createAction(
  '[Company] getting company avatar error',
  props<{ error: any }>()
);

export const updateCompanyAvatar = createAction(
  '[Company] updating company avatar',
  props<{ companyId: string; avatar: string }>()
);

export const updateCompanyAvatarSuccess = createAction(
  '[Company] updating company avatar success',
  props<{ avatar: string }>()
);

export const updateCompanyAvatarError = createAction(
  '[Company] updating company avatar error',
  props<{ error: any }>()
);

export const deleteMember = createAction(
  '[Company] delete company member',
  props<{ memberId: string; companyId: string }>()
);
export const deleteMemberSuccess = createAction(
  '[Company] delete company member success',
  props<{ memberId: string }>()
);
export const deleteMemberError = createAction(
  '[Company] delete company member error',
  props<{ error: any }>()
);

export const leaveCompany = createAction(
  '[Company] leaving the company',
  props<{ memberId: string; companyId: string }>()
);
export const leaveCompanySuccess = createAction(
  '[Company] leaving the company success',
  props<{ memberId: string }>()
);
export const leaveCompanyError = createAction(
  '[Company] leaving the company error',
  props<{ error: any }>()
);
