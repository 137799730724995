import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompanyService } from '../../services/company.service';
import * as CompanyActions from './company.actions';
import * as ProfileActions from '../profile/profile.actions';
import { concatMap, exhaustMap, of, tap } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AvatarService } from '../../services/avatar.service';

@Injectable()
export class CompanyEffects {
  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.createCompany),
      exhaustMap(({ company, avatar }) =>
        this.companyService.createCompany(company).pipe(
          map((company) =>
            CompanyActions.creatingCompanySuccess({
                company: company,
                avatar: avatar
            })
          ),
          catchError((error) =>
            of(CompanyActions.creatingCompanyError({ error: error }))
          )
        )
      )
    )
  );
  loadCompanyData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadingCompany),
      exhaustMap(({ companyId }) =>
        this.companyService.getCompany(companyId).pipe(
          map((company) =>
            CompanyActions.loadingCompanySuccess({
              company: company,
            })
          ),
          catchError((error) =>
            of(CompanyActions.loadingCompanyError({ error: error }))
          )
        )
      )
    )
  );
  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.deletingCompany),
      exhaustMap(({ companyId }) =>
        this.companyService.deleteCompany(companyId).pipe(
          map(() => CompanyActions.deletingCompanySuccess()),
          catchError((error) =>
            of(CompanyActions.deletingCompanyError({ error: error }))
          )
        )
      )
    )
  );
  loadCompanyMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadingCompanyMembers),
      exhaustMap(({ companyId }) =>
        this.companyService.getCompanyMembers(companyId).pipe(
          map((members) =>
            CompanyActions.loadingCompanyMembersSuccess({ members: members })
          ),
          catchError((error) =>
            of(CompanyActions.loadingCompanyMembersError({ error: error }))
          )
        )
      )
    )
  );
  updateAfterCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.creatingCompanySuccess),
      concatMap(({ company , avatar}) => [
        ProfileActions.loadingProfile(),
        CompanyActions.loadingCompanyMembers({
          companyId: company.id,
        }),
          CompanyActions.updateCompanyAvatar({
              companyId: company.id, avatar: avatar
          })
      ])
    )
  );
  editingCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.editCompany),
      exhaustMap(({ company }) =>
        this.companyService.editCompany(company).pipe(
          map((company) =>
            CompanyActions.editingCompanySuccess({ company: company })
          ),
          catchError((error) =>
            of(CompanyActions.editingCompanyError({ error: error }))
          )
        )
      )
    )
  );

  postLoadCompanyAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadingCompanySuccess),
      concatMap(({ company }) => [
        CompanyActions.loadingCompanyAvatar({
          companyId: company.id,
        }),
      ])
    )
  );

  loadingCompanyAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadingCompanyAvatar),
      exhaustMap(({ companyId }) =>
        this.avatarService.getCompanyAvatar(companyId).pipe(
          map((avatar) =>
            CompanyActions.loadingCompanyAvatarSuccess({
              avatar: avatar.base64EncodedImage,
            })
          ),
          catchError((error) =>
            of(CompanyActions.loadingCompanyAvatarError({ error: error }))
          )
        )
      )
    )
  );

  updateCompanyAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.updateCompanyAvatar),
      exhaustMap(({ companyId, avatar }) =>
        this.avatarService.updateCompanyAvatar(companyId, avatar).pipe(
          map((updatedAvatar) =>
            CompanyActions.updateCompanyAvatarSuccess({
              avatar: updatedAvatar.base64EncodedImage,
            })
          ),
          catchError((error) =>
            of(CompanyActions.updateCompanyAvatarError({ error: error }))
          )
        )
      )
    )
  );

  deleteCompanyMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.deleteMember),
      exhaustMap(({ memberId, companyId }) =>
        this.companyService.deleteMember(memberId, companyId).pipe(
          map(() => CompanyActions.deleteMemberSuccess({ memberId: memberId })),
          catchError((error) =>
            of(CompanyActions.deleteMemberError({ error: error }))
          )
        )
      )
    )
  );

  leaveCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.leaveCompany),
      exhaustMap(({ memberId, companyId }) =>
        this.companyService.deleteMember(memberId, companyId).pipe(
          mergeMap(() => [
            CompanyActions.leaveCompanySuccess({ memberId: memberId }),
            CompanyActions.deletingCompanySuccess(),
            ProfileActions.loadingProfile(),
          ]),
          catchError((error) =>
            of(CompanyActions.leaveCompanyError({ error: error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private avatarService: AvatarService
  ) {}
}
