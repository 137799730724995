<button
  *ngIf="!searchType"
  class="quantum-button"
  [class.gross]="gross"
  [class.small]="small"
  [class.medium]="medium"
  [disabled]="disabled"
  [class.red]="redAlert"
>
  {{ title }}
</button>

<div class="container" *ngIf="searchType" style="visibility: hidden">
  <form>
    <input type="search" placeholder="Search" />
    <button [disabled]="disabled" type="submit">Search</button>
  </form>
</div>
