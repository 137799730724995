<div class="modal-container">
  <div class="modal-header">
    <span>Are you sure you want to {{ data.action }} this company group?</span>
  </div>
  <div class="seperator">
    <hr />
  </div>
  <div class="modal-body" *ngIf="company$ | async; let company">
    <span *ngIf="!errorMessage && data.leaving">
      You going to leave the group
      <span class="company-name">{{
        company.name || "Adorsys GmbH & Co.KG"
      }}</span
      >. After leaving this group, you lose access to the equipment page. Then
      you can join another group or create a new one.
    </span>
    <span *ngIf="!errorMessage && !data.leaving">
      You going to delete the group
      <span class="company-name">{{
        company.name || "Adorsys GmbH & Co.KG"
      }}</span
      >. After deleting this group, you and all members will lose access to the
      added equipment. Your data will be deleted. You can then create a new
      group.
    </span>
    <span class="error-message" *ngIf="errorMessage">
      {{ errorMessage }}
    </span>
    <div class="modal-content">
      <div class="buttons">
        <app-button
          [small]="true"
          [title]="'CANCEL'"
          (click)="abortModal()"
        ></app-button>
        <app-button
          [redAlert]="true"
          [small]="true"
          [title]="data.buttonTitel"
          (click)="deleteGroup(company.id)"
        ></app-button>
      </div>
    </div>
  </div>
</div>
