import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResources } from '../auth-config';
import {ProfileType, UserRole} from '../models/user.model';
import {Role} from "./company.service";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  apiEndpoint: string = protectedResources.apiTodoList.endpoint;

  getProfile() {
    return this.http.get<ProfileType>(`${this.apiEndpoint}/profile`);
  }

  editProfile(data: ProfileType) {
    return this.http.post<ProfileType>(`${this.apiEndpoint}/profile`, data);
  }

  changeProfileRole(memberId: string, companyId: string, role: Role) {
    return this.http.put<Role>(`${this.apiEndpoint}/companies/${companyId}/roles/${memberId}`, role)
  }

  static isProfileComplete(data: ProfileType): boolean
  {
    return (
      Boolean(data.firstName && data.lastName && data.jobTitle)
    )
  }
}
