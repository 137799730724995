import {createAction, props} from "@ngrx/store";
import {Equipment} from "../../models/equipment.model";

export const loadingEquipment = createAction(
  '[Equipment] loading equipment',
  props<{companyId: string}>()
)

export const loadingEquipmentSuccess = createAction(
  '[Equipment] loading equipment success',
  props<{equipment: Equipment}>()
)

export const loadingEquipmentError = createAction(
  '[Equipment] loading equipment error',
  props<{error: any}>()
)

export const activatingAircraft = createAction(
  '[Equipment] activating aircraft',
  props<{serial: string, companyId: string}>()
)

export const activatingAircraftSuccess = createAction(
  '[Equipment] activating aircraft success',
  props<{companyId: string}>()
)

export const activatingAircraftError = createAction(
  '[Equipment] activating aircraft error',
  props<{error: any}>()
)
