<div class="modal-container">
  <div class="modal-header">
    <span>Create New Mission</span>
    <div class="modal-header-close-btn" (click)="abortModal()">
      <span class="icon-close"></span>
    </div>
  </div>

  <div class="seperator">
    <hr />
  </div>

  <div class="modal-body">
    <span class="error-message" *ngIf="errorMessage">
      {{ errorMessage }}
    </span>

    <form [formGroup]="form" class="send-email" (ngSubmit)="planMission()">
      <app-mission-name-input
        [controllerName]="'name'"
        label="Mission Name"
        placeholder="Enter mission"
      ></app-mission-name-input>

      <app-operator-options-input
        [operatorOptions]="operatorOptions"
        [initValue]="''"
        [formGroupName]="'operator'"
      ></app-operator-options-input>

      <app-drone-options-input
        [droneOptions]="droneOptions"
        [initValue]="''"
        [formGroupName]="'drone'"
      ></app-drone-options-input>

      <app-timestamp-input
        [controllerName]="'timestamp'"
        label="Planned At"
        [initValue]="''"
        placeholder="Enter time stamp"
      ></app-timestamp-input>

      <div class="button-bar">
        <app-button
          [medium]="true"
          [title]="'PLAN MISSION...'"
          [disabled]="form.invalid"
        ></app-button>
      </div>
    </form>
  </div>
</div>
