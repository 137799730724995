<div class="profile-container" *ngIf="profile$ | async; let profileState">
  <form
    [formGroup]="form"
    [noValidate]="false"
    (ngSubmit)="update()"
    *ngIf="profileState.loadingState === LoadingState.SUCCESS"
  >
    <app-avatar
      (imagebase64)="getAvatar($event)"
      [avatarInput]="true"
    ></app-avatar>
    <div class="profile-block">
      <app-firstname-input
        [firstName]="profileState.firstName"
      ></app-firstname-input>
      <app-lastname-input
        [lastName]="profileState.lastName"
      ></app-lastname-input>
      <app-jobtitle-input
        [initValue]="profileState.jobTitle"
        [value]="profileState.jobTitle"
        [controllerName]="'jobtitle'"
      ></app-jobtitle-input>
      <app-email-input
        [disabled]="true"
        [readonly]="true"
        [ignoreValidation]="true"
        [initValue]="profileState.email"
        [value]="profileState.email"
        [controllerName]="'email'"
      ></app-email-input>
    </div>
    <div class="button-container">
      <app-response-banner
        successMessage="Profile successful saved and updated"
        errorMessage="Something went wrong, try again"
        [success]="successfulEdit"
        [invisible]="!showResponseBanner"
      ></app-response-banner>
      <app-button
        [disabled]="form.invalid"
        [title]="'SAVE PROFILE'"
      ></app-button>
    </div>
  </form>
</div>
