import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-fallback',
  templateUrl: './fallback.component.html',
  styleUrls: ['./fallback.component.scss']
})
export class FallbackComponent implements OnInit {
  @Input() show: boolean = false;

  ngOnInit(): void {
    setTimeout(() => this.show = false, 5000);
  }
}
