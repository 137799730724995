import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { protectedResources } from '../auth-config';
import { CompanyType, MemberType } from '../models/company.model';
import { map, Observable } from 'rxjs';
import { UserRole } from '../models/user.model';

export interface Role {
  companyRole: number;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}
  apiEndpoint: string = protectedResources.apiTodoList.endpoint;

  getCompany(companyId: string) {
    return this.http.get<CompanyType>(
      `${this.apiEndpoint}/companies/${companyId}`
    );
  }

  getCompanyMembers(companyId: string): Observable<MemberType[]> {
    return this.http
      .get<any[]>(`${this.apiEndpoint}/companies/${companyId}/members`)
      .pipe(
        map((members) =>
          members.map((member) => ({
            ...member,
            role: member.role === 1 ? UserRole.ADMIN : UserRole.PILOT,
          }))
        )
      );
  }

  editCompany(data: CompanyType) {
    return this.http.put<CompanyType>(
      `${this.apiEndpoint}/companies/${data.id}`,
      data
    );
  }

  createCompany(data: CompanyType) {
    return this.http.post<CompanyType>(`${this.apiEndpoint}/companies`, data);
  }

  getUsersCompanyRole(userId: string, companyId?: string) {
    return this.http
      .get<Role>(`${this.apiEndpoint}/companies/${companyId}/roles/${userId}`)
      .pipe(
        map(({ companyRole }) => {
          return {
            role: companyRole === 1 ? UserRole.ADMIN : UserRole.PILOT,
          };
        })
      );
  }

  getInviteLinkAsBase64(companyId: string) {
    return this.http
      .get(`${this.apiEndpoint}/companies/${companyId}/invite`, {
        responseType: 'text',
      })
      .pipe(map((inviteLink) => btoa(inviteLink)));
  }

  sendInviteViaEmail(companyId: string, email: string) {
    return this.http
      .post(`${this.apiEndpoint}/companies/${companyId}/invite`, {
        email: email,
        redirectUri: window.location.host + '/join'
      })
  }

  deleteMember(memberId: string, companyId: string) {
    return this.http.delete(
      `${this.apiEndpoint}/companies/${companyId}/members/${memberId}`
    );
  }

  deleteCompany(companyId: string) {
    return this.http.delete(`${this.apiEndpoint}/companies/${companyId}`);
  }

  static isCompanyComplete(data: CompanyType): boolean {
    return Boolean(
      data.country &&
        data.name &&
        data.industry &&
        data.stateOrProvince
    );
  }
}
