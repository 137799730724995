<div class="group" appActionOnOutsideClick (clickOutside)="close()">
  <label>Operator</label>
  <div class="selected-item" [ngClass]="showOperator ? 'arrow-down' : ''">
    <div
      class="option"
      [ngClass]="operatorOptionsGroup.invalid ? 'invalid' : 'valid'"
      [class.dirty]="operatorOptionsGroup.dirty"
    >
      <input type="button" name="group" (click)="toggleShowingOperator()" />
      <p *ngIf="operatorOptionsGroup.value?.operator">
        {{ operatorOptionsGroup.value.operator }}
      </p>
      <p class="placeholder" *ngIf="!operatorOptionsGroup.value.operator">
        Choose...
      </p>
    </div>
  </div>
  <div
    class="option-wrap"
    [ngClass]="showOperator ? 'active' : ''"
    appTypeAheadFocus
  >
    <div
      class="option children"
      *ngFor="let option of operatorOptions"
      [formGroup]="operatorOptionsGroup"
    >
      <input
        type="radio"
        name="operator"
        [formControlName]="'operator'"
        (click)="toggleShowingOperator()"
        [value]="option.email"
      />
      <p>{{ option.email }}</p>
    </div>
  </div>
  <div
    *ngIf="operatorOptionsGroup.invalid && !showOperator"
    [class.dirty]="operatorOptionsGroup.dirty"
    class="error-message"
  >
    <div>*required</div>
  </div>
</div>
