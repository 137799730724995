import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, Validators} from "@angular/forms";

type StateOrProvinceInputFormControl = FormControl<string>
@Component({
  selector: 'app-state-or-province-input',
  templateUrl: './state-or-province-input.component.html',
  styleUrls: ['./state-or-province-input.component.scss', '../inputs.scss']
})
export class StateOrProvinceInputComponent implements OnInit{
  @Input() initValue: string = "";
  @Input() controllerName: string = "";
  stateOrProvinceControl!: StateOrProvinceInputFormControl;

  public static readonly stateOrProvinceControl: () => StateOrProvinceInputFormControl = () => new FormControl();
  constructor(private controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.stateOrProvinceControl = (this.controlContainer.control as FormGroup).get(this.controllerName) as StateOrProvinceInputFormControl;
    this.stateOrProvinceControl.setValue(this.initValue);
    this.stateOrProvinceControl.setValidators([Validators.required, Validators.maxLength(100)])
    this.stateOrProvinceControl.updateValueAndValidity();
  }

}
