import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { HelpComponent } from './pages/help/help.component';
import { AcademyComponent } from './pages/academy/academy.component';
import { EquipmentComponent } from './pages/equipment/equipment.component';
import { ProgressComponent } from './pages/progress/progress.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { MembersTableComponent } from './components/members-table/members-table.component';
import { CompanyResolverComponent } from './components/company-resolver/company-resolver.component';
import { JoinComponent } from './pages/join/join.component';
import { MissionsComponent } from './pages/missions/missions.component';
import { MissionPlannerComponent } from './components/mission-planner/mission-planner.component';

const routes: Routes = [
  { path: '', redirectTo: '/progress', pathMatch: 'full' },
  {
    path: 'join/:link',
    component: JoinComponent,
  },
  {
    path: 'progress',
    canActivate: [MsalGuard],
    component: ProgressComponent,
  },
  {
    path: 'dashboard',
    canActivate: [MsalGuard],
    component: DashboardComponent,
    children: [
      {
        path: 'profile',
        component: ProfileFormComponent,
      },
      {
        path: 'company',
        component: CompanyResolverComponent,
      },
      {
        path: 'members',
        component: MembersTableComponent,
      },
      {
        path: '**',
        redirectTo: 'profile',
      },
    ],
  },
  {
    path: 'equipment',
    canActivate: [MsalGuard],
    component: EquipmentComponent,
  },
  {
    path: 'missions',
    canActivate: [MsalGuard],
    component: MissionsComponent,
  },
  {
    path: 'missions/:missionID',
    canActivate: [MsalGuard],
    component: MissionPlannerComponent,
  },
  {
    path: 'missions/planner',
    component: MissionPlannerComponent,
  },
  {
    path: 'academy',
    canActivate: [MsalGuard],
    component: AcademyComponent,
  },
  {
    path: 'help',
    canActivate: [MsalGuard],
    component: HelpComponent,
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
