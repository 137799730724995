<div class="header">
  <div><h1>Missions</h1></div>
  <div *ngIf="profile$ | async; let profile">
    <div
      *ngIf="profile.role === 'ADMIN'"
      class="new-drone"
      (click)="openNewMissionDialog()"
    >
      + add Mission
    </div>
  </div>
</div>
<div class="equipment-container">
  <div class="block">
    <div class="row-6-headline">
      <div class="headline">Mission Name</div>
      <div>Operator</div>
      <div>Drone</div>
      <div>Planned At</div>
      <div>Result</div>
      <div>Status</div>
    </div>
    <span class="break-line"></span>
    <div class="container-content">
      <div *ngIf="missions.length > 0">
        <div *ngFor="let mission of missions; let i = index">
          <div
            [ngClass]="{
              clickable: mission.status !== 'PLANNING',
              highlight: mission.status === 'PLANNING'
            }"
            class="row-6 content"
            (click)="
              mission.status !== 'PLANNING' && toMission($event, mission)
            "
          >
            <div class="drone-content">
              {{ mission.name }}
            </div>
            <div>
              {{ getMemberEmailByOperatorId(mission.operatorId) | async }}
            </div>
            <div>{{ mission.aircraftId }}</div>
            <div>{{ mission.plannedAt }}</div>

            <a
              *ngIf="mission.missionResult; else empty"
              href="{{ mission.missionResult.resultUrl }}"
              target="_blank"
              rel="noreferrer"
              class="ellipsis"
              (click)="stopPropagation($event)"
              >{{ mission.missionResult.resultUrl }}
            </a>
            <ng-template #empty><span>-</span></ng-template>

            <div *ngIf="mission.status === 'PLANNING'">
              <app-button
                [small]="true"
                [title]="'Assign'"
                (click)="assignMissionToOperator($event, mission.missionID)"
              ></app-button>
            </div>
            <div *ngIf="mission.status === 'ASSIGNED'">
              <app-button
                [small]="true"
                [title]="'Complete...'"
                (click)="openCompleteMissionModal($event, mission.missionID)"
              ></app-button>
            </div>
            <div *ngIf="mission.status === 'DONE'" class="status">
              <div>{{ mission.status }}</div>
            </div>
          </div>
          <span
            class="content-line"
            *ngIf="missions.length ?? 0 - 1 !== i"
          ></span>
        </div>
      </div>
      <div class="empty-list" *ngIf="missions.length === 0">
        <div *ngIf="profile$ | async; let profile">
          <span *ngIf="profile.role === 'ADMIN'; else nonAdmin">
            No missions. Create a
            <a href="javascript:;" (click)="openNewMissionDialog()"
              >new mission</a
            >.
          </span>
          <ng-template #nonAdmin>
            No missions. Contact you fleet manager for mission assignments.
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
