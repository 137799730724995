import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AvatarService} from "../../services/avatar.service";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-avatar-company',
  templateUrl: './avatar-company.component.html',
  styleUrls: ['./avatar-company.component.scss'],
})
export class AvatarCompanyComponent {
  @Input() name: string = '';
  @Input() area: string = '';
  @Input() subarea: string = '';
  @Input() address: string = '';
  @Input() avatar: string = '';
  @Output() imagebase64 = new EventEmitter<string>();
  preview = '';
  previewURL?: any;
  limit: number = 202175;
  isAvatarTooBig: boolean = false;
  currentAvatar?: string = '';

  constructor(private avatarService: AvatarService) {
  }

  isInfoAvatar() {
    return this.name && this.area && this.address;
  }

  selectFile(event: any) {
    let result = this.avatarService.selectFile(event, this.imagebase64, this.currentAvatar!);
    this.isAvatarTooBig = result.tooBig;
    if (!this.isAvatarTooBig) {
      this.preview = result.preview;
      this.avatarService.fileToDataURL(result.file!, (url) => {
        this.previewURL = url;
      })
    }
  }
}
