<ng-container *ngIf="profile$ | async; let profile">
  <div class="dashboard-response" *ngIf="responseBanner$ | async; let responseBanner">
    <app-response-banner
      [success]="responseBanner.success"
      [invisible]="!responseBanner.open"
      [successMessage]="responseBanner.successMessage"
      [errorMessage]="responseBanner.errorMessage"
    ></app-response-banner>
  </div>
  <div class="tab-navigation">
    <app-tab-button
      [active]="router.url.includes('profile')"
      title="PROFILE"
      routerLink="profile"
    ></app-tab-button>
    <app-tab-button
      [active]="router.url.includes('company')"
      title="COMPANY"
      routerLink="company"
      *ngIf="profile.completed"
    ></app-tab-button>
    <app-tab-button
      [active]="router.url.includes('members')"
      title="MEMBERS"
      routerLink="members"
      *ngIf="profile.role !== UserRole.PILOT && (company$ | async)?.completed"
    ></app-tab-button>
  </div>
  <div class="tab-container">
    <router-outlet></router-outlet>
  </div>
</ng-container>
