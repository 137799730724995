import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import {Store} from "@ngrx/store";
import {selectProfileData} from "../../stores/profile/profile.selector";
import {ProfileState} from "../../stores/store.states";
import {dispatchLoadingProfileWithCompanyAndEquipment} from "../../stores/store.utils";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  constructor(private store: Store) {
    dispatchLoadingProfileWithCompanyAndEquipment(this.store);
  }
  user$: Observable<ProfileState> = this.store.select(selectProfileData)
}
