import * as EquipmentAction from "./equipment.actions";
import {createEntityAdapter} from "@ngrx/entity";
import {Aircrafts, Software} from "../../models/equipment.model";
import {EquipmentState, LoadingState} from "../store.states";
import {createReducer, on} from "@ngrx/store";

export const aircraftsAdapter = createEntityAdapter<Aircrafts>({
  selectId: (aircraft) => aircraft.serialNumber,
  sortComparer: false
});

export const softwareAdapter = createEntityAdapter<Software>({
  selectId: (software) => software.downloadLink,
  sortComparer: false
})

export const initialState: EquipmentState = {
  aircrafts: aircraftsAdapter.getInitialState(),
  software: softwareAdapter.getInitialState(),
  loadingState: LoadingState.FUTURE,
  updateState: LoadingState.FUTURE,
  error: null,
  completed: false
}

export const equipmentReducer = createReducer(
  initialState,
  on(EquipmentAction.loadingEquipment, state => ({
    ...state,
    loadingState: LoadingState.PENDING,
    completed: false
  })),
  on(EquipmentAction.loadingEquipmentSuccess, (state, {equipment}) => (
    {
      ...state,
      loadingState: LoadingState.SUCCESS,
      aircrafts: aircraftsAdapter.setAll(equipment.aircrafts, state.aircrafts),
      software: softwareAdapter.setAll(equipment.software, state.software),
      completed:
        !!(equipment.aircrafts.length && equipment.software.length
          && equipment.aircrafts.at(0)?.name && equipment.software.at(0)?.name
        )
    }
  )),
  on(EquipmentAction.loadingEquipmentError, (state, {error}) => ({
    ...state,
    loadingState: LoadingState.ERROR,
    error: error,
    completed: false
  })),
  on(EquipmentAction.activatingAircraft, (state) => ({
    ...state,
    updateState: LoadingState.PENDING
  })),
  on(EquipmentAction.activatingAircraftSuccess, (state) => ({
    ...state,
    updateState: LoadingState.SUCCESS
  })),
  on(EquipmentAction.activatingAircraftError, (state, {error}) => ({
    ...state,
    updateState: LoadingState.ERROR,
    error: error
  }))
)
