<ng-container *ngIf="(step$ | async)===1">
  <div class="progress-container" *ngIf="(profile$ | async) as profile">
    <ng-container *ngIf="!profile.completed">
      <div class="progress-text">
        <h1>Hello {{ profile.firstName }},</h1>
        <p>
          You must first fill out your profile completely. After that have to join a group or create a group.
        </p>
      </div>
      <app-button
        [title]="'BACK TO DASHBOARD'"
        routerLink="/dashboard"
      ></app-button>
    </ng-container>
    <ng-container *ngIf="profile.completed">
      <div class="progress-text">
        <h1>Hello {{ profile.firstName }},</h1>
        <p>
          You must first join a group or create a group. After that you can add
          your drone.
        </p>
      </div>
      <app-button
        [title]="'BACK TO DASHBOARD'"
        routerLink="/dashboard/company"
      ></app-button>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="(step$ | async)===2">
  <div class="progress-container">
    <div class="progress-text">
      <h1>Ready to Fly</h1>
      <p *ngIf="company$ | async; let company">
        Great! You completed your profile data and are a member of {{company.name}}. Now you can add a drone with
        a license key.
      </p>
    </div>
    <app-progress-bar></app-progress-bar>
    <app-button
      [title]="'ADD FIRST DRONE TO COMPANY'"
      (click)="changeToThirdStep()"
    ></app-button>
  </div>
</ng-container>
<ng-container *ngIf="(step$ | async)===3">
  <div class="header">
    <div><h1>Company Equipment</h1></div>
    <div class="new-drone" (click)="openAddDroneDialog()">+ add new drone</div>
  </div>
  <div class="equipment-container">
    <div class="block">
      <div class="row-3-headline">
        <div class="headline">Company Drones</div>
        <div>Drone S/N</div>
        <div>Details</div>
      </div>
      <span class="break-line"></span>
      <div class="container-content" *ngIf="(equipment$ | async)">
        <div *ngFor="let aircraft of aircrafts$ | async; let i = index">
          <div class="row-3 content">
            <div class="drone-content">
              {{ aircraft.name }}
            </div>
            <div>{{ aircraft.serialNumber }}</div>
            <div>{{ aircraft.description }}</div>
          </div>
          <span
            class="content-line"
            *ngIf="(aircrafts$ | async)?.length??0 - 1 !== i"
          ></span>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="row-3-headline">
        <div class="headline">Company Software</div>
        <div>Version</div>
        <div>Download</div>
      </div>
      <span class="break-line"></span>
      <div class="container-content">
        <div *ngFor="let software of (software$ | async); let i = index">
          <div class="row-3 content">
            <div class="software-entry">
              <div>
                <img class="icon" src="../assets/svg/out.svg" />
              </div>
              <div>
                <b>{{ software.name }}</b>
                <br />
                <span>{{ software.detailedName }}</span>
              </div>
            </div>
            <div>{{ software.version }}</div>
            <div>
              <a href="{{ software.downloadLink }}">
                <img class="icon" src="../assets/svg/download.svg" />
              </a>
            </div>
          </div>
          <span
            class="content-line"
            *ngIf="(software$ | async)?.length??0 - 1 !== i"
          ></span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
