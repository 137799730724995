import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CompanyState } from '../../../stores/store.states';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCompanyData } from '../../../stores/company/company.selector';

export type GroupActionsModalData = {
  action: "delete" | "otherAction...",
  leaving: boolean,
  buttonTitel: string,
  callback: Function
}
@Component({
  selector: 'app-group-actions-modal',
  templateUrl: './group-actions-modal.component.html',
  styleUrls: ['./group-actions-modal.component.scss'],
})
export class GroupActionsModalComponent {
  errorMessage: string = '';
  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA) public data: GroupActionsModalData,
    private store: Store
  ) {
    this.company$ = this.store.select(selectCompanyData);
  }
  company$: Observable<CompanyState>;

  abortModal(): void {
    this.dialogRef.close();
  }

  deleteGroup(companyId?: string): void {
    this.data.callback(companyId);
    this.dialogRef.close();
  }
}
