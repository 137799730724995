import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EquipmentService} from 'src/app/services/equipment.service';
import {ProgressService} from '../../services/progress.service';
import {Store} from '@ngrx/store';
import {first, Observable, Subscription, take, tap} from 'rxjs';
import {LoadingState, ProfileState} from '../../stores/store.states';
import {selectProfileData} from '../../stores/profile/profile.selector';
import {UserRole} from '../../models/user.model';
import {dispatchLoadingProfileWithCompanyAndEquipment} from "../../stores/store.utils";
import {LocalStorageService} from "../../services/local-storage.service";
import {Dialog} from "@angular/cdk/dialog";
import {
  JoinGroupModalComponent,
  JoinGroupModalType
} from "../../components/modals/join-group-modal/join-group-modal.component";
import {JoinCompanyService} from "../../services/join-company.service";
import {filter} from "rxjs/operators";
import {InformationModalComponent} from "../../components/modals/information-modal/information-modal.component";

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit, OnDestroy {
  constructor(
    private equipmentService: EquipmentService,
    private router: Router,
    private progressService: ProgressService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private dialog: Dialog,
    private joinCompanyService: JoinCompanyService
  ) {
    this.profile$ = this.store.select(selectProfileData);
  }
  profileProgress: number = 0;
  profile$: Observable<ProfileState>;
  progressSubscription?: Subscription;

  ngOnInit() {
    this.profile$.pipe(
      first(state => (state.quantumProfileLoadingState === LoadingState.SUCCESS)),
      take(1)
    ).subscribe(profile => {
      if(profile.completed) {
        if(this.localStorageService.getInviteLinkFromLocalStorage()) {
          this.openInviteDialog();
        }
      } else {
        if(this.localStorageService.getInviteLinkFromLocalStorage()) {
          this.openInformationDialogToCompleteProfile();
        }
      }
    })
    dispatchLoadingProfileWithCompanyAndEquipment(this.store);
    this.progressSubscription = this.progressService
      .getProgressAsObservable()
      .subscribe((next) => {
        this.profileProgress = next;
        if (next === 100) {
          this.router.navigate(['/dashboard']);
        }
      });
  }
  openInformationDialogToCompleteProfile() {
    const dialogRef = this.dialog.open(InformationModalComponent, {
      data: {
        headerText: "Profile not Complete",
        bodyText: "Please complete your profile. After that you are going to join the company automatically." +
          " Press cancel if you don't want to join anymore.",
        cancelable: true
      }
    });
    dialogRef.closed.subscribe((result: any) => {
      if(result.cancel) {
        this.localStorageService.removeInviteLinkFromLocalStorage();
        return;
      }
      this.router.navigate(['/dashboard/profile'])
    })
  }
  openInviteDialog() {
    const dialogRef = this.dialog.open<JoinGroupModalType>(JoinGroupModalComponent);
    dialogRef.closed.subscribe((result) => {
      if (!!result && result.isValid) {
        this.joinCompanyService.joinCompany(result.joinLink).subscribe({
            next: (joinResult) => {
              this.localStorageService.removeInviteLinkFromLocalStorage();
              this.router.navigate(['/dashboard/company'])
            },
            error: (error) => {
            }
          }
        );
      } else if (!!result && !result?.isValid) {
      } else {
        this.localStorageService.removeInviteLinkFromLocalStorage();
        this.router.navigate(['/dashboard/company'])
      }
    });
  }

  setStep(number: number) {
    this.equipmentService.setStep(number);
  }

  ngOnDestroy() {
    this.progressSubscription?.unsubscribe();
  }

  protected readonly UserRole = UserRole;

}
