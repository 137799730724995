<div class="group">
  <label>Company</label>
  <input
    [formControl]="companyControl"
    type="text"
    placeholder=""
    class="quantum-input"
    [ngClass]="companyControl.invalid ? 'invalid' : ''"
  />
  <div
    *ngIf="
      companyControl.invalid && companyControl.errors && companyControl.dirty
    "
    [class.dirty]="companyControl.dirty"
    class="error-message"
  >
    <div *ngIf="companyControl.errors['required']">*required</div>
  </div>
</div>
