<div class="modal-container">
  <div class="modal-header">
    <span>Complete Mission</span>
    <div class="modal-header-close-btn" (click)="abortModal()">
      <span class="icon-close"></span>
    </div>
  </div>

  <div class="seperator">
    <hr />
  </div>

  <div class="modal-body">
    <span class="error-message" *ngIf="errorMessage">
      {{ errorMessage }}
    </span>

    <form [formGroup]="form" class="send-email" (ngSubmit)="completeMission()">
      <app-result-url-input
        [controllerName]="'url'"
        label="Result URL"
        placeholder="Enter Result URL"
      ></app-result-url-input>

      <div class="button-bar">
        <app-button
          [medium]="true"
          [title]="'COMPLETE'"
          [disabled]="form.invalid"
        ></app-button>
      </div>
    </form>
  </div>
</div>
