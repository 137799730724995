import * as ProfileActions from './profile.actions';
import {createReducer, on} from '@ngrx/store';
import {LoadingState, ProfileState} from '../store.states';
import {UserRole} from '../../models/user.model';
import {UserService} from '../../services/user.service';

export const initialState: ProfileState = {
  role: UserRole.PILOT,
  id: '',
  companyId: '',
  family_name: '',
  given_name: '',
  avatar: '',
  email: '',
  firstName: '',
  jobTitle: '',
  lastName: '',
  loadingState: LoadingState.FUTURE,
  quantumProfileLoadingState: LoadingState.FUTURE,
  updatedState: LoadingState.FUTURE,
  roleLoadingState: LoadingState.FUTURE,
  activeProfileError: '',
  quantumProfileError: '',
  completed: false,
};
export const profileReducer = createReducer(
  initialState,
  on(ProfileActions.logout, (state) => ({
    ...state,
    companyId: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    avatar: '',
    loadingState: LoadingState.SUCCESS,
  })),
  on(ProfileActions.loadingMsalActiveProfile, (state) => ({
    ...state,
    loadingState: LoadingState.PENDING,
    updatedState: LoadingState.FUTURE,
  })),
  on(
    ProfileActions.loadingMsalActiveProfileSuccess,
    (state, { msalProfile }) => ({
      ...state,
        firstName: (state.firstName?state.firstName:msalProfile.given_name),
        lastName: (state.lastName?state.lastName:msalProfile.family_name),
        given_name: msalProfile.given_name,
        family_name: msalProfile.family_name,
        email: msalProfile.email,
        loadingState: LoadingState.SUCCESS,
    })
  ),
  on(ProfileActions.loadingQuantumProfile, (state) => ({
    ...state,
    loadingState: LoadingState.PENDING,
    updatedState: LoadingState.FUTURE,
    quantumProfileLoadingState: LoadingState.PENDING
  })),
  on(
    ProfileActions.loadingQuantumProfileSuccess,
    (state, { quantumProfile }) => ({
      ...state,
      companyId: quantumProfile.companyId as string,
      firstName: quantumProfile.firstName??state.firstName,
      lastName: quantumProfile.lastName??state.lastName,
      avatar: quantumProfile.avatar,
      jobTitle: quantumProfile.jobTitle,
      id: quantumProfile.id,
      loadingState: LoadingState.SUCCESS,
      completed: UserService.isProfileComplete({
        id: quantumProfile.id,
        avatar: '',
        companyName: '',
        country: '',
        fieldsOfApplication: [],
        firstName: quantumProfile.firstName,
        industry: '',
        jobTitle: quantumProfile.jobTitle,
        lastName: quantumProfile.lastName,
        stateOrProvince: '',
      }),
      quantumProfileLoadingState: LoadingState.SUCCESS
    })
  ),
  on(ProfileActions.loadingMsalActiveProfileError, (state, { error }) => ({
    ...state,
    activeProfileError: error,
    loadingState: LoadingState.ERROR,
    quantumProfileLoadingState: LoadingState.ERROR
  })),
  on(ProfileActions.loadingQuantumProfileError, (state, { error }) => ({
    ...state,
    quantumProfileError: error,
    loadingState: LoadingState.ERROR,
  })),
  on(ProfileActions.editingQuantumProfile, (state) => ({
    ...state,
    updatedState: LoadingState.PENDING,
  })),
  on(
    ProfileActions.editingQuantumProfileSuccess,
    (state, { updatedQuantumProfile }) => ({
      ...state,
      firstName: updatedQuantumProfile.firstName,
      lastName: updatedQuantumProfile.lastName,
      avatar: updatedQuantumProfile.avatar,
      jobTitle: updatedQuantumProfile.jobTitle,
      updatedState: LoadingState.SUCCESS,
    completed: UserService.isProfileComplete({
        id: updatedQuantumProfile.id,
        avatar: '',
        companyName: '',
        country: '',
        fieldsOfApplication: [],
        firstName: updatedQuantumProfile.firstName,
        industry: '',
        jobTitle: updatedQuantumProfile.jobTitle,
        lastName: updatedQuantumProfile.lastName,
        stateOrProvince: '',
    }),
    })
  ),
  on(ProfileActions.editingQuantumProfileError, (state, { error }) => ({
    ...state,
    quantumProfileError: error,
    updatedState: LoadingState.ERROR,
  })),
  on(ProfileActions.loadingProfileRole, (state) => ({
    ...state,
    roleLoadingState: LoadingState.PENDING
  })),
  on(ProfileActions.loadingProfileRoleSuccess, (state, { role }) => ({
    ...state,
    role: role,
    roleLoadingState: LoadingState.SUCCESS
  })),
  on(ProfileActions.loadingProfileRoleError, (state, { error }) => ({
    ...state,
    quantumProfileError: error,
    roleLoadingState: LoadingState.ERROR
  })),
  on(ProfileActions.updatingProfileRole, (state) => ({
    ...state,
    updatedState: LoadingState.PENDING
  })),
  on(ProfileActions.updatingProfileRoleSuccess, (state, { role }) => ({
    ...state,
    updatedState: LoadingState.SUCCESS
  })),
  on(ProfileActions.updatingProfileRoleError, (state, { error }) => ({
    ...state,
    updatedState: LoadingState.ERROR
  }))
);
