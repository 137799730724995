import {Component, Inject, Input} from '@angular/core';
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

export type InformationModalData = {
  headerText: string,
  bodyText: string,
  cancelable?: boolean,
}
@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent {
  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA) public data: InformationModalData,
  ) {
  }

  abortModal(cancel: boolean): void {
    this.dialogRef.close({
      cancel: cancel
    });
  }
}
