import {AfterViewInit, Component, Inject, Input, ViewChild} from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {Clipboard} from "@angular/cdk/clipboard";
import {EmailInputComponent} from "../../inputs/email-input/email-input.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CompanyService} from "../../../services/company.service";
import {Store} from "@ngrx/store";
import {selectCompanyData} from "../../../stores/company/company.selector";
import {take, takeLast} from "rxjs";
import {JoinCompanyService} from "../../../services/join-company.service";
import {DashboardService} from "../../../services/dashboard.service";

@Component({
  selector: 'app-invite-member-modal',
  templateUrl: './invite-member-modal.component.html',
  styleUrls: ['./invite-member-modal.component.scss'],
})
export class InviteMemberModalComponent {
  @Input() open: boolean = false;
  errorMessage: string = '';
  isCopied: boolean = false;
  link: string = window.location.host + '/join/';
  form: FormGroup = this.formBuilder.nonNullable.group({
    email: EmailInputComponent.emailControl()
  })

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA) public data: any,
    private clipboard: Clipboard,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private store: Store,
    private joinCompanyService: JoinCompanyService,
    private dashboardService: DashboardService
  ) {
    this.store.select(selectCompanyData).pipe(take(1)).subscribe(
      company => {
        if(company.id) {
          this.companyService.getInviteLinkAsBase64(company.id).subscribe(inviteLink => {
            this.link += this.joinCompanyService.makeUrlFriendlyBase64Text(inviteLink);
            this.link += "?name=" + encodeURI(company.name)
          })
        }
      }
    )
  }

  abortModal(): void {
    this.dialogRef.close();
  }

  finishModal(): void {
    this.dialogRef.close({});
  }

  sendInvitation() {
    this.store.select(selectCompanyData).pipe(take(1)).subscribe(company => {
      if(!company.id) return;
      this.companyService.sendInviteViaEmail(company.id, this.form.value.email).subscribe(
        {
          next: () => {
            this.dashboardService.openSuccessResponseBanner("Email was successfully sent")
          },
          error: () => {
            this.dashboardService.openErrorResponseBanner("Something went wrong, try again")
          },
          complete: () => {
            this.finishModal();
          }
        }
      )
    })
  }

  copyLink() {
    this.clipboard.copy(this.link);
    this.showIsCopiedBanner(7500);
  }

  showIsCopiedBanner(time: number) {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, time)
  }
}
