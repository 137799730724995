import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class JoinCompanyService {

  constructor(private http: HttpClient) {}

  joinCompany(joinLink: string) {
    return this.http.get(joinLink)
  }
  reversURLFriendlyBase64Text(text: string) {
    if (text.length % 4 != 0){
      text += ('===').slice(0, 4 - (text.length % 4));
    }
    return text.replace(/-/g, '+').replace(/_/g, '/');
  }

  makeUrlFriendlyBase64Text(base64text: string) {
    return base64text
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }
}
