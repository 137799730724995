import {Component, ViewChild} from '@angular/core';
import {countryBase, fieldsApplicationOptionsBase} from '../../../utils/data';
import {OptionsCountry} from '../profile-form/profile-form.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {merge, Observable, take, tap} from 'rxjs';
import {CompanyState, LoadingState} from '../../stores/store.states';
import {selectCompanyData} from '../../stores/company/company.selector';
import {
  ApplicationsOptionsInputComponent
} from '../inputs/applications-options-input/applications-options-input.component';
import {CompanyInputComponent} from '../inputs/company-input/company-input.component';
import {CountryOptionsInputComponent} from '../inputs/country-options-input/country-options-input.component';
import {IndustryOptionsInputComponent} from '../inputs/industry-options-input/industry-options-input.component';
import {StateOrProvinceInputComponent} from '../inputs/state-or-province-input/state-or-province-input.component';
import {PostalcodeInputComponent} from '../inputs/postalcode-input/postalcode-input.component';
import * as CompanyActions from '../../stores/company/company.actions';
import {deletingCompany} from '../../stores/company/company.actions';
import {CompanyType} from '../../models/company.model';
import {filter} from 'rxjs/operators';
import {dispatchLoadingCompanyTimeLocked} from '../../stores/store.utils';
import {DashboardService} from '../../services/dashboard.service';
import {
  GroupActionsModalComponent,
  GroupActionsModalData
} from '../modals/group-actions-modal/group-actions-modal.component';
import {Dialog} from '@angular/cdk/dialog';

@Component({
  selector: 'app-company-group-form',
  templateUrl: './company-group-form.component.html',
  styleUrls: ['./company-group-form.component.scss'],
})
export class CompanyGroupFormComponent {
  countryOptions: OptionsCountry[] = countryBase;
  fieldsApplicationOptions = fieldsApplicationOptionsBase;
  applicationOptions: string[] = [];
  form: FormGroup;
  successfulEdit: boolean = false;
  showResponseBanner: boolean = false;
  errorMessageResponseBanner?: string;
  successMessageResponseBanner?: string;
  showCardToCreatCompanyGroup: boolean = false;
  showCompanyEdit: boolean = false;
  company$: Observable<CompanyState>;
  companyCompleted: boolean = false;
  companyId: string = '';
  avatar?: string;
  @ViewChild(ApplicationsOptionsInputComponent)
  applicationsOptionsInputComponent!: ApplicationsOptionsInputComponent;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private dashboardService: DashboardService,
    private dialog: Dialog
  ) {
    dispatchLoadingCompanyTimeLocked(store);
    this.company$ = this.store.select(selectCompanyData);
    this.company$.subscribe((company) => {
      if (!company.name) {
        this.showOnlyCardToCreateCompanyGroup();
      } else {
        this.showOnlyCompanyEdit();
      }
      this.avatar = company.avatar;
      this.companyCompleted = company.completed;
      this.companyId = company.id as string;
    });

    this.form = this.formBuilder.nonNullable.group({
      company: CompanyInputComponent.companyInputFormControl(),
      country: CountryOptionsInputComponent.countryOptionsGroup(),
      industry: IndustryOptionsInputComponent.industryOptionsGroup(),
      application: ApplicationsOptionsInputComponent.applicationOptionsGroup(),
      stateOrProvince: StateOrProvinceInputComponent.stateOrProvinceControl(),
      postalcode: PostalcodeInputComponent.postalcodeControl(),
    });
  }

  showOnlyCompanyEdit() {
    this.showCardToCreatCompanyGroup = false;
    this.showCompanyEdit = true;
  }

  showOnlyCardToCreateCompanyGroup() {
    this.showCardToCreatCompanyGroup = true;
    this.showCompanyEdit = false;
  }

  getAvatar(image: string) {
    return (this.avatar = image);
  }

  update() {
    this.markAllFieldsAsDirty();
    const company = this.getValuesFromForm();
    if (this.form.valid && !this.companyCompleted) {
      this.createCompany(company);
    } else if (this.form.valid && this.companyCompleted) {
      this.editCompany(company);
    } else {
      this.showMissingFieldsResponseBanner();
    }
  }

  markAllFieldsAsDirty() {
    Object.keys(this.form.controls).forEach((control) =>
      this.form.get(control)?.markAsDirty()
    );
  }

  createCompany(company: CompanyType) {
    this.store.dispatch(
      CompanyActions.createCompany({
        company: {
          ...company
        },
        avatar: this.avatar as string
      })
    );
    this.dashboardService.openResponseBannerOnResponse(
    'The group was successfully created',
    'Something went wrong',
      this.company$,
      "creatingState"
    );
  }

  editCompany(newCompany: CompanyType) {
    const avatar = this.avatar ?? '';
    if (!this.companyId) return;
    this.company$.pipe(take(1)).subscribe((oldCompany) => {
      this.store.dispatch(
        CompanyActions.editCompany({
          company: {
            ...newCompany,
          },
        })
      );
      if (avatar && avatar !== oldCompany.avatar)
        this.store.dispatch(
          CompanyActions.updateCompanyAvatar({
            companyId: this.companyId,
            avatar: avatar,
          })
        );
    });
    merge(
      this.company$.pipe(
        filter((company) => company.updateState === LoadingState.SUCCESS),
        take(1),
        tap(() => {
          this.showSuccessResponseBanner();
        })
      ),
      this.company$.pipe(
        filter(
          (company) =>
            company.updateState === LoadingState.ERROR ||
            company.updateAvatarState === LoadingState.ERROR
        ),
        take(1),
        tap(() => {
          this.showErrorResponseBanner();
        })
      )
    ).subscribe();
  }

  showMissingFieldsResponseBanner() {
    this.showResponseBanner = true;
    this.errorMessageResponseBanner = 'Please fill out all required fields';
    this.successfulEdit = false;
  }

  showSuccessResponseBanner() {
    this.showResponseBanner = true;
    this.successMessageResponseBanner = 'Company is successfully updated';
    this.successfulEdit = true;
  }
  showErrorResponseBanner() {
    this.showResponseBanner = true;
    this.errorMessageResponseBanner = 'Something went wrong';
    this.successfulEdit = false;
  }

  getValuesFromForm(): CompanyType {
    return {
      name: this.form.value.company,
      country: this.form.value.country.country,
      stateOrProvince: this.form.value.stateOrProvince,
      postalCode: this.form.value.postalcode,
      industry: this.form.value.industry.industry,
      fieldsOfApplication: this.applicationsOptionsInputComponent.getValues(),
      id: this.companyId,
    };
  }

  changeOptions($event: string[]) {
    this.applicationOptions = $event;
  }

  openActionDialog() {
    const dialogRef = this.dialog.open<GroupActionsModalData>(GroupActionsModalComponent, {
      data: {
        action: 'delete',
        leaving: false,
        buttonTitel: 'DELETE',
        callback: (companyId: string) => {
          this.store.dispatch(
            deletingCompany({
              companyId: companyId as string,
            })
          );
          this.dashboardService.openResponseBannerOnResponse(
            "The group was successfully deleted",
            "Something went wrong",
            this.company$,
            "updateState"
          );
        },
      },
    });
  }
}
