import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EquipmentService} from "../../services/equipment.service";
import * as EquipmentActions from "./equipment.actions";
import {concatMap, exhaustMap, of, withLatestFrom} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class EquipmentEffects {
  loadingEquipment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EquipmentActions.loadingEquipment),
        exhaustMap(({companyId}) => this.equipmentService.getEquipments(companyId).pipe(
          map(equipments => EquipmentActions.loadingEquipmentSuccess({equipment: equipments})),
          catchError(error => of(EquipmentActions.loadingEquipmentError({error : error})))
        ))
      )
  )

  newAircraft$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EquipmentActions.activatingAircraftSuccess),
        map(({companyId}) => EquipmentActions.loadingEquipment({
          companyId: companyId
        }))
      )
  )

  activateNewAircraft$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EquipmentActions.activatingAircraft),
        exhaustMap(({serial, companyId}) => this.equipmentService.activateEquipment(serial, companyId).pipe(
          map(() => EquipmentActions.activatingAircraftSuccess({
            companyId: companyId
          })),
          catchError(error => of(EquipmentActions.activatingAircraftError({error: error})))
        ))
      )
  )

  reloadEquipment$ = createEffect(
    () => this.actions$.pipe(
      ofType(EquipmentActions.activatingAircraftSuccess),
      concatMap((comanyId) => [
        EquipmentActions.loadingEquipment(comanyId)
      ])
    )
  )

  constructor(
    private actions$: Actions,
    private equipmentService: EquipmentService
  ) {
  }
}
