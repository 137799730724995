export const fieldsApplicationOptionsBase: string[] = [
  'Surveying',
  'Mining and Aggregates',
  'Construction and Infrastructure',
  'Agriculture',
  'Environmental Monitoring',
  'Forestry',
  'Research and Education',
  'Humanitarian Disaster Management',
  'RescueServices',
];

export const surveyingOptionsBase: string[] = [
  'Land Surveying',
  'Cadastral Maps',
  'Topographical Surveys',
  'Precise Measurements',
  'Land Management',
  'Urban Planning',
];

export const miningAndAggregatesOptionsBase: string[] = [
  'Inventory and Stockpile Volume Management',
  'MineOrQuarry Monitoring and Operation Planning',
  'Assessment Before Drilling Or Blasting',
  'Road Monitoring',
  'Slope Monitoring',
  'Hazard Identification',
];

export const constructionAndInfrastructureOptionsBase: string[] = [
  'Volume Measurements',
  '3D Models of Sites',
  'Tracking Construction Progress',
  'Pipeline Inspection Monitoring',
  'Railway Inspection',
  'Solar Farm',
  'Road',
];

export const agricultureOptionsBase: string[] = [
  'Monitoring Plant Health',
  'Perform PlantCount',
  'Yield Optimization',
  'Assess Losses after major weather events',
  'Smart Farming',
];

export const environmentalMonitoringOptionsBase: string[] = [
  'Population Monitoring and Control',
  'Area Classification',
  'Volumes is land restoration',
  'Monitor Land Changes',
];

export const forestryOptionsBase: string[] = ['Managed', 'Natural'];

export const countryBase2: any[] = [
  { code: 'BD', title: 'Bangladesh' },
  { code: 'BE', title: 'Belgium' },
  { code: 'BF', title: 'Burkina Faso' },
  { code: 'BG', title: 'Bulgaria' },
  { code: 'BA', title: 'Bosnia and Herzegovina' },
  { code: 'BB', title: 'Barbados' },
  { code: 'WF', title: 'Wallis and Futuna' },
  { code: 'BL', title: 'Saint Barthelemy' },
  { code: 'BM', title: 'Bermuda' },
  { code: 'BN', title: 'Brunei' },
  { code: 'BO', title: 'Bolivia' },
  { code: 'BH', title: 'Bahrain' },
  { code: 'BI', title: 'Burundi' },
  { code: 'BJ', title: 'Benin' },
  { code: 'BT', title: 'Bhutan' },
  { code: 'JM', title: 'Jamaica' },
  { code: 'BV', title: 'Bouvet Island' },
  { code: 'BW', title: 'Botswana' },
  { code: 'WS', title: 'Samoa' },
  { code: 'BQ', title: 'Bonaire, Saint Eustatius and Saba' },
  { code: 'BR', title: 'Brazil' },
  { code: 'BS', title: 'Bahamas' },
  { code: 'JE', title: 'Jersey' },
  { code: 'BY', title: 'Belarus' },
  { code: 'BZ', title: 'Belize' },
  { code: 'RU', title: 'Russia' },
  { code: 'RW', title: 'Rwanda' },
  { code: 'RS', title: 'Serbia' },
  { code: 'TL', title: 'East Timor' },
  { code: 'RE', title: 'Reunion' },
  { code: 'TM', title: 'Turkmenistan' },
  { code: 'TJ', title: 'Tajikistan' },
  { code: 'RO', title: 'Romania' },
  { code: 'TK', title: 'Tokelau' },
  { code: 'GW', title: 'Guinea-Bissau' },
  { code: 'GU', title: 'Guam' },
  { code: 'GT', title: 'Guatemala' },
  { code: 'GS', title: 'South Georgia and the South Sandwich Islands' },
  { code: 'GR', title: 'Greece' },
  { code: 'GQ', title: 'Equatorial Guinea' },
  { code: 'GP', title: 'Guadeloupe' },
  { code: 'JP', title: 'Japan' },
  { code: 'GY', title: 'Guyana' },
  { code: 'GG', title: 'Guernsey' },
  { code: 'GF', title: 'French Guiana' },
  { code: 'GE', title: 'Georgia' },
  { code: 'GD', title: 'Grenada' },
  { code: 'GB', title: 'United Kingdom' },
  { code: 'GA', title: 'Gabon' },
  { code: 'SV', title: 'El Salvador' },
  { code: 'GN', title: 'Guinea' },
  { code: 'GM', title: 'Gambia' },
  { code: 'GL', title: 'Greenland' },
  { code: 'GI', title: 'Gibraltar' },
  { code: 'GH', title: 'Ghana' },
  { code: 'OM', title: 'Oman' },
  { code: 'TN', title: 'Tunisia' },
  { code: 'JO', title: 'Jordan' },
  { code: 'HR', title: 'Croatia' },
  { code: 'HT', title: 'Haiti' },
  { code: 'HU', title: 'Hungary' },
  { code: 'HK', title: 'Hong Kong' },
  { code: 'HN', title: 'Honduras' },
  { code: 'HM', title: 'Heard Island and McDonald Islands' },
  { code: 'VE', title: 'Venezuela' },
  { code: 'PR', title: 'Puerto Rico' },
  { code: 'PS', title: 'Palestinian Territory' },
  { code: 'PW', title: 'Palau' },
  { code: 'PT', title: 'Portugal' },
  { code: 'SJ', title: 'Svalbard and Jan Mayen' },
  { code: 'PY', title: 'Paraguay' },
  { code: 'IQ', title: 'Iraq' },
  { code: 'PA', title: 'Panama' },
  { code: 'PF', title: 'French Polynesia' },
  { code: 'PG', title: 'Papua New Guinea' },
  { code: 'PE', title: 'Peru' },
  { code: 'PK', title: 'Pakistan' },
  { code: 'PH', title: 'Philippines' },
  { code: 'PN', title: 'Pitcairn' },
  { code: 'PL', title: 'Poland' },
  { code: 'PM', title: 'Saint Pierre and Miquelon' },
  { code: 'ZM', title: 'Zambia' },
  { code: 'EH', title: 'Western Sahara' },
  { code: 'EE', title: 'Estonia' },
  { code: 'EG', title: 'Egypt' },
  { code: 'ZA', title: 'South Africa' },
  { code: 'EC', title: 'Ecuador' },
  { code: 'IT', title: 'Italy' },
  { code: 'VN', title: 'Vietnam' },
  { code: 'SB', title: 'Solomon Islands' },
  { code: 'ET', title: 'Ethiopia' },
  { code: 'SO', title: 'Somalia' },
  { code: 'ZW', title: 'Zimbabwe' },
  { code: 'SA', title: 'Saudi Arabia' },
  { code: 'ES', title: 'Spain' },
  { code: 'ER', title: 'Eritrea' },
  { code: 'ME', title: 'Montenegro' },
  { code: 'MD', title: 'Moldova' },
  { code: 'MG', title: 'Madagascar' },
  { code: 'MF', title: 'Saint Martin' },
  { code: 'MA', title: 'Morocco' },
  { code: 'MC', title: 'Monaco' },
  { code: 'UZ', title: 'Uzbekistan' },
  { code: 'MM', title: 'Myanmar' },
  { code: 'ML', title: 'Mali' },
  { code: 'MO', title: 'Macao' },
  { code: 'MN', title: 'Mongolia' },
  { code: 'MH', title: 'Marshall Islands' },
  { code: 'MK', title: 'Macedonia' },
  { code: 'MU', title: 'Mauritius' },
  { code: 'MT', title: 'Malta' },
  { code: 'MW', title: 'Malawi' },
  { code: 'MV', title: 'Maldives' },
  { code: 'MQ', title: 'Martinique' },
  { code: 'MP', title: 'Northern Mariana Islands' },
  { code: 'MS', title: 'Montserrat' },
  { code: 'MR', title: 'Mauritania' },
  { code: 'IM', title: 'Isle of Man' },
  { code: 'UG', title: 'Uganda' },
  { code: 'TZ', title: 'Tanzania' },
  { code: 'MY', title: 'Malaysia' },
  { code: 'MX', title: 'Mexico' },
  { code: 'IL', title: 'Israel' },
  { code: 'FR', title: 'France' },
  { code: 'IO', title: 'British Indian Ocean Territory' },
  { code: 'SH', title: 'Saint Helena' },
  { code: 'FI', title: 'Finland' },
  { code: 'FJ', title: 'Fiji' },
  { code: 'FK', title: 'Falkland Islands' },
  { code: 'FM', title: 'Micronesia' },
  { code: 'FO', title: 'Faroe Islands' },
  { code: 'NI', title: 'Nicaragua' },
  { code: 'NL', title: 'Netherlands' },
  { code: 'NO', title: 'Norway' },
  { code: 'NA', title: 'Namibia' },
  { code: 'VU', title: 'Vanuatu' },
  { code: 'NC', title: 'New Caledonia' },
  { code: 'NE', title: 'Niger' },
  { code: 'NF', title: 'Norfolk Island' },
  { code: 'NG', title: 'Nigeria' },
  { code: 'NZ', title: 'New Zealand' },
  { code: 'NP', title: 'Nepal' },
  { code: 'NR', title: 'Nauru' },
  { code: 'NU', title: 'Niue' },
  { code: 'CK', title: 'Cook Islands' },
  { code: 'XK', title: 'Kosovo' },
  { code: 'CI', title: 'Ivory Coast' },
  { code: 'CH', title: 'Switzerland' },
  { code: 'CO', title: 'Colombia' },
  { code: 'CN', title: 'China' },
  { code: 'CM', title: 'Cameroon' },
  { code: 'CL', title: 'Chile' },
  { code: 'CC', title: 'Cocos Islands' },
  { code: 'CA', title: 'Canada' },
  { code: 'CG', title: 'Republic of the Congo' },
  { code: 'CF', title: 'Central African Republic' },
  { code: 'CD', title: 'Democratic Republic of the Congo' },
  { code: 'CZ', title: 'Czech Republic' },
  { code: 'CY', title: 'Cyprus' },
  { code: 'CX', title: 'Christmas Island' },
  { code: 'CR', title: 'Costa Rica' },
  { code: 'CW', title: 'Curacao' },
  { code: 'CV', title: 'Cape Verde' },
  { code: 'CU', title: 'Cuba' },
  { code: 'SZ', title: 'Swaziland' },
  { code: 'SY', title: 'Syria' },
  { code: 'SX', title: 'Sint Maarten' },
  { code: 'KG', title: 'Kyrgyzstan' },
  { code: 'KE', title: 'Kenya' },
  { code: 'SS', title: 'South Sudan' },
  { code: 'SR', title: 'Suriname' },
  { code: 'KI', title: 'Kiribati' },
  { code: 'KH', title: 'Cambodia' },
  { code: 'KN', title: 'Saint Kitts and Nevis' },
  { code: 'KM', title: 'Comoros' },
  { code: 'ST', title: 'Sao Tome and Principe' },
  { code: 'SK', title: 'Slovakia' },
  { code: 'KR', title: 'South Korea' },
  { code: 'SI', title: 'Slovenia' },
  { code: 'KP', title: 'North Korea' },
  { code: 'KW', title: 'Kuwait' },
  { code: 'SN', title: 'Senegal' },
  { code: 'SM', title: 'San Marino' },
  { code: 'SL', title: 'Sierra Leone' },
  { code: 'SC', title: 'Seychelles' },
  { code: 'KZ', title: 'Kazakhstan' },
  { code: 'KY', title: 'Cayman Islands' },
  { code: 'SG', title: 'Singapore' },
  { code: 'SE', title: 'Sweden' },
  { code: 'SD', title: 'Sudan' },
  { code: 'DO', title: 'Dominican Republic' },
  { code: 'DM', title: 'Dominica' },
  { code: 'DJ', title: 'Djibouti' },
  { code: 'DK', title: 'Denmark' },
  { code: 'VG', title: 'British Virgin Islands' },
  { code: 'DE', title: 'Germany' },
  { code: 'YE', title: 'Yemen' },
  { code: 'DZ', title: 'Algeria' },
  { code: 'US', title: 'United States' },
  { code: 'UY', title: 'Uruguay' },
  { code: 'YT', title: 'Mayotte' },
  { code: 'UM', title: 'United States Minor Outlying Islands' },
  { code: 'LB', title: 'Lebanon' },
  { code: 'LC', title: 'Saint Lucia' },
  { code: 'LA', title: 'Laos' },
  { code: 'TV', title: 'Tuvalu' },
  { code: 'TW', title: 'Taiwan' },
  { code: 'TT', title: 'Trinidad and Tobago' },
  { code: 'TR', title: 'Turkey' },
  { code: 'LK', title: 'Sri Lanka' },
  { code: 'LI', title: 'Liechtenstein' },
  { code: 'LV', title: 'Latvia' },
  { code: 'TO', title: 'Tonga' },
  { code: 'LT', title: 'Lithuania' },
  { code: 'LU', title: 'Luxembourg' },
  { code: 'LR', title: 'Liberia' },
  { code: 'LS', title: 'Lesotho' },
  { code: 'TH', title: 'Thailand' },
  { code: 'TF', title: 'French Southern Territories' },
  { code: 'TG', title: 'Togo' },
  { code: 'TD', title: 'Chad' },
  { code: 'TC', title: 'Turks and Caicos Islands' },
  { code: 'LY', title: 'Libya' },
  { code: 'VA', title: 'Vatican' },
  { code: 'VC', title: 'Saint Vincent and the Grenadines' },
  { code: 'AE', title: 'United Arab Emirates' },
  { code: 'AD', title: 'Andorra' },
  { code: 'AG', title: 'Antigua and Barbuda' },
  { code: 'AF', title: 'Afghanistan' },
  { code: 'AI', title: 'Anguilla' },
  { code: 'VI', title: 'U.S. Virgin Islands' },
  { code: 'IS', title: 'Iceland' },
  { code: 'IR', title: 'Iran' },
  { code: 'AM', title: 'Armenia' },
  { code: 'AL', title: 'Albania' },
  { code: 'AO', title: 'Angola' },
  { code: 'AQ', title: 'Antarctica' },
  { code: 'AS', title: 'American Samoa' },
  { code: 'AR', title: 'Argentina' },
  { code: 'AU', title: 'Australia' },
  { code: 'AT', title: 'Austria' },
  { code: 'AW', title: 'Aruba' },
  { code: 'IN', title: 'India' },
  { code: 'AX', title: 'Aland Islands' },
  { code: 'AZ', title: 'Azerbaijan' },
  { code: 'IE', title: 'Ireland' },
  { code: 'ID', title: 'Indonesia' },
  { code: 'UA', title: 'Ukraine' },
  { code: 'QA', title: 'Qatar' },
  { code: 'MZ', title: 'Mozambique' },
];

export const checkboxOptions: any[] = [
  {
    id: 1,
    title: 'Drone Service Provider',
    checked: true,
  },
  {
    id: 2,
    title: 'Mining, Quarriers & Aggregates',
    checked: false,
  },
  {
    id: 3,
    title: 'Engineering & Construction',
    checked: true,
  },
  {
    id: 4,
    title: 'Energy & Utilities',
    checked: false,
  },
  {
    id: 5,
    title: 'Mapping & GIS',
    checked: true,
  },
  {
    id: 6,
    title: 'Environmental',
    checked: false,
  },
  {
    id: 7,
    title: 'Aggriculture',
    checked: true,
  },
  {
    id: 8,
    title: 'Education',
    checked: false,
  },
  {
    id: 9,
    title: 'Goverment',
    checked: false,
  },
  {
    id: 10,
    title: 'Surveillance',
    checked: true,
  },
  {
    id: 11,
    title: 'Others',
    checked: false,
  },
];

export const countryBase: any[] = [
  {
    code: 'AF',
    title: 'Afghanistan',
  },
  {
    code: 'AX',
    title: 'Aland Islands',
  },
  {
    code: 'AL',
    title: 'Albania',
  },
  {
    code: 'DZ',
    title: 'Algeria',
  },
  {
    code: 'AS',
    title: 'American Samoa',
  },
  {
    code: 'AD',
    title: 'Andorra',
  },
  {
    code: 'AO',
    title: 'Angola',
  },
  {
    code: 'AI',
    title: 'Anguilla',
  },
  {
    code: 'AQ',
    title: 'Antarctica',
  },
  {
    code: 'AG',
    title: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    title: 'Argentina',
  },
  {
    code: 'AM',
    title: 'Armenia',
  },
  {
    code: 'AW',
    title: 'Aruba',
  },
  {
    code: 'AU',
    title: 'Australia',
  },
  {
    code: 'AT',
    title: 'Austria',
  },
  {
    code: 'AZ',
    title: 'Azerbaijan',
  },
  {
    code: 'BS',
    title: 'Bahamas',
  },
  {
    code: 'BH',
    title: 'Bahrain',
  },
  {
    code: 'BD',
    title: 'Bangladesh',
  },
  {
    code: 'BB',
    title: 'Barbados',
  },
  {
    code: 'BY',
    title: 'Belarus',
  },
  {
    code: 'BE',
    title: 'Belgium',
  },
  {
    code: 'BZ',
    title: 'Belize',
  },
  {
    code: 'BJ',
    title: 'Benin',
  },
  {
    code: 'BM',
    title: 'Bermuda',
  },
  {
    code: 'BT',
    title: 'Bhutan',
  },
  {
    code: 'BO',
    title: 'Bolivia',
  },
  {
    code: 'BQ',
    title: 'Bonaire, Saint Eustatius and Saba',
  },
  {
    code: 'BA',
    title: 'Bosnia and Herzegovina',
  },
  {
    code: 'BW',
    title: 'Botswana',
  },
  {
    code: 'BV',
    title: 'Bouvet Island',
  },
  {
    code: 'BR',
    title: 'Brazil',
  },
  {
    code: 'IO',
    title: 'British Indian Ocean Territory',
  },
  {
    code: 'VG',
    title: 'British Virgin Islands',
  },
  {
    code: 'BN',
    title: 'Brunei',
  },
  {
    code: 'BG',
    title: 'Bulgaria',
  },
  {
    code: 'BF',
    title: 'Burkina Faso',
  },
  {
    code: 'BI',
    title: 'Burundi',
  },
  {
    code: 'KH',
    title: 'Cambodia',
  },
  {
    code: 'CM',
    title: 'Cameroon',
  },
  {
    code: 'CA',
    title: 'Canada',
  },
  {
    code: 'CV',
    title: 'Cape Verde',
  },
  {
    code: 'KY',
    title: 'Cayman Islands',
  },
  {
    code: 'CF',
    title: 'Central African Republic',
  },
  {
    code: 'TD',
    title: 'Chad',
  },
  {
    code: 'CL',
    title: 'Chile',
  },
  {
    code: 'CN',
    title: 'China',
  },
  {
    code: 'CX',
    title: 'Christmas Island',
  },
  {
    code: 'CC',
    title: 'Cocos Islands',
  },
  {
    code: 'CO',
    title: 'Colombia',
  },
  {
    code: 'KM',
    title: 'Comoros',
  },
  {
    code: 'CK',
    title: 'Cook Islands',
  },
  {
    code: 'CR',
    title: 'Costa Rica',
  },
  {
    code: 'HR',
    title: 'Croatia',
  },
  {
    code: 'CU',
    title: 'Cuba',
  },
  {
    code: 'CW',
    title: 'Curacao',
  },
  {
    code: 'CY',
    title: 'Cyprus',
  },
  {
    code: 'CZ',
    title: 'Czech Republic',
  },
  {
    code: 'CD',
    title: 'Democratic Republic of the Congo',
  },
  {
    code: 'DK',
    title: 'Denmark',
  },
  {
    code: 'DJ',
    title: 'Djibouti',
  },
  {
    code: 'DM',
    title: 'Dominica',
  },
  {
    code: 'DO',
    title: 'Dominican Republic',
  },
  {
    code: 'TL',
    title: 'East Timor',
  },
  {
    code: 'EC',
    title: 'Ecuador',
  },
  {
    code: 'EG',
    title: 'Egypt',
  },
  {
    code: 'SV',
    title: 'El Salvador',
  },
  {
    code: 'GQ',
    title: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    title: 'Eritrea',
  },
  {
    code: 'EE',
    title: 'Estonia',
  },
  {
    code: 'ET',
    title: 'Ethiopia',
  },
  {
    code: 'FK',
    title: 'Falkland Islands',
  },
  {
    code: 'FO',
    title: 'Faroe Islands',
  },
  {
    code: 'FJ',
    title: 'Fiji',
  },
  {
    code: 'FI',
    title: 'Finland',
  },
  {
    code: 'FR',
    title: 'France',
  },
  {
    code: 'GF',
    title: 'French Guiana',
  },
  {
    code: 'PF',
    title: 'French Polynesia',
  },
  {
    code: 'TF',
    title: 'French Southern Territories',
  },
  {
    code: 'GA',
    title: 'Gabon',
  },
  {
    code: 'GM',
    title: 'Gambia',
  },
  {
    code: 'GE',
    title: 'Georgia',
  },
  {
    code: 'DE',
    title: 'Germany',
  },
  {
    code: 'GH',
    title: 'Ghana',
  },
  {
    code: 'GI',
    title: 'Gibraltar',
  },
  {
    code: 'GR',
    title: 'Greece',
  },
  {
    code: 'GL',
    title: 'Greenland',
  },
  {
    code: 'GD',
    title: 'Grenada',
  },
  {
    code: 'GP',
    title: 'Guadeloupe',
  },
  {
    code: 'GU',
    title: 'Guam',
  },
  {
    code: 'GT',
    title: 'Guatemala',
  },
  {
    code: 'GG',
    title: 'Guernsey',
  },
  {
    code: 'GN',
    title: 'Guinea',
  },
  {
    code: 'GW',
    title: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    title: 'Guyana',
  },
  {
    code: 'HT',
    title: 'Haiti',
  },
  {
    code: 'HM',
    title: 'Heard Island and McDonald Islands',
  },
  {
    code: 'HN',
    title: 'Honduras',
  },
  {
    code: 'HK',
    title: 'Hong Kong',
  },
  {
    code: 'HU',
    title: 'Hungary',
  },
  {
    code: 'IS',
    title: 'Iceland',
  },
  {
    code: 'IN',
    title: 'India',
  },
  {
    code: 'ID',
    title: 'Indonesia',
  },
  {
    code: 'IR',
    title: 'Iran',
  },
  {
    code: 'IQ',
    title: 'Iraq',
  },
  {
    code: 'IE',
    title: 'Ireland',
  },
  {
    code: 'IM',
    title: 'Isle of Man',
  },
  {
    code: 'IL',
    title: 'Israel',
  },
  {
    code: 'IT',
    title: 'Italy',
  },
  {
    code: 'CI',
    title: 'Ivory Coast',
  },
  {
    code: 'JM',
    title: 'Jamaica',
  },
  {
    code: 'JP',
    title: 'Japan',
  },
  {
    code: 'JE',
    title: 'Jersey',
  },
  {
    code: 'JO',
    title: 'Jordan',
  },
  {
    code: 'KZ',
    title: 'Kazakhstan',
  },
  {
    code: 'KE',
    title: 'Kenya',
  },
  {
    code: 'KI',
    title: 'Kiribati',
  },
  {
    code: 'XK',
    title: 'Kosovo',
  },
  {
    code: 'KW',
    title: 'Kuwait',
  },
  {
    code: 'KG',
    title: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    title: 'Laos',
  },
  {
    code: 'LV',
    title: 'Latvia',
  },
  {
    code: 'LB',
    title: 'Lebanon',
  },
  {
    code: 'LS',
    title: 'Lesotho',
  },
  {
    code: 'LR',
    title: 'Liberia',
  },
  {
    code: 'LY',
    title: 'Libya',
  },
  {
    code: 'LI',
    title: 'Liechtenstein',
  },
  {
    code: 'LT',
    title: 'Lithuania',
  },
  {
    code: 'LU',
    title: 'Luxembourg',
  },
  {
    code: 'MO',
    title: 'Macao',
  },
  {
    code: 'MK',
    title: 'Macedonia',
  },
  {
    code: 'MG',
    title: 'Madagascar',
  },
  {
    code: 'MW',
    title: 'Malawi',
  },
  {
    code: 'MY',
    title: 'Malaysia',
  },
  {
    code: 'MV',
    title: 'Maldives',
  },
  {
    code: 'ML',
    title: 'Mali',
  },
  {
    code: 'MT',
    title: 'Malta',
  },
  {
    code: 'MH',
    title: 'Marshall Islands',
  },
  {
    code: 'MQ',
    title: 'Martinique',
  },
  {
    code: 'MR',
    title: 'Mauritania',
  },
  {
    code: 'MU',
    title: 'Mauritius',
  },
  {
    code: 'YT',
    title: 'Mayotte',
  },
  {
    code: 'MX',
    title: 'Mexico',
  },
  {
    code: 'FM',
    title: 'Micronesia',
  },
  {
    code: 'MD',
    title: 'Moldova',
  },
  {
    code: 'MC',
    title: 'Monaco',
  },
  {
    code: 'MN',
    title: 'Mongolia',
  },
  {
    code: 'ME',
    title: 'Montenegro',
  },
  {
    code: 'MS',
    title: 'Montserrat',
  },
  {
    code: 'MA',
    title: 'Morocco',
  },
  {
    code: 'MZ',
    title: 'Mozambique',
  },
  {
    code: 'MM',
    title: 'Myanmar',
  },
  {
    code: 'NA',
    title: 'Namibia',
  },
  {
    code: 'NR',
    title: 'Nauru',
  },
  {
    code: 'NP',
    title: 'Nepal',
  },
  {
    code: 'NL',
    title: 'Netherlands',
  },
  {
    code: 'NC',
    title: 'New Caledonia',
  },
  {
    code: 'NZ',
    title: 'New Zealand',
  },
  {
    code: 'NI',
    title: 'Nicaragua',
  },
  {
    code: 'NE',
    title: 'Niger',
  },
  {
    code: 'NG',
    title: 'Nigeria',
  },
  {
    code: 'NU',
    title: 'Niue',
  },
  {
    code: 'NF',
    title: 'Norfolk Island',
  },
  {
    code: 'KP',
    title: 'North Korea',
  },
  {
    code: 'MP',
    title: 'Northern Mariana Islands',
  },
  {
    code: 'NO',
    title: 'Norway',
  },
  {
    code: 'OM',
    title: 'Oman',
  },
  {
    code: 'PK',
    title: 'Pakistan',
  },
  {
    code: 'PW',
    title: 'Palau',
  },
  {
    code: 'PS',
    title: 'Palestinian Territory',
  },
  {
    code: 'PA',
    title: 'Panama',
  },
  {
    code: 'PG',
    title: 'Papua New Guinea',
  },
  {
    code: 'PY',
    title: 'Paraguay',
  },
  {
    code: 'PE',
    title: 'Peru',
  },
  {
    code: 'PH',
    title: 'Philippines',
  },
  {
    code: 'PN',
    title: 'Pitcairn',
  },
  {
    code: 'PL',
    title: 'Poland',
  },
  {
    code: 'PT',
    title: 'Portugal',
  },
  {
    code: 'PR',
    title: 'Puerto Rico',
  },
  {
    code: 'QA',
    title: 'Qatar',
  },
  {
    code: 'CG',
    title: 'Republic of the Congo',
  },
  {
    code: 'RE',
    title: 'Reunion',
  },
  {
    code: 'RO',
    title: 'Romania',
  },
  {
    code: 'RU',
    title: 'Russia',
  },
  {
    code: 'RW',
    title: 'Rwanda',
  },
  {
    code: 'BL',
    title: 'Saint Barthelemy',
  },
  {
    code: 'SH',
    title: 'Saint Helena',
  },
  {
    code: 'KN',
    title: 'Saint Kitts and Nevis',
  },
  {
    code: 'LC',
    title: 'Saint Lucia',
  },
  {
    code: 'MF',
    title: 'Saint Martin',
  },
  {
    code: 'PM',
    title: 'Saint Pierre and Miquelon',
  },
  {
    code: 'VC',
    title: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'WS',
    title: 'Samoa',
  },
  {
    code: 'SM',
    title: 'San Marino',
  },
  {
    code: 'ST',
    title: 'Sao Tome and Principe',
  },
  {
    code: 'SA',
    title: 'Saudi Arabia',
  },
  {
    code: 'SN',
    title: 'Senegal',
  },
  {
    code: 'RS',
    title: 'Serbia',
  },
  {
    code: 'SC',
    title: 'Seychelles',
  },
  {
    code: 'SL',
    title: 'Sierra Leone',
  },
  {
    code: 'SG',
    title: 'Singapore',
  },
  {
    code: 'SX',
    title: 'Sint Maarten',
  },
  {
    code: 'SK',
    title: 'Slovakia',
  },
  {
    code: 'SI',
    title: 'Slovenia',
  },
  {
    code: 'SB',
    title: 'Solomon Islands',
  },
  {
    code: 'SO',
    title: 'Somalia',
  },
  {
    code: 'ZA',
    title: 'South Africa',
  },
  {
    code: 'GS',
    title: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'KR',
    title: 'South Korea',
  },
  {
    code: 'SS',
    title: 'South Sudan',
  },
  {
    code: 'ES',
    title: 'Spain',
  },
  {
    code: 'LK',
    title: 'Sri Lanka',
  },
  {
    code: 'SD',
    title: 'Sudan',
  },
  {
    code: 'SR',
    title: 'Suriname',
  },
  {
    code: 'SJ',
    title: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SZ',
    title: 'Swaziland',
  },
  {
    code: 'SE',
    title: 'Sweden',
  },
  {
    code: 'CH',
    title: 'Switzerland',
  },
  {
    code: 'SY',
    title: 'Syria',
  },
  {
    code: 'TW',
    title: 'Taiwan',
  },
  {
    code: 'TJ',
    title: 'Tajikistan',
  },
  {
    code: 'TZ',
    title: 'Tanzania',
  },
  {
    code: 'TH',
    title: 'Thailand',
  },
  {
    code: 'TG',
    title: 'Togo',
  },
  {
    code: 'TK',
    title: 'Tokelau',
  },
  {
    code: 'TO',
    title: 'Tonga',
  },
  {
    code: 'TT',
    title: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    title: 'Tunisia',
  },
  {
    code: 'TR',
    title: 'Turkey',
  },
  {
    code: 'TM',
    title: 'Turkmenistan',
  },
  {
    code: 'TC',
    title: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    title: 'Tuvalu',
  },
  {
    code: 'VI',
    title: 'U.S. Virgin Islands',
  },
  {
    code: 'UG',
    title: 'Uganda',
  },
  {
    code: 'UA',
    title: 'Ukraine',
  },
  {
    code: 'AE',
    title: 'United Arab Emirates',
  },
  {
    code: 'GB',
    title: 'United Kingdom',
  },
  {
    code: 'US',
    title: 'United States',
  },
  {
    code: 'UM',
    title: 'United States Minor Outlying Islands',
  },
  {
    code: 'UY',
    title: 'Uruguay',
  },
  {
    code: 'UZ',
    title: 'Uzbekistan',
  },
  {
    code: 'VU',
    title: 'Vanuatu',
  },
  {
    code: 'VA',
    title: 'Vatican',
  },
  {
    code: 'VE',
    title: 'Venezuela',
  },
  {
    code: 'VN',
    title: 'Vietnam',
  },
  {
    code: 'WF',
    title: 'Wallis and Futuna',
  },
  {
    code: 'EH',
    title: 'Western Sahara',
  },
  {
    code: 'YE',
    title: 'Yemen',
  },
  {
    code: 'ZM',
    title: 'Zambia',
  },
  {
    code: 'ZW',
    title: 'Zimbabwe',
  },
];
