import {Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {CompanyGroupFormComponent} from '../company-group-form/company-group-form.component';
import {MembersTableComponent} from '../members-table/members-table.component';
import {Store} from "@ngrx/store";
import {take} from "rxjs";
import {selectCompanyResolve} from "../../stores/store.selector";
import {filter} from "rxjs/operators";


@Component({
  selector: 'app-company-resolver',
  templateUrl: './company-resolver.component.html',
  styleUrls: ['./company-resolver.component.scss'],
})
export class CompanyResolverComponent implements OnInit, OnDestroy{

  completed = false;
  constructor(private viewContainerRef: ViewContainerRef, private store: Store) {}

  ngOnInit(): void {
    this.resolveComponent();
    if(!this.completed) {
      this.loadComponentCompany();
    } else {
      this.resolveComponent();
    }
  }

  resolveComponent() {
    this.store.select(selectCompanyResolve).pipe(
        filter(resolve => resolve.isReady),
        take(1)
    ).subscribe(resolve => {
      if(resolve.companyComponent) this.loadComponentCompany();
      if(resolve.memberComponent) this.loadComponentMembers();
      this.completed = resolve.isReady;
    })
  }

  ngOnDestroy() {
  }

  private loadComponentMembers() {
    this.viewContainerRef.clear();
    this.viewContainerRef.createComponent(MembersTableComponent);
  }
  private loadComponentCompany() {
    this.viewContainerRef.clear();
    this.viewContainerRef.createComponent(CompanyGroupFormComponent);
  }
}
