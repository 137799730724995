<div class="modal-container">
  <div class="modal-header">
    <span>{{data.headerText}}</span>
  </div>
  <div class="seperator">
    <hr />
  </div>
  <div class="modal-body" >
    <p>{{data.bodyText}}</p>
    <div class="modal-content">
      <div class="buttons">
        <ng-container *ngIf="data.cancelable">
          <app-button
            [small]="true"
            [title]="'CANCEL'"
            (click)="abortModal(true)"
          ></app-button>
        </ng-container>
        <app-button
          [small]="true"
          [title]="'OK'"
          (click)="abortModal(false)"
        ></app-button>
      </div>
    </div>
  </div>
</div>
