import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EquipmentState} from "../store.states";
import {aircraftsAdapter, softwareAdapter} from "./equipment.reducer";

export const selectEquipmentState = createFeatureSelector<EquipmentState>('equipment');

export const selectEquipment = createSelector(
  selectEquipmentState,
  (state) => ({
    ...state
  })
);

export const selectAircrafts = createSelector(
  selectEquipment,
  (state) => state.aircrafts
)

export const selectSoftware = createSelector(
  selectEquipment,
  (state) => state.software
)

export const {
  selectAll: selectAllAircrafts,
  selectEntities: selectAicraftEntities,
  selectIds: selectAircraftIds,
  selectTotal: selectTotalAicrafts,
} = aircraftsAdapter.getSelectors(selectAircrafts);

export const {
  selectAll: selectAllSoftware,
  selectEntities: selectSoftwareEntities,
  selectIds: selectSoftwareIds,
  selectTotal: selectTotalSoftware,
} = softwareAdapter.getSelectors(selectSoftware);
