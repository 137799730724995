import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MemberType } from 'src/app/models/company.model';

type OperatorOptionsInputFormGroup = FormGroup<{
  operator: FormControl<string>;
}>;
@Component({
  selector: 'app-operator-options-input',
  templateUrl: './operator-options-input.component.html',
  styleUrls: ['./operator-options-input.component.scss', '../inputs.scss'],
})
export class OperatorOptionsInputComponent implements OnInit {
  showOperator: boolean = false;

  @Input() operatorOptions: MemberType[] = [];
  @Input() initValue: string = '';

  public static readonly operatorOptionsGroup: () => OperatorOptionsInputFormGroup =
    () => new FormGroup({ operator: new FormControl() });

  operatorOptionsGroup!: OperatorOptionsInputFormGroup;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.operatorOptionsGroup = this.controlContainer
      .control as OperatorOptionsInputFormGroup;
    this.operatorOptionsGroup.controls.operator.setValue(this.initValue);
    this.operatorOptionsGroup.controls.operator.setValidators([
      Validators.required,
    ]);
    this.operatorOptionsGroup.controls.operator.updateValueAndValidity();
  }

  toggleShowingOperator(): void {
    this.showOperator = !this.showOperator;
  }
  close() {
    this.showOperator = false;
  }
}
