import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  saveInviteLinkToLocalStorage(link: string) {
    window.localStorage.setItem('inviteLink', JSON.stringify({
      joinLink: link
    }))
  }

  getInviteLinkFromLocalStorage() {
    const invite = window.localStorage.getItem('inviteLink');
    if(invite) {
      return JSON.parse(invite);
    } else {
      return null;
    }
  }

  removeInviteLinkFromLocalStorage() {
    window.localStorage.removeItem('inviteLink')
  }
}
