import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss']
})
export class TabButtonComponent {
  @Input() title: string = "";
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;
}
