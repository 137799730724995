<div class="group">
  <div
    class="checkbox-wrap"
    (click)="checkboxControl.setValue(!checkboxControl.value); checkboxControl.markAsDirty()"
    [class.dirty-and-invalid]="checkboxControl.invalid && checkboxControl.dirty"
  >
    <div class="checkbox" [ngClass]="checkboxControl.value?'checked':''"></div>
    <p>{{ label }}</p>
    <input
      type="checkbox"
      [formControl]="checkboxControl"
    />
  </div>
  <div *ngIf="checkboxControl.invalid && checkboxControl.errors && checkboxControl.dirty" [class.dirty]="checkboxControl.dirty" class="error-message">
    <div *ngIf="checkboxControl.errors['required']">You have to accept the conditions</div>
  </div>
</div>
