import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, Validators} from "@angular/forms";
import {OptionsCountry} from "../../profile-form/profile-form.component";

type CountryOptionsInputFormGroup = FormGroup<{country: FormControl<string>}>
@Component({
  selector: 'app-country-options-input',
  templateUrl: './country-options-input.component.html',
  styleUrls: ['./country-options-input.component.scss', '../inputs.scss']
})
export class CountryOptionsInputComponent implements OnInit{
  showCountry: boolean = false;
  @Input() countryOptions: OptionsCountry[] = [];

  @Input() initValue: string = '';
  public static readonly countryOptionsGroup: () => CountryOptionsInputFormGroup = () => new FormGroup(
    {country: new FormControl()}
  )
  countryOptionsGroup!: CountryOptionsInputFormGroup;

  constructor(private controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.countryOptionsGroup = this.controlContainer.control as CountryOptionsInputFormGroup;
    this.countryOptionsGroup.controls.country.setValue(this.initValue);
    this.countryOptionsGroup.controls.country.setValidators([Validators.required]);
    this.countryOptionsGroup.controls.country.updateValueAndValidity();
  }
  toggleShowingCountry() : void {
    this.showCountry = !this.showCountry;
  }
  close() {
    this.showCountry = false;
  }
}
