import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '../../models/user.model';
import { Store } from '@ngrx/store';
import {selectProfileCompanyId, selectProfileData, selectProfileRole} from '../../stores/profile/profile.selector';
import { selectCompanyData } from '../../stores/company/company.selector';
import {DashboardResponseBanner, DashboardService} from "../../services/dashboard.service";

@Component({
  selector: 'app-dashboard-tabs',
  templateUrl: './dashboard-tabs.component.html',
  styleUrls: ['./dashboard-tabs.component.scss'],
})
export class DashboardTabsComponent {
  company$ = this.store.select(selectCompanyData);
  responseBanner$ = this.dashboardService.dashboardResponseBanner$;

  constructor(
    protected router: Router,
    private store: Store,
    private dashboardService: DashboardService
  ) {
  }
  profile$ = this.store.select(selectProfileData);
  protected readonly UserRole = UserRole;

}
