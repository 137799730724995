import {Component, Input} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, Validators} from "@angular/forms";

type JobtitleInputFormControl = FormControl<string>
@Component({
  selector: 'app-jobtitle-input',
  templateUrl: './jobtitle-input.component.html',
  styleUrls: ['./jobtitle-input.component.scss', '../inputs.scss']
})
export class JobtitleInputComponent {
  @Input() initValue: string = '';
  @Input() set value(val: string) {
    if(this.jobtitleControl) this.jobtitleControl.setValue(val);
  }
  @Input() controllerName: string = '';
  jobtitleControl!: FormControl;

  public static readonly jobtitleControl: () => JobtitleInputFormControl = () => new FormControl();
  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.jobtitleControl = (this.controlContainer.control as FormGroup).get(this.controllerName) as JobtitleInputFormControl;
    this.jobtitleControl.setValue(this.initValue);
    this.jobtitleControl.setValidators([Validators.required, Validators.maxLength(100)]);
    this.jobtitleControl.updateValueAndValidity();
  }
}
