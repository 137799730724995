import { Component } from '@angular/core';
import {ProgressService} from "../../services/progress.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  profileProgress$: Observable<number>;

  constructor(private progressService: ProgressService) {
    this.profileProgress$ = this.progressService.getProgressAsObservable();
  }

}
