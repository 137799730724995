import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ButtonComponent } from './components/button/button.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { InputComponent } from './components/input/input.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TopnavComponent } from './components/topnav/topnav.component';
import { EquipmentComponent } from './pages/equipment/equipment.component';
import { ProgressComponent } from './pages/progress/progress.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AcademyComponent } from './pages/academy/academy.component';
import { HelpComponent } from './pages/help/help.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  ProtectedResourceScopes,
} from '@azure/msal-angular';

import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { CompanyInputComponent } from './components/inputs/company-input/company-input.component';
import { CountryOptionsInputComponent } from './components/inputs/country-options-input/country-options-input.component';
import { OperatorOptionsInputComponent } from './components/inputs/operator-options-input/operator-options-input.component';
import { StateOrProvinceInputComponent } from './components/inputs/state-or-province-input/state-or-province-input.component';
import { IndustryOptionsInputComponent } from './components/inputs/industry-options-input/industry-options-input.component';
import { ApplicationsOptionsInputComponent } from './components/inputs/applications-options-input/applications-options-input.component';
import { FirstnameInputComponent } from './components/inputs/firstname-input/firstname-input.component';
import { LastnameInputComponent } from './components/inputs/lastname-input/lastname-input.component';
import { JobtitleInputComponent } from './components/inputs/jobtitle-input/jobtitle-input.component';
import { EmailInputComponent } from './components/inputs/email-input/email-input.component';
import { ResponseBannerComponent } from './components/response-banner/response-banner.component';
import { PostalcodeInputComponent } from './components/inputs/postalcode-input/postalcode-input.component';
import { FullscreenLoadingSpinnerComponent } from './components/fullscreen-loading-spinner/fullscreen-loading-spinner.component';
import { LoadingInterceptor } from './interceptor/loading.interceptor';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { FallbackComponent } from './components/fallback/fallback.component';
import { TypeAheadFocusDirective } from './directives/type-ahead-focus.directive';
import { ActionOnOutsideClickDirective } from './directives/action-on-outside-click.directive';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CompanyGroupFormComponent } from './components/company-group-form/company-group-form.component';
import { TabButtonComponent } from './components/tab-button/tab-button.component';
import { CardComponent } from './components/card/card.component';
import { MembersTableComponent } from './components/members-table/members-table.component';
import { AvatarCompanyComponent } from './components/avatar-company/avatar-company.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AddDroneModalComponent } from './components/modals/add-drone-modal/add-drone-modal.component';
import { InviteMemberModalComponent } from './components/modals/invite-member-modal/invite-member-modal.component';
import { DialogModule } from '@angular/cdk/dialog';
import {
  CdkMenu,
  CdkMenuBar,
  CdkMenuItem,
  CdkMenuTrigger,
} from '@angular/cdk/menu';
import { LicensekeyInputComponent } from './components/inputs/licensekey-input/licensekey-input.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { profileReducer } from './stores/profile/profile.reducer';
import { ProfileEffects } from './stores/profile/profile.effects';
import { companyReducer } from './stores/company/company.reducer';
import { CompanyEffects } from './stores/company/company.effects';
import { equipmentReducer } from './stores/equipment/equipment.reducer';
import { EquipmentEffects } from './stores/equipment/equipment.effects';
import { DashboardTabsComponent } from './components/dashboard-tabs/dashboard-tabs.component';
import { CompanyResolverComponent } from './components/company-resolver/company-resolver.component';
import { InfoboxComponent } from './components/infobox/infobox.component';
import { CompanyGroupInformationFormComponent } from './components/company-group-information-form/company-group-information-form.component';
import { CheckboxInputComponent } from './components/inputs/checkbox-input/checkbox-input.component';
import { JoinGroupModalComponent } from './components/modals/join-group-modal/join-group-modal.component';
import { JoinComponent } from './pages/join/join.component';
import { GroupActionsModalComponent } from './components/modals/group-actions-modal/group-actions-modal.component';
import { InformationModalComponent } from './components/modals/information-modal/information-modal.component';
import { MissionsComponent } from './pages/missions/missions.component';
import { MissionPlannerComponent } from './components/mission-planner/mission-planner.component';
import { NewMissionModalComponent } from './components/modals/new-mission-modal/new-misson-modal.component';
import { DroneOptionsInputComponent } from './components/inputs/drone-options-input/drone-options-input.component';
import { MissionNameInputComponent } from './components/inputs/mission-name-input/mission-name-input.component';
import { TimestampInputComponent } from './components/inputs/timestamp-input/timestamp-input.component';
import { CompleteMissionModalComponent } from './components/modals/complete-mission-modal/complete-misson-modal.component';
import { ResultUrlInputComponent } from './components/inputs/result-url-input/result-url-input.component';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();

  protectedResourceMap.set(protectedResources.apiTodoList.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiTodoList.scopes.read],
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiTodoList.scopes.write],
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.apiTodoList.scopes.write],
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.apiTodoList.scopes.write],
    },
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ButtonComponent,
    AvatarComponent,
    InputComponent,
    SidenavComponent,
    TopnavComponent,
    EquipmentComponent,
    ProgressComponent,
    AcademyComponent,
    HelpComponent,
    ProfileFormComponent,
    FullscreenLoadingSpinnerComponent,
    LoadingSpinnerComponent,
    CompanyInputComponent,
    CountryOptionsInputComponent,
    DroneOptionsInputComponent,
    OperatorOptionsInputComponent,
    StateOrProvinceInputComponent,
    IndustryOptionsInputComponent,
    ApplicationsOptionsInputComponent,
    FirstnameInputComponent,
    LastnameInputComponent,
    JobtitleInputComponent,
    EmailInputComponent,
    ResponseBannerComponent,
    PostalcodeInputComponent,
    FullscreenLoadingSpinnerComponent,
    LoadingSpinnerComponent,
    FallbackComponent,
    TypeAheadFocusDirective,
    ActionOnOutsideClickDirective,
    ProgressBarComponent,
    CompanyGroupFormComponent,
    TabButtonComponent,
    CardComponent,
    MembersTableComponent,
    AvatarCompanyComponent,
    AddDroneModalComponent,
    InviteMemberModalComponent,
    LicensekeyInputComponent,
    DashboardTabsComponent,
    CompanyResolverComponent,
    InfoboxComponent,
    TimestampInputComponent,
    CompanyGroupInformationFormComponent,
    CheckboxInputComponent,
    JoinGroupModalComponent,
    JoinComponent,
    GroupActionsModalComponent,
    InformationModalComponent,
    MissionsComponent,
    MissionPlannerComponent,
    NewMissionModalComponent,
    MissionNameInputComponent,
    CompleteMissionModalComponent,
    ResultUrlInputComponent,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MsalModule,
    OverlayModule,
    DialogModule,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    StoreModule.forRoot({
      profile: profileReducer,
      company: companyReducer,
      equipment: equipmentReducer,
    }, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
    }),
    EffectsModule.forRoot([ProfileEffects, CompanyEffects, EquipmentEffects]),
    CdkMenuBar,
    BaseChartDirective
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables())
  ]
})
export class AppModule { }
