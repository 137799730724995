import {Component} from '@angular/core';
import {Dialog} from '@angular/cdk/dialog';
import {InviteMemberModalComponent} from '../modals/invite-member-modal/invite-member-modal.component';
import {Store} from '@ngrx/store';
import {Observable, take} from 'rxjs';
import {MemberType} from '../../models/company.model';
import {
  selectAllCompanyMembersWithSelfFirst,
  selectCompanyData,
  selectCompanyMembers,
} from '../../stores/company/company.selector';
import {selectProfileData, selectProfileRole,} from '../../stores/profile/profile.selector';
import {CompanyState, LoadingState, ProfileState} from '../../stores/store.states';
import {UserRole} from '../../models/user.model';
import {Router} from '@angular/router';
import {dispatchLoadingMembersTimeLocked} from '../../stores/store.utils';
import {updatingProfileRole} from '../../stores/profile/profile.actions';
import {deleteMember, leaveCompany,} from '../../stores/company/company.actions';
import {
  GroupActionsModalComponent,
  GroupActionsModalData,
} from '../modals/group-actions-modal/group-actions-modal.component';
import {DashboardService} from 'src/app/services/dashboard.service';
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.scss'],
})
export class MembersTableComponent {
  profile$: Observable<ProfileState>;
  constructor(
    private dialog: Dialog,
    private store: Store,
    private router: Router,
    private dashboardService: DashboardService
  ) {
    dispatchLoadingMembersTimeLocked(store);
    this.profile$ = this.store.select(selectProfileData);
    this.members$ = this.store.select(selectAllCompanyMembersWithSelfFirst);
    this.company$ = this.store.select(selectCompanyData);
  }
  members$: Observable<MemberType[]>;
  company$: Observable<CompanyState>;
  role$ = this.store.select(selectProfileRole);

  openConfirmLeave(profileId: string) {
    this.dialog.open<GroupActionsModalData>(GroupActionsModalComponent, {
      data: {
        action: 'leave',
        leaving: true,
        buttonTitel: 'LEAVE',
        callback: (companyId: string) => {
          this.store.dispatch(
            leaveCompany({
              memberId: profileId,
              companyId: companyId,
            })
          );
          this.dashboardService.openResponseBannerOnResponse(
            'You left the group successfully',
            'Something went wrong',
            this.company$,
            'updateState'
          );
          this.router.navigate(['/dashboard/profile']);
        },
      },
    });
  }

  openInviteDialog() {
    const dialogRef = this.dialog.open<any>(InviteMemberModalComponent);
    dialogRef.closed.subscribe((result) => {
      console.log(result);
    });
  }

  editCompany() {
    this.router.navigate(['/dashboard/company']);
  }

  protected readonly UserRole = UserRole;

  changeRole(member: MemberType, company: CompanyState, isCurrentUser: boolean) {
    this.store.dispatch(
      updatingProfileRole({
        memberId: member.userId,
        companyId: company.id as string,
        role: member.role === UserRole.PILOT ? UserRole.ADMIN : UserRole.PILOT,
      })
    );
    if(isCurrentUser) {
      this.store.select(selectProfileData).pipe(filter(profile => profile.updatedState === LoadingState.SUCCESS), take(1)).subscribe(
          () => this.router.navigate(["/dashboard/company"]).then(() => window.location.reload())
      )
    }
  }

  deleteMember(member: MemberType, company: CompanyState, isCurrentUser: boolean) {
    this.store.dispatch(
      deleteMember({
        memberId: member.userId,
        companyId: company.id as string,
      })
    );
    if(isCurrentUser) {
      this.store.select(selectCompanyData).pipe(filter(company => company.updateState === LoadingState.SUCCESS), take(1)).subscribe(
          () => this.router.navigate(["/dashboard/profile"]).then(() => window.location.reload())
      )
    }
  }

  protected readonly length = length;

  moreThanOneAdmin(members: MemberType[]) {
    return (members.filter(member => member.role === UserRole.ADMIN).length>1)
  }
}
