import {AfterViewInit, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-response-banner',
  templateUrl: './response-banner.component.html',
  styleUrls: ['./response-banner.component.scss']
})
export class ResponseBannerComponent implements AfterViewInit {
  @Input() success: boolean = true;
  @Input() successMessage: string = "Successful!"
  @Input() errorMessage: string = "Something went wrong..."
  @Input() invisible: boolean = true;
  _transition: boolean = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._transition = true;
    })
  }
}
