import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, take} from 'rxjs';
import {JobtitleInputComponent} from '../inputs/jobtitle-input/jobtitle-input.component';
import {Store} from '@ngrx/store';
import {selectProfileData} from '../../stores/profile/profile.selector';
import * as ProfileActions from '../../stores/profile/profile.actions';
import {LoadingState, ProfileState} from '../../stores/store.states';
import {EmailInputComponent} from '../inputs/email-input/email-input.component';
import {
  dispatchLoadingCompany,
  dispatchLoadingProfileTimeLocked,
  dispatchLoadingProfileWithCompanyAndEquipment
} from "../../stores/store.utils";
import {filter} from "rxjs/operators";
import {LocalStorageService} from "../../services/local-storage.service";
import {Dialog} from "@angular/cdk/dialog";
import {JoinCompanyService} from "../../services/join-company.service";
import {Router} from "@angular/router";
import {InformationModalComponent} from "../modals/information-modal/information-modal.component";
import {selectCompanyData} from "../../stores/company/company.selector";

export interface OptionsCountry {
  code: string;
  title: string;
}

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent {
  successfulEdit: boolean = false;
  showResponseBanner: boolean = false;
  avatar?: string;

  form: FormGroup = this.formBuilder.nonNullable.group({
    jobtitle: JobtitleInputComponent.jobtitleControl(),
    email: EmailInputComponent.emailControl(),
  });

  profile$: Observable<ProfileState>;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private localStorageSerivce: LocalStorageService,
    private joingCompanyService: JoinCompanyService,
    private router: Router,
    private dialog: Dialog
    ) {
    dispatchLoadingProfileTimeLocked(store);
    this.profile$ = this.store.select(selectProfileData);
    this.profile$.subscribe((profile: ProfileState) => {
      if(profile.avatar) this.avatar = profile.avatar;
    });
  }

  getAvatar(image: string) {
    this.avatar = image;
  }

  update() {
    this.store.dispatch(
      ProfileActions.editingQuantumProfile({
        quantumProfile: {
          ...this.form.value,
          avatar: this.avatar
        },
      })
    );
    this.profile$.pipe(
      filter(profile =>
        profile.updatedState === LoadingState.SUCCESS || profile.updatedState === LoadingState.ERROR
      ),
      take(1))
      .subscribe((profile: ProfileState) => {
      if (profile.updatedState === LoadingState.SUCCESS) {
        this.successfulEdit = true;
        this.showResponseBanner = true;
        const invite = this.localStorageSerivce.getInviteLinkFromLocalStorage();
        if(invite) {
          this.joingCompanyService.joinCompany(invite.joinLink).subscribe({
              next: (joinResult) => {
                this.localStorageSerivce.removeInviteLinkFromLocalStorage();
                this.openInformationDialogToConfirmCompletion();
              },
              error: (error) => {
              }
            }
          );
        }
      } else if (profile.updatedState === LoadingState.ERROR) {
        this.successfulEdit = false;
        this.showResponseBanner = true;
      }
    });
  }

  openInformationDialogToConfirmCompletion() {
    dispatchLoadingProfileWithCompanyAndEquipment(this.store);
    this.store.select(selectCompanyData).pipe(
      filter(company => company.loadingState === LoadingState.SUCCESS),
      take(1)
    ).subscribe(company => {
      const dialogRef = this.dialog.open(InformationModalComponent, {
        data: {
          headerText: "Congratulations on joining " + company.name,
          bodyText: "You are now in the company group " + company.name + "!"
        }
      });
      dialogRef.closed.subscribe(_ => {
        this.router.navigate(['/dashboard/company'])
      })
    })
  }
  protected readonly LoadingState = LoadingState;
}
