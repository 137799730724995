import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators, ControlContainer, FormGroup } from "@angular/forms";

type LicenseKeyInputFormControl = FormControl<string>;
@Component({
  selector: 'app-licensekey-input',
  templateUrl: './licensekey-input.component.html',
  styleUrls: ['../inputs.scss', './licensekey-input.component.scss']
})
export class LicensekeyInputComponent implements OnInit {
  @Input() controllerName: string = "";
  @Input() initValue: string = "";

  licenseKeyControl!: LicenseKeyInputFormControl;
  public static readonly licenseKeyControl: () => LicenseKeyInputFormControl = () => new FormControl();

  constructor(private controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.licenseKeyControl = (this.controlContainer.control as FormGroup).get(this.controllerName) as LicenseKeyInputFormControl;
    this.licenseKeyControl.setValue(this.initValue);
    this.licenseKeyControl.setValidators([Validators.required]);
    this.licenseKeyControl.updateValueAndValidity();
  }
}
