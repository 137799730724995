import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input() imagename: string = "";
  @Output() click: EventEmitter<any> = new EventEmitter<any>();
}
