<div
  class="group" appActionOnOutsideClick (clickOutside)="close()"
  [ngClass]="
            industryOptionsGroup.value
            && industryOptionsGroup.value.industry
            && industryOptionsGroup.value.industry.length > 32
              ? 'gross'
              : ''
          "
>
  <label>Industry</label>
  <div
    class="selected-item"
    [ngClass]="showIndustry ? 'arrow-down' : ''"
  >
    <div class="option"
         [ngClass]="industryOptionsGroup.invalid ? 'invalid' : 'valid'"
         [class.dirty]="industryOptionsGroup.dirty"
    >
      <input type="button" name="group" (click)="toggleShowingIndustry()" />
      <p *ngIf="industryOptionsGroup.value.industry">
        {{ industryOptionsGroup.value.industry }}
      </p>
      <p *ngIf="!industryOptionsGroup.value.industry" class="placeholder">Choose...</p>
    </div>
  </div>
  <div class="option-wrap" [ngClass]="showIndustry ? 'active' : ''">
    <div
      class="option children"
      *ngFor="let option of fieldsApplicationOptions"
      [formGroup]="industryOptionsGroup"
    >
      <input
        type="radio"
        name="industry"
        [formControlName]="'industry'"
        [value]="option"
        (click)="toggleShowingIndustry()"
      />
      <p>{{ option }}</p>
    </div>
  </div>
  <div *ngIf="industryOptionsGroup.invalid && !showIndustry && industryOptionsGroup.dirty" [class.dirty]="industryOptionsGroup.dirty" class="error-message">
    <div>*required</div>
  </div>
</div>
