import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Aircrafts } from 'src/app/models/equipment.model';

type DroneOptionsInputFormGroup = FormGroup<{
  drone: FormControl<string>;
}>;
@Component({
  selector: 'app-drone-options-input',
  templateUrl: './drone-options-input.component.html',
  styleUrls: ['./drone-options-input.component.scss', '../inputs.scss'],
})
export class DroneOptionsInputComponent implements OnInit {
  showDrone: boolean = false;

  @Input() droneOptions: Aircrafts[] = [];
  @Input() initValue: string = '';

  public static readonly droneOptionsGroup: () => DroneOptionsInputFormGroup =
    () => new FormGroup({ drone: new FormControl() });

  droneOptionsGroup!: DroneOptionsInputFormGroup;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.droneOptionsGroup = this.controlContainer
      .control as DroneOptionsInputFormGroup;
    this.droneOptionsGroup.controls.drone.setValue(this.initValue);
    this.droneOptionsGroup.controls.drone.setValidators([Validators.required]);
    this.droneOptionsGroup.controls.drone.updateValueAndValidity();
  }

  toggleShowingDrone(): void {
    this.showDrone = !this.showDrone;
  }
  close() {
    this.showDrone = false;
  }
}
