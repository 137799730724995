<div class="group">
  <label>Postal Code</label>
  <input
    [formControl]="postalcodeControl"
    type="text"
    placeholder=""
    class="quantum-input"
    [ngClass]="postalcodeControl.invalid ? 'invalid' : ''"
  />
  <div
    *ngIf="
      postalcodeControl.invalid &&
      postalcodeControl.errors &&
      postalcodeControl.dirty
    "
    [class.dirty]="postalcodeControl.dirty"
    class="error-message"
  >
    <div *ngIf="postalcodeControl.errors['required']">*required</div>
  </div>
</div>
