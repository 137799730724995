<div class="company-group-information-form" *ngIf="showCardToCreatCompanyGroup">
  <app-company-group-information-form
    (wantsToCreateGroup)="showOnlyCompanyEdit()"
  ></app-company-group-information-form>
</div>
<ng-container *ngIf="company$ | async; let company">
  <form
    [formGroup]="form"
    [noValidate]="false"
    (ngSubmit)="update()"
    *ngIf="showCompanyEdit"
  >
    <div class="company-top-block">
      <app-avatar-company
        [avatar]="company.avatar || 'assets/imgs/placeholder-avatar.png'"
        (imagebase64)="getAvatar($event)"
      >
      </app-avatar-company>
      <div
        *ngIf="company.name"
        class="close-group"
        (click)="openActionDialog()"
      >
        <div class="icon-close">
          <img src="/assets/svg/close-x.svg" class="iconx" /> delete group
        </div>
      </div>
    </div>
    <div class="six-inputs">
      <app-company-input
        [initValue]="company.name"
        [controllerName]="'company'"
      ></app-company-input>
      <div class="area">
        <app-country-options-input
          [countryOptions]="countryOptions"
          [initValue]="company.country"
          [formGroupName]="'country'"
        ></app-country-options-input>
        <app-state-or-province-input
          [controllerName]="'stateOrProvince'"
          [initValue]="company.stateOrProvince"
        ></app-state-or-province-input>
        <app-postalcode-input
          [initValue]="company.postalCode"
          [controllerName]="'postalcode'"
        ></app-postalcode-input>
      </div>
      <app-industry-options-input
        [initValue]="company.industry"
        [formGroupName]="'industry'"
        [fieldsApplicationOptions]="fieldsApplicationOptions"
        (applicationsForIndustry)="changeOptions($event)"
      ></app-industry-options-input>
      <app-applications-options-input
        [selectedOptions]="company.fieldsOfApplication"
        [changeOptions]="applicationOptions"
        [formGroupName]="'application'"
      >
      </app-applications-options-input>
    </div>
    <div class="button-container">
      <app-response-banner
        [errorMessage]="errorMessageResponseBanner ?? 'Something went wrong...'"
        [successMessage]="successMessageResponseBanner ?? 'Success!'"
        [success]="successfulEdit"
        [invisible]="!showResponseBanner"
      ></app-response-banner>
      <app-button
        [title]="company.completed ? 'SAVE CHANGES' : 'CREATE'"
      ></app-button>
    </div>
  </form>
</ng-container>
