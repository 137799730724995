<div class="image-container">
  <img src="{{previewURL || avatar}}" alt="company logo" />
</div>
<ng-container *ngIf="isInfoAvatar()">
  <div class="content-container info">
    <div class="name">{{ name }}</div>
    <div class="area">{{ area }} {{ subarea?'| '+subarea:'' }}</div>
    <div class="address">{{ address }}</div>
  </div>
</ng-container>
<ng-container *ngIf="!isInfoAvatar()">
  <div class="button-container">
    <div class="button-texts">
      <div class="upload-text">Upload new Avatar</div>
      <div
        [ngStyle]="{
        color: isAvatarTooBig ? 'red' : ''
      }"
        class="info-text"
      >
        {{
        isAvatarTooBig
          ? "The image is higher than 200KB"
          : "The maximum file size allowed is 200KB."
        }}
      </div>
      <div class="choose">
        <input
          type="file"
          (change)="selectFile($event)"
          class="select-profile-picture"
        />
        <div class="message">
          <img class="icon-upload" src="../assets/svg/icons8-upload.svg" />
          <span>choose a picture</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
