<div class="image-container" *ngIf="avatarInput">
  <ng-container *ngIf="user$ | async; let user">
    <img *ngIf="user.avatar || previewURL" [src]="previewURL || user.avatar" class=""  alt="profile avatar"/>
  </ng-container>
  <ng-container *ngIf="user$ | async; let user">
    <div *ngIf="!user.avatar && !previewURL" class="user-profile">
      <span *ngIf="user$ | async; let user" class="user-name">{{
        getInitalsFromName(user.given_name)
      }}</span>
    </div>
  </ng-container>
  <div class="button-container">
    <div class="button-texts">
      <div class="upload-text">Upload new Avatar</div>
      <div
        [ngStyle]="{
          color: isAvatarTooBig ? 'red' : ''
        }"
        class="info-text"
      >
        {{
          isAvatarTooBig
            ? "The image is higher than 200KB"
            : "The maximum file size allowed is 200KB."
        }}
      </div>
      <div class="choose">
        <input
          type="file"
          (change)="selectFile($event)"
          class="select-profile-picture"
        />
        <div class="message">
          <img class="icon-upload" src="../assets/svg/icons8-upload.svg" />
          <span>choose a picture</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="image-container-top" *ngIf="avatarMenu">
  <div class="avatar-container-top" *ngIf="user$ | async; let user">
    <div class="image-container-top">
      <ng-container *ngIf="user$ | async; let user">
        <div class="user-profile-top">
          <span *ngIf="!user.avatar && !previewURL" class="user-name-top">{{
            getInitalsFromName(user.firstName)
          }}</span>
          <img *ngIf="user.avatar || previewURL" [src]="user.avatar || previewURL" class="" />
        </div>
      </ng-container>
      <div class="button-container-top">
        <div class="texts-top">
          <div class="name-top">{{ user.firstName }} {{ user.lastName }}</div>
          <div class="job-top">{{ user.jobTitle }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="avatar-container-top" *ngIf="avatarTop">
  <div class="image-container-top" *ngIf="user$ | async; let user">
    <div class="user-profile-top">
      <span *ngIf="!user.avatar && !previewURL" class="user-name-top">{{
        getInitalsFromName(user.firstName)
      }}</span>
      <img *ngIf="user.avatar || previewURL" [src]="user.avatar || previewURL" class="" />
    </div>
    <div class="button-container-top">
      <div class="texts-top">
        <div class="name-top">{{ user.firstName }} {{ user.lastName }}</div>
        <div class="job-top">{{ user.jobTitle }}</div>
      </div>
    </div>
  </div>
</div>
