import {Component, OnInit} from '@angular/core';
import {Aircrafts, Software} from 'src/app/models/equipment.model';
import {EquipmentService} from 'src/app/services/equipment.service';
import {ProgressService} from "../../services/progress.service";
import {
  AddDroneModalComponent,
} from "../../components/modals/add-drone-modal/add-drone-modal.component";
import {Dialog} from "@angular/cdk/dialog";
import {Store} from "@ngrx/store";
import * as EquipmentActions from "../../stores/equipment/equipment.actions";
import * as CompanyActions from '../../stores/company/company.actions';
import {Observable} from "rxjs";
import {CompanyState, EquipmentState, ProfileState} from "../../stores/store.states";
import {
  selectAircrafts,
  selectAllAircrafts,
  selectAllSoftware,
  selectEquipment
} from "../../stores/equipment/equipment.selector";
import {selectProfileCompanyId, selectProfileData} from "../../stores/profile/profile.selector";
import * as ProfileActions from "../../stores/profile/profile.actions";
import {selectCompanyData} from "../../stores/company/company.selector";
import {dispatchLoadingProfileWithCompanyAndEquipment} from "../../stores/store.utils";

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent implements OnInit {
  constructor(
    private equipmentService: EquipmentService,
    private progressService: ProgressService,
    public dialog: Dialog,
    private store: Store
  ) {
    dispatchLoadingProfileWithCompanyAndEquipment(this.store);
    this.equipment$ = this.store.select(selectEquipment)
    this.aircrafts$ = this.store.select(selectAllAircrafts)
    this.software$ = this.store.select(selectAllSoftware)
    this.profile$ = this.store.select(selectProfileData)
    this.company$ = this.store.select(selectCompanyData)
    this.equipment$.subscribe(equipment => {
      if(equipment.completed) {
        this.changeToThirdStep();
      }
    })
    this.step$ = this.equipmentService.getStepAsObservable();
  }
  step$: Observable<number>;
  equipment$: Observable<EquipmentState>
  aircrafts$: Observable<Aircrafts[]>
  software$: Observable<Software[]>
  profile$: Observable<ProfileState>
  company$: Observable<CompanyState>
  ngOnInit() {
    this.progressService.getProgressAsObservable().subscribe(next => {
      if(next>=70 && next<=100) {
        this.equipmentService.setStep(2);
      }
    });
  }

  openAddDroneDialog() {
    const dialogRef = this.dialog.open(AddDroneModalComponent);
    dialogRef.closed.subscribe(result => {})
  }

  changeToThirdStep() {
    this.equipmentService.setStep(3);
  }
}
