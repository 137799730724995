import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-firstname-input',
  templateUrl: './firstname-input.component.html',
  styleUrls: ['./firstname-input.component.scss', '../inputs.scss']
})
export class FirstnameInputComponent {
  @Input() firstName = '';
}
