import {AfterViewInit, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CheckboxInputComponent} from "../inputs/checkbox-input/checkbox-input.component";

@Component({
  selector: 'app-company-group-information-form',
  templateUrl: './company-group-information-form.component.html',
  styleUrls: ['./company-group-information-form.component.scss']
})
export class CompanyGroupInformationFormComponent {
  @Output() wantsToCreateGroup: EventEmitter<void> = new EventEmitter<void>();
  form: FormGroup =  this.formBuilder.nonNullable.group({
    readInformationCheckbox: CheckboxInputComponent.checkboxInputFormControl()
  });
  protected readonly Validators = Validators;
  submitted: Boolean = false;

  constructor(private formBuilder: FormBuilder) {}

  onSubmit() {
    this.form.controls['readInformationCheckbox'].markAsDirty();
    this.submitted = true;
    if(this.form.value.readInformationCheckbox) {
      this.wantsToCreateGroup.emit();
    }
  }
}
