<div class="progress-container" *ngIf="profile$ | async; let profile">
  <div class="progress-text">
    <h1>Hello {{  profile.firstName }},</h1>
    <p>
      Welcome to Quantum Systems Customer Portal.<br />
      Here you can manage, update, and monitor your drones.
    </p>
  </div>
  <app-progress-bar></app-progress-bar>
  <app-button *ngIf="profileProgress<70 && !profile.completed" [title]="'COMPLETE PROFILE'" routerLink="/dashboard"></app-button>
  <app-button *ngIf="profileProgress<70 && profile.completed" [title]="'JOIN OR CREATE A GROUP'" routerLink="/dashboard/company"></app-button>
  <div class="management">
    <app-button (click)="setStep(3)" *ngIf="profileProgress>=70" [title]="'MANAGE COMPANY DRONES'" routerLink="/equipment"></app-button>
    <app-button *ngIf="profileProgress>=70 && profile.role === UserRole.ADMIN" [title]="'MANAGE COMPANY PILOTS'" routerLink="/dashboard/members"></app-button>
  </div>
</div>
