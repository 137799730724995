import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent {
  clickOn: boolean = false;
  @Output() logoutEvent = new EventEmitter();

  opening() {
    this.clickOn = !this.clickOn;
  }

  childMethod() {
    this.logoutEvent.emit();
  }
}
