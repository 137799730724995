import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

type PostalcodeInputFormControl = FormControl<string>;
@Component({
  selector: 'app-postalcode-input',
  templateUrl: './postalcode-input.component.html',
  styleUrls: ['./postalcode-input.component.scss', '../inputs.scss'],
})
export class PostalcodeInputComponent {
  @Input() controllerName: string = '';
  @Input() initValue: string = '';

  postalcodeControl!: PostalcodeInputFormControl;
  public static readonly postalcodeControl: () => PostalcodeInputFormControl =
    () => new FormControl();
  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.postalcodeControl = (this.controlContainer.control as FormGroup).get(
      this.controllerName
    ) as PostalcodeInputFormControl;
    this.postalcodeControl.setValue(this.initValue);
    this.postalcodeControl.setValidators([
      Validators.required,
      Validators.maxLength(100),
    ]);
    this.postalcodeControl.updateValueAndValidity();
  }
}
