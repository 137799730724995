<div
  class="group"
  [ngStyle]="!optionsBasedOnIndustry.length?{visibility: 'hidden'}:{}"
>
  <label>Fields Of Application</label>
  <div
    class="selected-item"
    [ngClass]="showApplication ? 'arrow-down' : ''"
  >
    <div class="option"
         [ngClass]="!isDisabled && applicationOptionsGroup.invalid ? 'invalid' : 'valid'"
         [class.dirty]="applicationOptionsGroup.dirty"
    >
      <input
        type="button"
        name="group"
        (click)="toggleShowingApplication()"
        [attr.disabled]="isDisabled ? '' : null"
      />
      <p *ngIf="getValues().at(0)">
        {{ getValues().at(0)}} <span class="bold" *ngIf="selectedCheckboxes().length-1>0">+ {{ selectedCheckboxes().length - 1 }} Selected</span>
      </p>
      <p class="placeholder" *ngIf="!getValues().at(0)">
        {{ placeholder }}
      </p>
    </div>
  </div>
  <div class="option-wrap" [ngClass]="showApplication ? 'active' : ''">
    <div
      class="option children checkbox-wrap"
      *ngFor="let cb of checkboxes"
      [formGroup]="applicationOptionsGroup"
    >
      <div class="checkbox" [ngClass]="cb.control.value?'checked':''"></div>
      <p>{{ cb.value }}</p>
      <input
        type="checkbox"
        name="application"
        [formControl]="cb.control"
        [value]="cb.value"
      />
    </div>
  </div>
  <div *ngIf="applicationOptionsGroup.errors && !isDisabled && applicationOptionsGroup.invalid && !showApplication && applicationOptionsGroup.dirty" [class.dirty]="applicationOptionsGroup.dirty" class="error-message">
    <div>{{applicationOptionsGroup.errors['minOneSelected']}}</div>
  </div>
</div>
