<div class="modal-container">
  <div class="modal-header">
    <div>Add new drone</div>
    <div class="modal-header-close-btn icon-close" (click)="abortModal()"></div>
  </div>
  <div class="seperator">
    <hr>
  </div>
  <div class="modal-body">
    <div *ngIf="!errorMessage">Please fill in your drone license key.</div>
    <div class="error-message" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
    <form [formGroup]="form" [noValidate]="true" (ngSubmit)="addDrone()">
      <app-licensekey-input [controllerName]="'licenseKey'"></app-licensekey-input>
      <app-button
        [gross]="true"
        [title]="'CHECK LICENSE KEY'"
        [disabled]="form.invalid"
      ></app-button>
    </form>
  </div>
</div>
