<div class="header">
  <h2>{{ mission.name ?? "Mission Planner" }}</h2>
  <app-button
    *ngIf="mission.missionID === undefined"
    [medium]="true"
    [title]="'SAVE'"
    (click)="saveMission()"
    [disabled]="!hasPolygon"
  ></app-button>
</div>

<div class="map-container">
  <div class="map-frame">
    <div id="map"></div>
  </div>
</div>

<div class="graph-container">
  <div *ngFor="let graph of graphList | keyvalue" class="graph-item">
    <div class="graph-item-toolbar">
      <select
        [(ngModel)]="graph.value.graphData"
        #data
        (ngModelChange)="onGraphDataSelectionChange(graph.key, data.value)"
      >
        <option value="null">---</option>
        <option value="latitude">Latitude</option>
        <option value="longitude">Longitude</option>
        <option value="altitude">Altitude</option>
        <option value="groundspeed">Groundspeed</option>
        <option value="wind">Wind</option>
        <option value="course">Course</option>
      </select>
      <button class="graph-item-toolbar-trash" (click)="removeGraph(graph.key)">
        <img class="icon" src="../../../assets/svg/trash.svg" />
      </button>
    </div>
    <canvas
      baseChart
      [type]="'line'"
      [data]="lineChartData(graph.key)"
      [options]="lineChartOptions"
    >
    </canvas>
  </div>
</div>
