import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import {Store} from "@ngrx/store";
import {ProfileState} from "../../stores/store.states";
import {selectProfileData} from "../../stores/profile/profile.selector";
import {dispatchLoadingProfileWithCompanyAndEquipment} from "../../stores/store.utils";

@Component({
  selector: 'app-academy',
  templateUrl: './academy.component.html',
  styleUrls: ['./academy.component.scss'],
})
export class AcademyComponent {
  constructor(private store: Store) {
    dispatchLoadingProfileWithCompanyAndEquipment(this.store);
  }
  user$: Observable<ProfileState> = this.store.select(selectProfileData)
}
