<div
  [class.successful]="success"
  [class.unsuccessful]="!success"
  [class.invisible]="invisible"
  [class.transition]="_transition"
>
  <div class="symbol"
     [class.successful]="success"
     [class.unsuccessful]="!success"
  ></div>
  <p *ngIf="success" class="successful">{{successMessage}}</p>
  <p *ngIf="!success" class="unsuccessful">{{errorMessage}}</p>
</div>
