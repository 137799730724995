import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-lastname-input',
  templateUrl: './lastname-input.component.html',
  styleUrls: ['./lastname-input.component.scss', '../inputs.scss']
})
export class LastnameInputComponent {
  @Input() lastName: string = '';
}
