<div class="modal-container">
  <div class="modal-header">
    <span>Invite new members</span>
    <div class="modal-header-close-btn" (click)="abortModal()">
      <span class="icon-close"></span>
    </div>
  </div>
  <div class="seperator">
    <hr />
  </div>
  <div class="modal-body">
    <span *ngIf="!errorMessage">
      Copy your company invite link for sharing or send an e-mail directly.
    </span>
    <span class="error-message" *ngIf="errorMessage">
      {{ errorMessage }}
    </span>
    <div class="company-share-link-group">
      <label for="company-share-link">Link for sharing</label>
      <div class="copy-link-input">
        <div class="group">
          <input
            disabled
            type="text"
            name="company-share-link"
            id="company-share-link"
            class="link-copy-input"
            [placeholder]="(link.length>40)? (link | slice:0:40)+'...':(link)"
          />
          <span class="copy-link" (click)="copyLink()"></span>
        </div>
        <app-response-banner [success]="true" [invisible]="!isCopied" successMessage="Copied"></app-response-banner>
      </div>
    </div>
    <form [formGroup]="form" class="send-email" (ngSubmit)="sendInvitation()">
      <label>Invite via e-mail</label>
      <app-email-input [controllerName]="'email'" label="E-Mail address" placeholder="Put in email address"></app-email-input>
      <app-button
        [medium]="true"
        [title]="'SEND INVITATION'"
        [disabled]="form.invalid"
      ></app-button>
    </form>
  </div>
</div>
