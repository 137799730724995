import {createSelector} from "@ngrx/store";
import {selectProfileState} from "./profile/profile.selector";
import {selectCompanyState} from "./company/company.selector";
import {selectEquipmentState} from "./equipment/equipment.selector";
import {UserRole} from "../models/user.model";
import {LoadingState} from "./store.states";

export const selectProgress = createSelector(
  selectProfileState, selectCompanyState, selectEquipmentState,
  (profile, company, equipment) => {
    return {
      profileComplete: profile.completed,
      companyComplete: company.completed,
      equipmentComplete: equipment.completed,
      progress:
        !profile.completed && !company.completed && !equipment.completed?30:
        profile.completed && (!company.completed && !equipment.completed)?40:
        profile.completed && company.completed && !equipment.completed?70:
        profile.completed && company.completed && equipment.completed?100:0
    }
  }
)

export const selectCompanyResolve = createSelector(
  selectProfileState, selectCompanyState,
  (profile, company) => {
    return {
      isReady: company.completed && profile.roleLoadingState === LoadingState.SUCCESS,
      companyComponent: (company.completed && profile.role === UserRole.ADMIN),
      memberComponent: (company.completed && profile.role === UserRole.PILOT)
    };
  }
)
