import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

type ResultUrlInputFormControl = FormControl<string>;

const regexUrl = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

@Component({
  selector: 'app-result-url-input',
  templateUrl: './result-url-input.component.html',
  styleUrls: ['./result-url-input.component.scss', '../inputs.scss'],
})
export class ResultUrlInputComponent {
  @Input() resultUrl = '';

  @Input() controllerName: string = '';
  resultUrlControl!: ResultUrlInputFormControl;
  public static readonly resultUrlControl: () => ResultUrlInputFormControl =
    () => new FormControl();

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.resultUrlControl = (this.controlContainer.control as FormGroup).get(
      this.controllerName
    ) as ResultUrlInputFormControl;
    this.resultUrlControl.setValidators([
      Validators.required,
      Validators.maxLength(100),
      Validators.pattern(regexUrl),
    ]);
    this.resultUrlControl.updateValueAndValidity();
  }
}
