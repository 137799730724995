import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {UserService} from "../../services/user.service";
import * as ProfileActions from './profile.actions';
import * as CompanyActions from '../company/company.actions';
import {concatMap, exhaustMap, first, of, withLatestFrom} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {MsalService} from "@azure/msal-angular";
import {Store} from "@ngrx/store";
import {CompanyService} from "../../services/company.service";
import {UserRole} from "../../models/user.model";
import {selectProfileData} from "./profile.selector";

@Injectable()
export class ProfileEffects {
  loadingProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadingProfile, ProfileActions.loginSuccess),
      concatMap(() => [
        ProfileActions.loadingQuantumProfile(),
        ProfileActions.loadingMsalActiveProfile()
      ])
    )
  )
  loadQuantumProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadingQuantumProfile),
      exhaustMap(() => this.userService.getProfile()
        .pipe(
          concatMap(profile => {
            if(profile.companyId) return [
                ProfileActions.loadingQuantumProfileSuccess({
                  quantumProfile: profile
                }),
                ProfileActions.loadingProfileRole({
                  companyId: profile.companyId,
                  userId: profile.id
                })
              ]
            return [
              ProfileActions.loadingQuantumProfileSuccess({
                quantumProfile: profile
              }),
            ]
            }
          ),
          catchError((err) => of(ProfileActions.loadingQuantumProfileError({error: err})))
        )
      )
  ))
  loadMSALProfile$ = createEffect(() =>
  this.actions$.pipe(
      ofType(ProfileActions.loadingMsalActiveProfile),
      exhaustMap(() =>  {
        const activeAccount = this.authService.instance.getActiveAccount();
        if(activeAccount?.idTokenClaims) {
          return of(ProfileActions.loadingMsalActiveProfileSuccess({
            msalProfile: {
              given_name: activeAccount.idTokenClaims['given_name'] as string || "",
              family_name: activeAccount.idTokenClaims['family_name'] as string || "",
              email: activeAccount.idTokenClaims.emails?.at(0) || ""
            }
          }))
        }
        return of(ProfileActions.loadingMsalActiveProfileError({error: "No active Account"}));
      })
    )
  )
  editQuantumProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.editingQuantumProfile),
      withLatestFrom(this.store.select(selectProfileData)),
      exhaustMap(([action, profileData]) => this.userService.editProfile(({
          ...action.quantumProfile,
          firstName:  action.quantumProfile.firstName??profileData.firstName,
          lastName: action.quantumProfile.firstName??profileData.lastName
          }))
        .pipe(
            map( profile => ProfileActions.editingQuantumProfileSuccess({updatedQuantumProfile: profile})),
            catchError(error => of(ProfileActions.editingQuantumProfileError({error: error})))
        )
      )
    )
  )
  loadProfileRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadingProfileRole),
      exhaustMap(({companyId, userId}) => this.companyService.getUsersCompanyRole(userId, companyId).pipe(
        map(role => ProfileActions.loadingProfileRoleSuccess(role)),
        catchError(error => of(ProfileActions.loadingProfileRoleError({error: error})))
      ))
    )
  )
  updateProfileRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.updatingProfileRole),
      exhaustMap(({companyId, memberId, role}) =>
        this.userService.changeProfileRole(memberId, companyId, role === UserRole.PILOT?{
          companyRole: 0
        }:{
          companyRole: 1
        }).pipe(
          concatMap(role => [ProfileActions.updatingProfileRoleSuccess(
              {
                role: role.companyRole === 0?UserRole.PILOT:UserRole.ADMIN
              }
            ),
            CompanyActions.loadingCompanyMembers({companyId: companyId})
            ]
          ),
          catchError(error => of(ProfileActions.updatingProfileRoleError({error: error})))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private authService: MsalService,
    private companyService: CompanyService,
    private store: Store
  ) {
  }
}
