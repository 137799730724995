export interface ProfileType {
  id: string;
  companyId?: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  companyName: string;
  country: string;
  postalCode?: string;
  industry: string;
  fieldsOfApplication: string[];
  stateOrProvince: string;
  avatar: string; //TODO: Delete
}
export enum UserRole {
  ADMIN = 'ADMIN',
  PILOT = 'PILOT',
}
