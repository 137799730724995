<div class="nav-container">
  <div class="logo-container">
    <img
      class="logo"
      src="../assets/svg/QS_New_Logo_Black.svg"
      alt="dashboard - Quantum Systems"
    />
  </div>
  <div class="menu-container">
    <div
      class="menu-item"
      [ngClass]="url === '/dashboard' ? 'active' : ''"
      routerLink="/dashboard"
    >
      <img
        class="icon"
        src="../assets/svg/dashboard.svg"
        alt="dashboard - Quantum Systems"
      />
      <div>Dashboard</div>
    </div>
    <div
      class="menu-item"
      [ngClass]="url === '/equipment' ? 'active' : ''"
      routerLink="/equipment"
    >
      <img
        class="icon"
        src="../assets/svg/equipment.svg"
        alt="dashboard - Quantum Systems"
      />
      <div>Company Equipment</div>
    </div>
    <div
      class="menu-item"
      [ngClass]="url === '/missions' ? 'active' : ''"
      routerLink="/missions"
    >
      <img
        class="icon"
        src="../assets/svg/missions.svg"
        alt="dashboard - Quantum Systems"
      />
      <div>Mission Control</div>
    </div>
    <!--
    <div
      class="menu-item"
      [ngClass]="url === '/academy' ? 'active' : ''"
      routerLink="/academy"
    >
      <img
        class="icon"
        src="../assets/svg/academy.svg"
        alt="dashboard - Quantum Systems"
      />
      <div>QS Academy</div>
    </div>
    <div
      class="menu-item"
      [ngClass]="url === '/help' ? 'active' : ''"
      routerLink="/help"
    >
      <img
        class="icon"
        src="../assets/svg/help.svg"
        alt="dashboard - Quantum Systems"
      />
      <div>Help</div>
    </div> -->
  </div>
</div>

<div class="container-topbar">
  <div class="logo-bubble-container">
    <img
      class="logo"
      src="../assets/svg/logo-mobile.svg"
      alt="dashboard - Quantum Systems"
    />
  </div>
  <div class="avatar-bubble-container">
    <app-avatar [avatarTop]="true"></app-avatar>
  </div>
  <div class="search-bubble-container">
    <app-button [searchType]="true" [title]="'UPDATE'"></app-button>
  </div>
  <div class="burger-bubble-container">
    <div
      class="burger-container"
      [ngClass]="clickOn ? 'change' : ''"
      (click)="opening()"
    >
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="circle"></div>
    </div>
  </div>
</div>
<div class="menu-open" [ngClass]="clickOn ? 'showing' : ''">
  <ul>
    <li routerLink="/dashboard" (click)="opening()">
      <!-- <img class="icon" src="../assets/svg/dashboard-black.svg" /> -->
      <div role="button">dashboard</div>
    </li>
    <li routerLink="/equipment" (click)="opening()">
      <!-- <img class="icon" src="../assets/svg/equipment-black.svg" /> -->
      <div role="button">company equipment</div>
    </li>
    <li>
      <a href="https://quantum-systems.com/legal-notice/">legal notice</a>
    </li>
    <li>
      <a href="https://quantum-systems.com/terms-and-conditions/"
        >terms and conditions</a
      >
    </li>
    <!--<li routerLink="/academy" (click)="opening()">
      <img class="icon" src="../assets/svg/academy-black.svg" />
      <div>QS Academy</div>
    </li>
    <li routerLink="/help" (click)="opening()">
      <img class="icon" src="../assets/svg/help-black.svg" />
      <div>Help</div>
    </li>-->
    <li (click)="childMethod()">
      <div role="button">logout</div>
    </li>
  </ul>
</div>
