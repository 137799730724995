import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading$: BehaviorSubject<Boolean>;
  constructor() {
    this.isLoading$ = new BehaviorSubject<Boolean>(false);
  }

  loading() : void {
    this.isLoading$.next(true);
  }

  loaded() : void {
    this.isLoading$.next(false);
  }

  getObservable() : Observable<Boolean>{
    return this.isLoading$.asObservable();
  }
}
