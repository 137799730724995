<div class="topnav-container">
  <div class="avatar-container">
    <app-avatar [avatarMenu]="true"></app-avatar>
  </div>
  <div class="search-container">
    <div class="button-container">
      <app-button [searchType]="true" [title]="'UPDATE'"></app-button>
    </div>
  </div>
  <div
    class="burger-container"
    [ngClass]="clickOn ? 'change' : ''"
    (click)="opening()"
    [cdkMenuTriggerFor]="menu"
  >
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
    <div class="circle"></div>
  </div>
</div>
<span class="line"></span>
<ng-template #menu>
  <div class="menu-open" [ngClass]="clickOn ? 'showing' : ''">
    <ul>
      <li>
        <a href="https://quantum-systems.com/legal-notice/">legal notice</a>
      </li>
      <li>
        <a href="https://quantum-systems.com/terms-and-conditions/">terms and conditions</a>
      </li>
      <li (click)="childMethod()">
        <a>logout</a>
      </li>
    </ul>
  </div>
</ng-template>
