<div class="group" appActionOnOutsideClick (clickOutside)="close()">
  <label>Country</label>
  <div class="selected-item" [ngClass]="showCountry ? 'arrow-down' : ''">
    <div
      class="option"
      [ngClass]="countryOptionsGroup.invalid ? 'invalid' : 'valid'"
      [class.dirty]="countryOptionsGroup.dirty"
    >
      <input type="button" name="group" (click)="toggleShowingCountry()" />
      <p *ngIf="countryOptionsGroup.value?.country">
        {{ countryOptionsGroup.value.country }}
      </p>
      <p class="placeholder" *ngIf="!countryOptionsGroup.value.country">
        Choose...
      </p>
    </div>
  </div>
  <div
    class="option-wrap"
    [ngClass]="showCountry ? 'active' : ''"
    appTypeAheadFocus
  >
    <div
      class="option children"
      *ngFor="let option of countryOptions"
      [formGroup]="countryOptionsGroup"
    >
      <input
        type="radio"
        name="country"
        [formControlName]="'country'"
        (click)="toggleShowingCountry()"
        [value]="option.title"
      />
      <p>{{ option.title }}</p>
    </div>
  </div>
  <div
    *ngIf="
      countryOptionsGroup.invalid && !showCountry && countryOptionsGroup.dirty
    "
    [class.dirty]="countryOptionsGroup.dirty"
    class="error-message"
  >
    <div>*required</div>
  </div>
</div>
