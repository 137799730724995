import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable, retry, tap} from 'rxjs';
import {finalize} from "rxjs/operators";
import {LoadingService} from "../services/loading.service";
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private activeRequests: number = 0;
  constructor(private loadingService: LoadingService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.activeRequests++;
    this.loadingService.loading();

    return next.handle(request)
      .pipe(
        tap(() => {
          setTimeout(() => {
            this.activeRequests = 0;
            this.loadingService.loaded();
          },15000)
        }),
        finalize(() => {
            if(this.activeRequests > 0) {
              this.activeRequests--;
            }
            if(this.activeRequests <= 0) {
              this.loadingService.loaded();
            }
        })
    );
  }
}
