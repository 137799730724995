import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Store} from "@ngrx/store";
import {selectEquipment} from "../stores/equipment/equipment.selector";
import {selectCompanyData} from "../stores/company/company.selector";
import * as EquipmentActions from "../stores/equipment/equipment.actions";
import * as CompanyActions from "../stores/company/company.actions";
import {selectProfileCompleted} from "../stores/profile/profile.selector";
import {selectProgress} from "../stores/store.selector";

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private $progress: BehaviorSubject<number>;

  constructor(
    private store: Store
  ) {
    this.$progress = new BehaviorSubject<number>(30);
    this.calculateProgress();
  }

  setProgress(progress: number) {
    this.$progress.next(progress);
  }

  getProgressAsObservable() {
    return this.$progress.asObservable()
  }

  calculateProgress() {
    this.store.select(selectProgress).pipe().subscribe(progress => {
      this.setProgress(progress.progress);
    })
  }
}
