import {
  Component,
  AfterViewInit,
  QueryList,
  ViewChildren,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import 'leaflet-draw';
import { protectedResources } from 'src/app/auth-config';
import { LiveTelemetricDataPoint, Mission } from 'src/app/models/mission.model';
import { MissionControlService } from 'src/app/services/mission-control.service';
import {
  ChartConfiguration,
  ChartData,
  ChartType,
  DefaultDataPoint,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { formatDate } from '@angular/common';

interface CustomChartData<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown
> extends ChartData<TType, TData, TLabel> {
  graphData: String | null;
}

interface CustomChartConfig<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown
> extends ChartConfiguration<TType, TData, TLabel> {
  data: CustomChartData<TType, TData, TLabel>;
}

@Component({
  selector: 'app-mission-planner',
  templateUrl: './mission-planner.component.html',
  styleUrls: ['./mission-planner.component.scss'],
})
export class MissionPlannerComponent implements AfterViewInit {
  private map: any;
  mission!: Partial<Mission>;
  hasPolygon: boolean = false;
  labelData: String[] = [];
  graphList: Map<String, CustomChartConfig<'line'>['data']> = new Map();
  latitudeData: any[] = [];
  longitudeData: any[] = [];
  altitudeData: any[] = [];
  groundspeedData: any[] = [];
  windspeedData: any[] = [];
  courseData: any[] = [];
  @ViewChildren(BaseChartDirective) chart?: QueryList<BaseChartDirective>;

  private initMap(): void {
    this.map = L.map('map', {
      center: [51.505, -0.09], // London
      zoom: 5,
      attributionControl: false,
    });

    const maps = {
      openStreet: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        subdomains: [],
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      },
      googleSatellite: {
        url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      },
    };

    L.tileLayer(maps.googleSatellite.url, {
      maxZoom: 20,
      minZoom: 3,
      subdomains: maps.googleSatellite.subdomains,
      attribution: undefined,
    }).addTo(this.map);

    switch (this.mission.status) {
      case 'ASSIGNED': {
        this.initLiveViewer();
        break;
      }

      case 'DONE': {
        this.initHistoricalViewer();
        break;
      }

      default:
      case 'PLANNING': {
        this.initPlanner();
        break;
      }
    }

    if (this.mission) {
      L.control
        .attribution({
          position: 'topright',
          prefix: this.mission.name,
        })
        .addTo(this.map);
      L.control
        .attribution({
          position: 'topright',
          prefix: this.mission.aircraftId,
        })
        .addTo(this.map);
      L.control
        .attribution({
          position: 'topright',
          prefix: this.mission.operatorId,
        })
        .addTo(this.map);
      L.control
        .attribution({
          position: 'topright',
          prefix: this.mission.plannedAt,
        })
        .addTo(this.map);
    }
  }

  private initPlanner(): void {
    const drawnItems = new L.FeatureGroup();
    this.map.addLayer(drawnItems);

    new L.Control.Draw({
      draw: {
        circle: false,
        circlemarker: false,
        marker: false,
        // polygon: false,
        polyline: false,
        rectangle: false,
      },
      edit: {
        featureGroup: drawnItems,
      },
    }).addTo(this.map);

    this.map.on(L.Draw.Event.CREATED, (event: L.DrawEvents.Created) => {
      if (event.layerType === 'polygon') {
        drawnItems.clearLayers();
        drawnItems.addLayer(event.layer);

        this.hasPolygon = drawnItems.getLayers().length > 0;
        this.updateMissionDetails(event.layer);
      }
    });

    this.map.on(L.Draw.Event.EDITED, () => {
      this.updateMissionDetails(drawnItems.getLayers()[0]);
    });

    this.map.on(L.Draw.Event.EDITSTART, () => {
      this.hasPolygon = false;
      this.updateMissionDetails(drawnItems.getLayers()[0]);
    });

    this.map.on(L.Draw.Event.EDITSTOP, () => {
      this.hasPolygon = drawnItems.getLayers().length > 0;
      this.updateMissionDetails(drawnItems.getLayers()[0]);
    });

    this.map.on(L.Draw.Event.DELETED, () => {
      this.hasPolygon = drawnItems.getLayers().length > 0;
      this.mission.missionDetails = undefined;
    });
  }

  private updateMissionDetails(layer: any): void {
    const coords: number[][] = layer.toGeoJSON().geometry.coordinates[0];
    this.mission.missionDetails = { geoPointDtos: [] };
    this.mission.missionDetails.geoPointDtos = coords.map(
      ([longitude, latitude]) => ({
        latitude: String(latitude),
        longitude: String(longitude),
        altitude: String(550), // NOTE: constant altitude
      })
    );
  }

  private drawMissionDetails(): void {
    if (this.mission.missionDetails) {
      const points: L.LatLngExpression[] =
        this.mission.missionDetails.geoPointDtos.map((point) => [
          Number(point.latitude),
          Number(point.longitude),
        ]);

      if (points.length > 0) {
        const polygon = L.polygon(points, { color: 'green' }).addTo(this.map);

        // zoom the map to the polygon
        this.map.fitBounds(polygon.getBounds());
      }
    }
  }

  private drawFlightFromHistoricData(data: LiveTelemetricDataPoint[]): void {
    if (data.length === 0) return;

    const latLongs = data.map(
      (point): L.LatLngExpression => [point.Latitude, point.Longitude]
    );

    const flightPath = L.polyline(latLongs, { color: 'lime' }).addTo(this.map);

    // zoom the map to the flight path
    this.map.fitBounds(flightPath.getBounds());
  }

  private initLiveViewer(): void {
    this.addGraphControls();
    this.drawMissionDetails();

    const updateDrone = (() => {
      const iconSize = 48;
      const aircraftIcon = L.icon({
        iconUrl: 'assets/imgs/aircraft.png',
        iconSize: [iconSize, iconSize],
        iconAnchor: [iconSize / 2, iconSize / 2],
        shadowUrl: 'assets/imgs/aircraft-shadow.png',
        shadowSize: [32, 32],
        shadowAnchor: [32, -32],
      });

      const aircraft = L.marker([0, 0], { icon: aircraftIcon });

      const popup = L.popup({
        content: 'Waiting for data...',
        className: 'leaflet-popup-drone',
        minWidth: 150,
        keepInView: true,
        offset: [0, 32],
      });

      const updatePopupContent = (data: LiveTelemetricDataPoint) => {
        const { Latitude, Longitude, AltitudeMSL, GroundSpeed, Wind } = data;

        const round = (num: number, n: number) => Number(num.toFixed(n));

        popup.setContent(
          `<table>
            <tr><td>Lat</td><td>${round(Latitude, 5)}</td></tr>
            <tr><td>Long</td><td>${round(Longitude, 5)}</td></tr>
            <tr><td>Alt</td><td>${round(AltitudeMSL, 2)} m</td></tr>
            <tr><td>GS</td><td>${round(GroundSpeed, 2)} m/s</td></tr>
            <tr><td>Wind</td><td>${round(Wind, 2)} m/s</td></tr>
          </table>`
        );
      };

      let isAircraftOnMap = false;
      const initAircraftMarker = (coordinates: L.LatLngExpression) => {
        if (isAircraftOnMap) return;
        aircraft.setLatLng(coordinates);
        aircraft.addTo(this.map);
        aircraft.bindPopup(popup).openPopup();
        isAircraftOnMap = true;
      };

      const fxDrone = new L.PosAnimation();
      const fxShadow = new L.PosAnimation();
      const fxPopup = new L.PosAnimation();

      const _aircraft: any = aircraft;

      const rotateElement = (element: any) => {
        const transform = `${element.style[L.DomUtil.TRANSFORM]} rotate(${
          _aircraft._inner_rotation
        }deg)`;
        element.style[L.DomUtil.TRANSFORM] = transform;
        element.style['transform-origin'] = 'center';
        element.style['transition'] = 'none';
      };

      fxDrone.on('step', () => rotateElement(_aircraft._icon));
      fxShadow.on('step', () => rotateElement(_aircraft._shadow));

      let shouldAnimate = true;

      const onZoom = () => {
        fxDrone.stop();
        fxShadow.stop();
        fxPopup.stop();

        rotateElement(_aircraft._icon);
        rotateElement(_aircraft._shadow);

        shouldAnimate = false;
      };

      const onZoomEnd = () => {
        shouldAnimate = true;
      };

      this.map.on('zoom', onZoom);
      this.map.on('zoomanim', onZoom);
      this.map.on('zoomend', onZoomEnd);

      const animateDrone = (coordinates: L.LatLngExpression) => {
        if (!shouldAnimate) return;
        aircraft.setLatLng(coordinates);
        const pos = this.map.latLngToLayerPoint(coordinates);
        fxDrone.run(_aircraft._icon, pos);
        fxShadow.run(_aircraft._shadow, pos);
        fxPopup.run(_aircraft._popup._container, pos);
      };

      return (data: LiveTelemetricDataPoint) => {
        const { Latitude, Longitude, Course } = data;

        if (Latitude === undefined || Longitude === undefined) {
          console.warn('Update drone: No Latitude and or Longitude!');
          return;
        }

        _aircraft._inner_rotation = Course;
        _aircraft._inner_latitude = Latitude;
        _aircraft._inner_longitude = Longitude;

        const coordinates: L.LatLngExpression = [Latitude, Longitude];
        initAircraftMarker(coordinates);
        animateDrone(coordinates);
        updatePopupContent(data);
      };
    })();

    // Fetch live data
    const url = `${protectedResources.apiTodoList.endpoint}/LiveTelemetricData/${this.mission.missionID}`;
    const ws = new WebSocket(url);

    const sendAck = () => {
      ws.send('ACK');
    };

    ws.onopen = sendAck;

    ws.onmessage = (event) => {
      let data = JSON.parse(event.data);
      this.fillGraphData(this, data);
      updateDrone(data);
      sendAck();
    };

    setInterval(() => {
      this.chart?.forEach((it) => it.update());
    }, 500);
  }

  private initHistoricalViewer(): void {
    this.addGraphControls();
    this.drawMissionDetails();

    const { missionID } = this.mission;
    if (!missionID) throw new Error('No missionID');
    this.missionControlService
      .getHistoricalTelemetricData(missionID)
      .subscribe((data) => {
        this.drawFlightFromHistoricData(data);

        data.forEach((it) => this.fillGraphData(this, it));
      });
  }

  private addGraphControls(): void {
    const addButtonControl = L.Control.extend({
      onAdd: () => {
        const button = L.DomUtil.create('button');
        button.role = 'button';
        button.innerHTML = '+ add graph';
        button.className = 'leaflet-touch leaflet-bar';
        button.style.backgroundColor = '#f4f4f4';

        L.DomEvent.on(button, 'click', (event) => {
          event.preventDefault();
          event.stopPropagation();

          this.addGraph();

          setTimeout(() => {
            const graphItems = document.getElementsByClassName('graph-item');

            // scroll new graph into view
            Object.values(graphItems).at(-1)?.scrollIntoView();
          });
        });

        return button;
      },
    });

    new addButtonControl({ position: 'bottomleft' }).addTo(this.map);
  }

  constructor(
    private missionControlService: MissionControlService,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit(): void {
    const { mission } = this.missionControlService;
    if (!mission) {
      this.router.navigate(['/missions']);
      return;
    }
    this.mission = mission;
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  saveMission() {
    this.missionControlService
      .postMission(this.mission)
      .subscribe((response) => {
        // TODO: Handle Error: don't navigate on error, Toast Error.
        this.router.navigate(['/missions']);
      });
  }

  addGraph() {
    this.graphList.set(crypto.randomUUID(), {
      labels: this.labelData,
      graphData: null,
      datasets: [
        {
          normalized: true,
          data: [],
          label: '',
          fill: true,
          tension: 0.5,
          borderColor: 'black',
          backgroundColor: 'rgba(255,0,0,0.3)',
        },
      ],
    });
  }

  removeGraph(id: String) {
    this.graphList.delete(id);
  }

  public lineChartData(graph: String) {
    return this.graphList.get(graph);
  }

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      decimation: {
        algorithm: 'lttb',
        enabled: true,
        samples: 25,
        threshold: 25,
      },
    },
  };

  onGraphDataSelectionChange(graph: String, data: String) {
    // this.graphList.get(graph)!.graphData = data;
    switch (data) {
      case 'latitude':
        this.graphList.get(graph)!.datasets[0].data = this.latitudeData;
        break;
      case 'longitude':
        this.graphList.get(graph)!.datasets[0].data = this.longitudeData;
        break;
      case 'altitude':
        this.graphList.get(graph)!.datasets[0].data = this.altitudeData;
        break;
      case 'groundspeed':
        this.graphList.get(graph)!.datasets[0].data = this.groundspeedData;
        break;
      case 'wind':
        this.graphList.get(graph)!.datasets[0].data = this.windspeedData;
        break;
      case 'course':
        this.graphList.get(graph)!.datasets[0].data = this.courseData;
        break;
    }

    this.chart?.forEach((it) => it.update());
  }

  fillGraphData(i: MissionPlannerComponent, data: LiveTelemetricDataPoint) {
    let ts = formatDate(new Date(data.eventTime), 'hh:mm:ss', this.locale);
    i.labelData.push(ts);
    i.latitudeData.push(data.Latitude);
    i.longitudeData.push(data.Longitude);
    i.altitudeData.push(data.AltitudeMSL);
    i.groundspeedData.push(data.GroundSpeed);
    i.windspeedData.push(data.Wind);
    i.courseData.push(data.Course);
  }
}
