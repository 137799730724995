import { Component, OnInit } from '@angular/core';
import { Software } from 'src/app/models/equipment.model';
import { Dialog } from '@angular/cdk/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CompanyState, ProfileState } from '../../stores/store.states';
import { selectAllSoftware } from '../../stores/equipment/equipment.selector';
import { selectProfileData } from '../../stores/profile/profile.selector';
import {
  selectAllCompanyMembersWithSelfFirst,
  selectCompanyData,
  selectCompanyMemberByOperatorId,
} from '../../stores/company/company.selector';
import { dispatchLoadingProfileWithCompanyAndEquipment } from '../../stores/store.utils';
import { NewMissionModalComponent } from 'src/app/components/modals/new-mission-modal/new-misson-modal.component';
import { MemberType } from 'src/app/models/company.model';
import { MissionControlService } from 'src/app/services/mission-control.service';
import { Mission } from 'src/app/models/mission.model';
import { Router } from '@angular/router';
import { CompleteMissionModalComponent } from 'src/app/components/modals/complete-mission-modal/complete-misson-modal.component';

@Component({
  selector: 'app-missions',
  templateUrl: './missions.component.html',
  styleUrls: ['./missions.component.scss'],
})
export class MissionsComponent implements OnInit {
  missions: Mission[] = [];

  constructor(
    private missionControlService: MissionControlService,
    public dialog: Dialog,
    private store: Store,
    private router: Router
  ) {
    dispatchLoadingProfileWithCompanyAndEquipment(this.store);
    this.software$ = this.store.select(selectAllSoftware);
    this.profile$ = this.store.select(selectProfileData);
    this.company$ = this.store.select(selectCompanyData);
    this.members$ = this.store.select(selectAllCompanyMembersWithSelfFirst);
  }
  software$: Observable<Software[]>;
  profile$: Observable<ProfileState>;
  company$: Observable<CompanyState>;
  members$: Observable<MemberType[]>;

  ngOnInit() {
    this.missionControlService.getMissions().subscribe((missions) => {
      this.missions = missions;
    });
  }

  openNewMissionDialog() {
    const dialogRef = this.dialog.open(NewMissionModalComponent);
    dialogRef.closed.subscribe((result) => {});
  }

  getMemberEmailByOperatorId(operatorId: string): Observable<string> {
    return this.store.select(selectCompanyMemberByOperatorId(operatorId));
  }

  assignMissionToOperator(event: any, missionID: string): void {
    event.preventDefault();
    event.stopPropagation();

    this.missionControlService
      .postAssignMissionToOperator(missionID)
      .subscribe((mission) => {
        const found = this.missions.findIndex(
          (mission) => mission.missionID === missionID
        );
        this.missions[found] = mission;
      });
  }

  openCompleteMissionModal(event: any, missionId: string): void {
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this.dialog.open<Mission>(CompleteMissionModalComponent, {
      data: {
        missionId,
      },
    });
    dialogRef.closed.subscribe((mission) => {
      if (!mission) return;

      const found = this.missions.findIndex(
        (mission) => mission.missionID === missionId
      );
      this.missions[found] = mission;
    });
  }
  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  toMission(event: any, mission: Mission): void {
    event.preventDefault();
    event.stopPropagation();

    this.missionControlService.mission = mission;
    this.router.navigate([`/missions/${mission.missionID}`]);
  }
}
