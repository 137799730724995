<div class="group">
  <label>License Key</label>
  <input
    [formControl]="licenseKeyControl"
    type="text"
    [placeholder]="'License Key' + (licenseKeyControl.invalid?'*':'')"
    class="quantum-input"
    [ngClass]="licenseKeyControl.invalid && licenseKeyControl.dirty? 'invalid' : ''"
  />
  <div *ngIf="licenseKeyControl.invalid && licenseKeyControl.errors" class="error-message" [class.dirty]="licenseKeyControl.dirty">
    <div *ngIf="licenseKeyControl.errors['required']">*required</div>
  </div>
</div>
