import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

type TimestampInputFormControl = FormControl<string>;
@Component({
  selector: 'app-timestamp-input',
  templateUrl: './timestamp-input.component.html',
  styleUrls: ['./timestamp-input.component.scss', '../inputs.scss'],
})
export class TimestampInputComponent implements OnInit {
  @Input() initValue: string = '';
  @Input() controllerName: string = '';
  timestampControl!: TimestampInputFormControl;
  public static readonly timestampInputFormControl: () => TimestampInputFormControl =
    () => new FormControl<string>('', { nonNullable: true });
  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.timestampControl = (this.controlContainer.control as FormGroup).get(
      this.controllerName
    ) as TimestampInputFormControl;
    this.timestampControl.setValue(this.initValue);
    this.timestampControl.setValidators([Validators.maxLength(100)]);
    this.timestampControl.updateValueAndValidity();
  }
}
