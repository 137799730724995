<div class="group">
  <label>Planned At</label>
  <input
    [formControl]="timestampControl"
    type="date"
    placeholder="Enter Timestamp"
    class="quantum-input"
    [ngClass]="timestampControl.invalid ? 'invalid' : ''"
  />
  <div
    *ngIf="
      timestampControl.invalid &&
      timestampControl.errors &&
      timestampControl.dirty
    "
    [class.dirty]="timestampControl.dirty"
    class="error-message"
  >
    <div *ngIf="timestampControl.errors['required']">*required</div>
  </div>
</div>
