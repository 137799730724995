import { Injectable } from '@angular/core';
import {BehaviorSubject, concatMap, endWith, map, merge, Observable, take, tap, timer} from "rxjs";
import {filter} from "rxjs/operators";
import {CompanyState, LoadingState} from "../stores/store.states";
import {Selector} from "@ngrx/store";

export type DashboardResponseBanner = {
  open: boolean,
  success: boolean,
  successMessage: string,
  errorMessage: string
}
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  public dashboardResponseBanner = new BehaviorSubject<DashboardResponseBanner>({
    errorMessage: "", open: false, success: true, successMessage: ""
  })
  public dashboardResponseBanner$: Observable<DashboardResponseBanner> = this.dashboardResponseBanner.asObservable();

  openResponseBannerOnResponse(successMessage: string, errorMessage: string, company$: Observable<CompanyState>, loadingState: keyof CompanyState) {
    merge(
      company$.pipe(
        filter(company => company[loadingState] === LoadingState.SUCCESS),
        take(1),
        tap(() => {
          this.openSuccessResponseBanner(successMessage);
        })
      ),
      company$.pipe(
        filter(company => company[loadingState] === LoadingState.ERROR),
        take(1),
        tap(() => {
          this.openErrorResponseBanner(errorMessage);
        })
      ),
    ).subscribe()
  }

  openSuccessResponseBanner(successMessage: string) {
    this.dashboardResponseBanner.next({
      errorMessage: "",open: true, success: true, successMessage: successMessage
    })
    setTimeout(() => {
      this.dashboardResponseBanner.next({
        errorMessage: "",open: false, success: true, successMessage: successMessage
      })
    }, 5000);
  }

  openErrorResponseBanner(errorMessage: string) {
    this.dashboardResponseBanner.next({
      errorMessage: errorMessage,open: true, success: false, successMessage: ""
    })
    setTimeout(() => {
      this.dashboardResponseBanner.next({
        errorMessage: errorMessage,open: false, success: false, successMessage: ""
      })
    }, 5000);
  }
  constructor() { }
}
