import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResources } from '../auth-config';
import { LiveTelemetricDataPoint, Mission } from '../models/mission.model';

@Injectable({
  providedIn: 'root',
})
export class MissionControlService {
  public mission?: Partial<Mission>;

  constructor(private http: HttpClient) {}
  apiEndpoint: string = protectedResources.apiTodoList.endpoint;

  getMissions() {
    return this.http.get<Mission[]>(
      `${this.apiEndpoint}/MissionControl/missions`
    );
  }

  postMission(data: Partial<Mission>) {
    return this.http.post<Partial<Mission>>(
      `${this.apiEndpoint}/MissionControl/mission`,
      data
    );
  }

  postAssignMissionToOperator(missionID: string) {
    return this.http.post<Mission>(
      `${this.apiEndpoint}/MissionControl/assignMissionToOperator/${missionID}`,
      undefined
    );
  }

  postMissionResult(body: { missionId: string; resultUrl: string }) {
    return this.http.post<Mission>(
      `${this.apiEndpoint}/MissionControl/mission/result`,
      body
    );
  }

  getHistoricalTelemetricData(missionID: string) {
    return this.http.get<LiveTelemetricDataPoint[]>(
      `${this.apiEndpoint}/HistoricalTelemetricData/${missionID}`
    );
  }
}
